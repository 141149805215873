<template>
  <div class="content-navbar">
    <div class="content-left">
      <v-btn class="btn-menu-full" icon @click="setMenuSidebar">
        <v-icon class="iconMenu"> mdi-menu </v-icon>
      </v-btn>
      <v-menu
        v-model="bMenu"
        offset-y
        transition="scale-transition"
        rounded="xl"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon class="btn-menu-mobile" v-bind="attrs" v-on="on">
            <v-icon class="iconMenu"> mdi-menu </v-icon>
          </v-btn>
        </template>
        <v-list :style="{'height': screenHeight + 'px' ,'max-height': screenHeight + 'px'}" class="content-menu-mobile pa-5">
          <div v-for="(item, i) in filteredItems" :key="i" >
            <!-- item -->
            <!-- v-if="bDashboardView? item.sModuleId === '1'? true:false:true" -->
            <div @click="goView(item.name)" class="item-menu-mobile" >
              <v-list-item
                :class="
                  setBackgroundColor(item.routeName) ? 'list-selected' : ''
                "
                class=""
                exact
              >
                <v-list-item-icon class="content-icon-list-menu">
                  <v-icon class="icon-list-menu">{{ item.iconMDI }}</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title class="text-list-menu">{{
                    item.title
                  }}</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </div>
          </div>
        </v-list>
      </v-menu>
    </div>
    <div id="imglogo" class="content-img-logo"></div>

    <!-- <v-divider vertical class="divider-sidebar-global"></v-divider> -->
    <div class="content-center">
      <p class="text-slogan">LIMPIEZA, ORDEN Y PUNTUALIDAD.</p>
    </div>
    <div class="content-right">
      <div class="content-img">
        <v-menu
          v-model="bMenuProfile"
          bottom
          min-width="200px"
          :close-on-content-click="true"
          offset-y
          rounded="lg"
        >
          <template v-slot:activator="{ on }">
            <v-avatar
              @click="bMenuProfile = !bMenuProfile"
              v-on="on"
              class="content-border-profile"
              size="45"
            >
              <v-avatar
                @click="bMenuProfile = !bMenuProfile"
                v-on="on"
                class="content-center-profile"
                size="43"
              >
                <img :src="sUrlImg" class="img-profile" />
              </v-avatar>
            </v-avatar>
            <v-btn
              class="btn-menu-profile"
              @click="bMenuProfile = !bMenuProfile"
              v-on="on"
              icon
            >
              <v-icon size="25">mdi-chevron-down</v-icon>
            </v-btn>
          </template>
          <v-card class="content-profile-menu pa-2">
            <div class="mx-auto">
              <div class="content-name-switch-mod-dark">
                <div class="content-name-profile">
                  <p class="text-name-profile">{{ sFullname }}</p>
                </div>
                <div class="content-switch">
                  <v-switch
                    v-model="bChangeDark"
                    class="switch-change-mod-dark"
                    hide-details
                    color="var(--primary-color-switch)"
                  >
                  </v-switch>
                  <v-icon class="icon-switch" v-if="bDark">
                    mdi-white-balance-sunny
                  </v-icon>
                  <v-icon class="icon-switch" v-else>
                    mdi-moon-waning-crescent
                  </v-icon>
                </div>
              </div>
              <div @click="goView('UserProfile')" class="content-user-profile">
                <v-icon class="icon-switch">mdi-account-outline</v-icon>
                <span class="text-user-profile">Perfil de usuario</span>
              </div>
              <!-- <div @click="logOut()" class="content-logout"> -->
              <div @click="logOut()" class="content-logout">
                <v-icon class="icon-switch">mdi-logout</v-icon>
                <span class="text-logout">Cerrar sesión</span>
              </div>
              <br />
            </div>
          </v-card>
        </v-menu>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      // sFullName: "",
      screenHeight:0,
      closeOnContentClick: true,
      bMenu: false,
      bMenuProfile: false,
      bMobileMenu: false,
      bChangeDark: true,
    };
  },
  beforeMount() {
    this.bChangeDark = this.bDark;
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      this.screenHeight = window.innerHeight - 70;

      window.innerWidth > 960 ? (this.bMenu = false) : "";
    },
    setMenuSidebar() {
      var active = (this.$store.state.bSidebar = !this.$store.state.bSidebar);
      this.$store.commit("openSidebar", active);
    },
    setBackgroundColor: function (routeNames) {
      let bln = false;
      for (let i = 0; i < routeNames.length; i++) {
        if (this.$route.name == routeNames[i]) {
          bln = true;
        }
      }
      return bln;
    },
    goView(sName) {
      const loading = this.$vs.loading({
        type: "circles",
        color: "#F1D72E",
        text: 'Cargando información...'
      });
      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          loading.close();
          this.$store.commit("openSidebar", false);
          if (sName === this.$route.name) {
            this.$store.commit("refresher", true);
          } else {
            this.$router.push({ name: sName }).catch((err) => { });
          }
        })
        .catch((err) => {
          loading.close();
          this.Error(err);
        });
      // this.$router.push(`/home/${view}`).catch(() => {});
    },

    getAdministratorDetail() {
      const payload = {},
        config = {
          headers: {
            Authorization: "Bearer " + this.$store.state.sToken,
            contentType: "application/x-www-form-urlencoded;charset=utf-8",
          },
        };
      DB.get(
        `${URI}/api/sp/v1/users/${this.$store.state.sUserId}`,
        config,
        payload
      )
        .then((response) => {
          this.sFullName =
            response.data.results.sName + " " + response.data.results.sLastname;
          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.Error(error.response.data);
        });
    },
    logOut() {
      const loading = this.$vs.loading({
        type: "circles",
        color: "#F1D72E",
      });
      this.loadingForm = true;
      const payload = {},
        config = {
          headers: {
            Authorization: "Bearer " + this.$store.state.sToken,
            contentType: "application/x-www-form-urlencoded;charset=utf-8",
          },
        };
      DB.delete(`${URI}/api/sp/v1/session`, config)
        .then((response) => {
          this.bLoading = false;

          this.Success(response.data.message);

          setTimeout(() => {
            this.$store.commit("setInformationUser", {
              sUserId: "",
              sToken: "",
              sFullName: "",
              sEmail: "",
              sPhoneNumber: "",
              sRole: "",
              tCreatedAt: "",
              aUserPermissions: null,
              // sUrlImg: [],
            });
            this.$router.go();
          }, 1000);
        })
        .catch((error) => {
          this.bLoading = false;
          this.Error(error.response.data);
        });
    },
    openLoading() {
      const loading = this.$vs.loading({
        type: "circles",
        color: "#F1D72E",
      });
      setTimeout(() => {
        loading.close();
      }, 3000);
    },
  },
  computed: {
    bDark() {
      return this.$store.state.bDark;
    },
    bSidebar() {
      return this.$store.state.bSidebar;
    },
    sUrlImg() {
      return this.$store.state.sUrlImg;
    },
    sFullname() {
      return this.$store.state.sFullname;
    },
    aMenuGlobal() {
      return this.$store.state.aMenuGlobal;
    },
    filteredItems: function () {
      return this.aMenuGlobal.filter((routeOption) => {
        if (this.userPermissions === null) {
          return routeOption;
        } else {
          var exp = this.userPermissions;
          let arr = "";
          exp.forEach((e) => {
            if (e.sModuleId === routeOption.sModuleId) {
              arr = e;
            }
          });
          return arr;
        }
      });
    },
    userPermissions() {
      return this.$store.state.aPermissions;
    },
    bDashboardView(){
      return  this.$store.state.aPermissions === null;
    }
  },
  watch: {
    bChangeDark() {
      var active = this.bChangeDark;
      var imglogo = document.getElementById("imglogo");
      imglogo.classList.toggle("content-img-logo-black");
      this.$store.commit("setModDark", active);
    },
    bMenuProfile() {},
  },
};
</script>
<style scoped>

.content-icon-list-menu{
  width: 20px;
}
.content-menu-mobile {
  background-color: var(--primary-color-menu) !important;
  overflow: auto;
}
.item-menu-mobile {
  cursor: pointer;
  margin-bottom: 10px;
}
.item-menu-mobile:hover {
  background-color: var(--primary-color-background-list-menu-hover);
  border-radius: 15px;
}
.list-item-option {
  background-color: var(--primary-color-text);
}
.icon-list-menu {
  color: var(--primary-color-text) !important;
}
.list-selected {
  background-color: var(--primary-color-background-list-selected);
  border-radius: 13px;
}
.text-list-menu {
  color: var(--primary-color-text) !important;
  font-size: 16px;
  font-family: "pop-Regular";
}
.content-navbar {
  display: flex;
  justify-content: end;
  align-items: center;
  color: white;
  width: 100%;
  height: 80px;
}
.content-left {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80px;
  max-width: 80px;
  min-width: 80px;
}
.content-right {
  display: flex;
  overflow: hidden !important;
  justify-content: end;
  align-items: center;
  width: 20vw;
  margin-right: 30px;
}

.content-center {
  width: 100%;
}
.text-slogan {
  font-family: "pop-SemiBold";
  margin-bottom: 0px;
  font-size: 17px;
  text-align: center;
  justify-self: center;
  letter-spacing: 3.6px;
  color: var(--primary-color-text-yellow);
  opacity: 1;
}
.btn-menu-profile {
  height: 31px !important;
  width: 31px !important;
  margin-left: 5px !important;
}
.iconMenu {
  color: var(--primary-color-text) !important;
}
.btn-menu-mobile {
  display: none !important;
}
.img-logo {
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* margin-left: 20px;
  margin-top: 10px; */
}
.content-img {
  cursor: pointer;
}
.content-img-logo {
  width: 250px;
  min-width: 250px;
  max-width: 250px;
  height: 60px;
  padding: 10px 5px 0px 5px;
  /* margin-left: 20px; */
  /* margin-top: 10px; */
  /* background: var(--primary-img-logo) no-repeat center; */
  background: url("../assets/ImgLogin/mini-logo-recamsa-white.png") no-repeat
    center;
  background-size: cover;
  background-position: center center;
  background-origin: content-box;
}
.content-img-logo-black {
  width: 250px;
  min-width: 250px;
  max-width: 250px;
  padding: 5px;
  height: 60px;
  /* margin-left: 20px;
  margin-top: 10px; */
  background: url("../assets/ImgLogin/mini-logo-recamsa-black.png") no-repeat
    center !important;
  background-size: cover !important;
  background-position: center center !important;
  background-origin: content-box !important;
}
.content-name-switch-mod-dark {
  display: flex;
  width: 100%;
}
.content-switch {
  display: flex;
  justify-content: end;
  width: 30%;
  align-items: center;
}
.switch-change-mod-dark {
  align-items: center;
  margin-top: -5px;
  /* margin-right: 15px; */
}
.icon-switch {
  color: var(--primary-color-text-menu-profile);
  margin-right: 15px;
}
.content-name-profile {
  display: flex;
  width: 100%;
  justify-content: start;
  align-content: center;
}
.text-name-profile {
  color: var(--primary-color-text-menu-profile);
  margin-left: 15px;
  margin-top: 15px;
  margin-right: 15px;
  font-size: 1rem;
  font-family: "pop-SemiBold";
}
.content-img-profile {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  object-fit: cover;
}
.content-border-profile {
  background-color: var(--primary-color-border-profile);
}
.content-center-profile {
  background-color: var(--primary-color-border-profile);
}
.content-profile-menu {
  background-color: var(--primary-color-menu-profile) !important;
  width: 350px;

  overflow: hidden;
  border-radius: 10px !important;
  border: var(--primary-color-border-menu-profile) solid 1px;
}
.img-profile {
  object-fit: cover;
}

.content-user-profile {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
  margin-left: 25px;
  cursor: pointer;
}
.content-user-profile:hover {
  text-decoration: underline !important;
}
.text-user-profile {
  align-items: center;
  height: 100%;
  font-family: "pop-Regular";
}

.content-logout {
  display: flex;
  cursor: pointer;
  margin-left: 25px;
  font-family: "pop-Regular";
}
.content-logout:hover {
  text-decoration: underline !important;
}

/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }
  .btn-menu-mobile {
    display: block !important;
  }
  .btn-menu-full {
    display: none !important;
  }
  .content-left {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10%;
    max-width: 10%;
    min-width: 10%;
  }
  .content-img-logo {
    width: 200px;
    min-width: 200px;
    max-width: 200px;
    height: 40px;
    padding: 5px;
    /* margin-left: 20px; */
    /* margin-top: 10px; */
    /* background: var(--primary-img-logo) no-repeat center; */
    background: url("../assets/ImgLogin/mini-logo-recamsa-white.png") no-repeat
      center;
    background-size: cover;
    background-position: center center;
    background-origin: content-box;
  }
  .content-img-logo-black {
    width: 200px;
    min-width: 200px;
    max-width: 200px;
    padding: 5px;
    height: 40px;
    /* margin-left: 20px; */
    /* margin-top: 10px; */
    background: url("../assets/ImgLogin/mini-logo-recamsa-black.png") no-repeat
      center !important;
    background-size: cover !important;
    background-position: center center !important;
    background-origin: content-box !important;
  }
  .content-center {
    display: none;
  }
  .content-right {
    display: flex;
    overflow: hidden !important;

    justify-content: end;
    align-items: center;
    width: 100%;
    margin-right: 0px !important;
  }
}
/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }
  .btn-menu-mobile {
    display: block !important;
  }
  .btn-menu-full {
    display: none !important;
  }
  .content-left {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    max-width: 40px;
    min-width: 40px;
  }
  .content-img-logo {
    width: 200px;
    min-width: 200px;
    max-width: 200px;
    height: 40px;
    padding: 5px;
    /* margin-left: 20px; */
    /* margin-top: 10px; */
    /* background: var(--primary-img-logo) no-repeat center; */
    background: url("../assets/ImgLogin/mini-logo-recamsa-white.png") no-repeat
      center;
    background-size: cover;
    background-position: center center;
    background-origin: content-box;
  }
  .content-img-logo-black {
    width: 200px;
    min-width: 200px;
    max-width: 200px;
    padding: 5px;
    height: 40px;
    /* margin-left: 20px; */
    /* margin-top: 10px; */
    background: url("../assets/ImgLogin/mini-logo-recamsa-black.png") no-repeat
      center !important;
    background-size: cover !important;
    background-position: center center !important;
    background-origin: content-box !important;
  }
  .content-right {
    display: flex;
    justify-content: end;
    align-items: center;
    overflow: hidden !important;
    margin-right: 0px;
    width: 40vw;
  }
}
/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}
/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}
/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>