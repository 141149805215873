import Vue from "vue";
import VueRouter from "vue-router";
import Store from "../store";
import reroute from "../reroute";

import router_guests from "@/router_guests";
import router_users from "@/router_users";

Vue.use(VueRouter);

var allRoutes = [];
allRoutes = allRoutes.concat(router_guests, router_users);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes: allRoutes,
});

router.beforeEach((to, from, next) => {
  if (to.name !== 'AccountDetail' && to.name !== 'TransationDetail' && to.name !== 'UserProfileDetailAccount' && to.name !== 'OPTransationDetailAccount') {
    Store.commit("setTabPositionAccounts",0)
  }
  if (to.name == "HomeLogin") {
    if (Store.state.sToken !== "") {
      next({
        path: "/home",
      });
    } else {
      next();
    }
  } else {
    if (Store.state.sToken !== "") {
      if (Store.state.aPermissions === null) {
        if (to.name === null) {
          next({
            name: "Dashboard",
          });
        } else {
          next();
        }
      } else {
        if (
          Store.state.aPermissions
            .map((e) => e.sModuleId)
            .includes(to.meta.permission, 0)
        ) {
          next();
        } else {
          next({
            name: reroute.redirectUser(Store.state.aPermissions),
          });
        }
      }
    } else {
      next({
        path: "/",
      });
    }
  }
});

export default router;
