<template>
  <div>
      <o-p-general-detail-component/>
  </div>
</template>

<script>
export default {
  data() {
    return {
      
    };
  },
};
</script>

<style>
</style>