<template>
  <div>
      <sales-orders-detail-customer-component/>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>
</style>