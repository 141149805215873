<template>
  <div>
      <provider-detail-o-p-payable-component/>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>