<template>
  <v-app>
    <router-view />
    <!--</v-main>-->
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({}),
  computed: {
    sModuleIdGlobal() {
      return this.$store.state.sModuleIdGlobal;
    },
  },
  watch: {
    $route(to, from) {
      let aResult = this.$store.state.aMenuGlobal;
      let aRouteName = aResult.find(
        (e) => e.sModuleId === this.sModuleIdGlobal
      ).routeName;
      if (!aRouteName.includes(to.name)) {
        this.$store.commit("setSearchGlobal", "");
        this.$store.commit("setStatusGlobal", []);
        this.$store.commit("setTypeOperationGlobal", "");
        this.$store.commit("setRangeDateGlobal", []);
        this.$store.commit("setCurrentPageGlobal", 1);
        this.$store.commit("setItemsPerPageGlobal", 30);
      } else {
        // console.log("No vaciar filtros");
      }
    },
  },
};
</script>
<style src="@/style.css"></style>
