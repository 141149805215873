<template>
    <div>
        <user-profile-component/>
    </div>
</template>

<script>

export default {
  name: 'UserProfileView',
}
</script>