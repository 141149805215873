<template>
  <div>
    <provider-component />
  </div>
</template>

<script>
export default {
  name: "Provider",
};
</script>