var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.isMobile)?_c('v-data-table',{staticClass:"global-table",attrs:{"mobile-breakpoint":"0","headers":_vm.headers,"disable-sort":true,"loading":_vm.bLoadingTable,"items":_vm.items,"items-per-page":_vm.iItemsPerPage,"hide-default-footer":true,"loading-text":"Cargando datos...","no-data-text":"No hay información disponible para mostrar.","footer-props":{
      'items-per-page-text': 'Filas por página',
      'items-per-page-all-text': 'Todos',
    }},scopedSlots:_vm._u([{key:"body",fn:function(ref){
    var items = ref.items;
return [_c('tbody',[_vm._l((items),function(item){return _c('tr',{key:item.sFolio,staticClass:"table-tr-custom"},[(_vm.bShowOPGeneral)?_c('td',{staticClass:"text-left table-td-custom global-body-name"},[_c('a',{staticClass:"no-style-a-tag",attrs:{"href":'/home/order-details/' + item.sPurchaseOrderId + '/payables'}},[_c('p',{staticClass:"table-item-text-custom"},[_c('v-icon',{staticClass:"text-icon-launch mr-2"},[_vm._v(" mdi-launch ")]),_c('span',{staticClass:"text-information-link"},[_vm._v(" "+_vm._s(item.sReference)+" ")])],1)])]):_c('td',{staticClass:"text-left table-td-custom global-body-name"},[_c('p',{staticClass:"table-item-text-custom"},[_c('v-icon',{staticClass:"text-icon-launch-without-link mr-2"},[_vm._v(" mdi-launch ")]),_c('span',{staticClass:"table-item-text-custom"},[_vm._v(" "+_vm._s(item.sReference)+" ")])],1)]),_c('td',{staticClass:"text-left table-td-custom"},[(item.sVendorName)?_c('p',{staticClass:"table-item-text-custom"},[_vm._v(" "+_vm._s(item.sVendorName)+" ")]):_c('p',{class:_vm.getValidatePBGlobal(item.sReference)
                  ? 'table-item-text-custom'
                  : 'text-data-empty'},[_vm._v(" "+_vm._s(_vm.getValidatePBGlobal(item.sReference) ? "Registro de báscula pública" : _vm.sEmptyData)+" ")])]),_c('td',{staticClass:"text-left table-td-custom"},[_c('p',{staticClass:"incomes-color table-item-text-custom text-end"},[_vm._v(" "+_vm._s(item.sAmountToPay)+" ")])]),_c('td',{staticClass:"text-left table-td-custom"},[_c('p',{staticClass:"incomes-color table-item-text-custom text-end"},[_vm._v(" "+_vm._s(item.sAmountInitial)+" ")])]),_c('td',{staticClass:"text-center table-td-custom"},[_c('p',{staticClass:"table-item-text-custom"},[_vm._v(" "+_vm._s(item.tCreatedAt)+" ")])])])}),(items.length === 0)?_c('tr',[_c('td',{staticClass:"text-center text-data-empty",attrs:{"colspan":_vm.headers.length}},[_vm._v(" No hay información disponible para mostrar. ")])]):_vm._e()],2)]}},{key:"footer",fn:function(){return [_c('footer-table-component-global',{attrs:{"numPages":_vm.numPages,"totalItems":_vm.totalItems,"bInitialCurrentPage":_vm.bInitialCurrentPage},on:{"changeTable":_vm.getEmitItems}})]},proxy:true}],null,false,2856362069)},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"var(--primary-color-loading-table)","indeterminate":""}})],1)],2):_c('div',[_c('div',{staticClass:"content-table-mobile"},[_c('div',[(_vm.items.length == 0)?_c('div',{staticClass:"content-empty-table"},[_c('span',{staticClass:"text-empty-table"},[_vm._v(" No hay información disponible para mostrar. ")])]):_vm._e(),_vm._l((_vm.items),function(itemTable,index){return _c('div',{key:index,attrs:{"id":"TableMobileOC"}},[_c('v-container',[_c('div',{staticClass:"content-all"},[_c('div',{staticClass:"content-left"},[_c('p',[_vm._v("Folio")])]),_c('div',{staticClass:"content-right"},[_c('a',{staticClass:"no-style-a-tag",attrs:{"href":'/home/order-details/' +
                    itemTable.sPurchaseOrderId +
                    '/payables'}},[_c('p',{staticClass:"text-title"},[_c('v-icon',{staticClass:"text-icon-launch mr-2"},[_vm._v(" mdi-launch ")]),_c('span',{staticClass:"text-information-link"},[_vm._v(" "+_vm._s(itemTable.sReference)+" ")])],1)])])]),_c('div',{staticClass:"content-all"},[_c('div',{staticClass:"content-left"},[_c('p',[_vm._v("Proveedor")])]),_c('div',{staticClass:"content-right"},[_c('p',{staticClass:"text-right"},[(itemTable.sVendorName)?_c('span',[_vm._v(" "+_vm._s(itemTable.sVendorName)+" ")]):_c('span',{class:_vm.getValidatePBGlobal(itemTable.sReference)
                        ? ''
                        : 'text-data-empty'},[_vm._v(" "+_vm._s(_vm.getValidatePBGlobal(itemTable.sReference) ? "Registro de báscula pública" : _vm.sEmptyData)+" ")])])])]),_c('div',{staticClass:"content-all"},[_c('div',{staticClass:"content-left"},[_c('p',[_vm._v("Cantidad por pagar")])]),_c('div',{staticClass:"content-right"},[_c('p',{staticClass:"incomes-color text-right"},[_vm._v(" "+_vm._s(itemTable.sAmountToPay)+" ")])])]),_c('div',{staticClass:"content-all"},[_c('div',{staticClass:"content-left"},[_c('p',[_vm._v("Cantidad inicial")])]),_c('div',{staticClass:"content-right"},[_c('p',{staticClass:"incomes-color text-right"},[_vm._v(" "+_vm._s(itemTable.sAmountInitial)+" ")])])]),_c('div',{staticClass:"content-all"},[_c('div',{staticClass:"content-left"},[_c('p',[_vm._v("Fecha de operación")])]),_c('div',{staticClass:"content-right"},[_c('p',{staticClass:"text-right"},[_vm._v(" "+_vm._s(itemTable.tCreatedAt)+" ")])])])]),_c('v-divider',{staticClass:"divider-global mt-3 mb-3"})],1)})],2),_c('footer-table-component-global',{attrs:{"numPages":_vm.numPages,"totalItems":_vm.totalItems,"bInitialCurrentPage":_vm.bInitialCurrentPage},on:{"changeTable":_vm.getEmitItems}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }