export const globalfunctions = {
  methods: {
    ObjEmptyGlobal(Obj) {
      return Object.keys(Obj).length > 0;
    },
    formatMoneyGlobal(number) {
      let convert_number = Number(number).toFixed(2);
      return convert_number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    formatQuantityGlobal(number) {
      let convert_number = Number(number).toFixed(0);
      return convert_number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    formatPhoneGlobal(oContactInfo) {
      let sCountryCallingCode = oContactInfo.sCountryCallingCode;
      switch (sCountryCallingCode) {
        case "52":
          return (
            "+" +
            oContactInfo.sCountryCallingCode +
            " (" +
            oContactInfo.sAreaCallingCode +
            ") " +
            [
              oContactInfo.sPhoneNumber.slice(0, 4),
              "-",
              oContactInfo.sPhoneNumber.slice(4),
            ].join("") +
            (oContactInfo.sPhoneExtension
              ? " ext. " + oContactInfo.sPhoneExtension + ""
              : "")
          );

        case "1":
          return (
            "+" +
            oContactInfo.sCountryCallingCode +
            " (" +
            oContactInfo.sAreaCallingCode +
            ") " +
            [
              oContactInfo.sPhoneNumber.slice(0, 3),
              "-",
              oContactInfo.sPhoneNumber.slice(3),
            ].join("") +
            (oContactInfo.sPhoneExtension
              ? " ext. " + oContactInfo.sPhoneExtension + ""
              : "")
          );

        default:
          break;
      }
    },
    formatDateGlobal(date) {
      const aDate = date.split("-");
      return (
        aDate[0] + " " + this.aMonth[parseInt(aDate[1]) - 1] + " " + aDate[2]
      );
    },
    getItemStatusOrderGlobal() {
      this.$store
        .dispatch("getItemStatusOrderGlobal")
        .then((resp) => {
          let aStatusOrder = [];
          switch (this.$route.name) {
            case "OPGeneral":
              aStatusOrder = resp.data.results;
              break;
            case "OPPending":
              aStatusOrder = resp.data.results.filter(e=> e.value === "PESO INICIAL" || e.value === "PESO ASIGNADO");
              break;

            default:
              break;
          }
          this.$store.commit("setItemStatusOrderGlobal", aStatusOrder);
        })
        .catch((err) => {
          this.Error(err);
        });
    },
    getOpenPDFGlobal(sPDF) {
      let LinkPDF = sPDF;
      let popUp = window.open(LinkPDF, "_blank");
      if (popUp === null || typeof popUp === "undefined") {
        this.Error("", "Activar ventana emergente del navegador");
      } else {
        popUp.focus();
      }
    },
    getValidatePBGlobal(sReference) {
      return sReference.includes("PB")
    }
  },
};
