<template>
  <div>
    <div v-if="!bLoading">
      <v-data-table
        v-if="!isMobile"
        mobile-breakpoint="0"
        :headers="headers"
        :disable-sort="true"
        :loading="bLoadingTable"
        :items="items"
        :items-per-page="iItemsPerPage"
        :hide-default-footer="true"
        loading-text="Cargando datos..."
        no-data-text="No hay información disponible para mostrar."
        :footer-props="{
          'items-per-page-text': 'Filas por página',
          'items-per-page-all-text': 'Todos',
        }"
        class="global-table"
      >
        <template slot="progress">
          <v-progress-linear
            color="var(--primary-color-loading-table)"
            indeterminate
          >
          </v-progress-linear>
        </template>
        <template v-slot:body="{ items }">
          <tbody>
            <tr
              v-for="item in items"
              :key="item.sFolio"
              class="table-tr-custom"
            >
              <td class="table-td-custom global-body-name">
                <p class="table-item-text-custom">
                  {{ item.sFolio }}
                </p>
              </td>
              <td class="text-left table-td-custom">
                <p class="table-item-text-custom">
                  {{ item.sBeneficiaryName }}
                </p>
              </td>
              <td class="text-center table-td-custom">
                <p class="table-item-text-custom">
                  {{ item.Accounts.sName }}
                </p>
              </td>
              <td class="text-center table-td-custom">
                <p class="incomes-color table-item-text-custom text-end">
                  {{ item.dAmountFormat }}
                </p>
              </td>
              <td class="text-center table-td-custom">
                <p class="table-item-text-custom">
                  {{ item.tTransactionDate }}
                </p>
              </td>
              <td class="text-center">
                <v-btn
                  :to="
                    '/home/transaction-detail/' +
                    item.Accounts.sAccountId +
                    '/' +
                    item.sAccountTransactionid +
                    '/incomes'
                  "
                  class="global-btn-table-detail"
                  icon
                >
                  <v-icon> mdi-eye-outline </v-icon>
                </v-btn>
              </td>
            </tr>
            <tr v-if="items.length === 0">
              <td :colspan="headers.length" class="text-center text-data-empty">
                No hay información disponible para mostrar.
              </td>
            </tr>
          </tbody>
        </template>
        <template v-slot:footer>
          <footer-table-component-global
            :numPages="numPages"
            :totalItems="totalItems"
            :bInitialCurrentPage="bInitialCurrentPage"
            @changeTable="updateFooterParams"
          />
        </template>
      </v-data-table>
      <div v-else>
        <div class="content-table-mobile">
          <div>
            <div v-if="items.length == 0" class="content-empty-table">
              <span class="text-empty-table">
                No hay información disponible para mostrar.
              </span>
            </div>
            <div
              id="TableMobileOC"
              v-for="(itemTable, index) in items"
              :key="index"
            >
              <v-container>
                <div class="content-all-first">
                  <v-spacer></v-spacer>
                  <div class="content-right-first">
                    <v-menu
                      attach
                      bottom
                      left
                      :close-on-content-click="false"
                      offset-y
                      rounded="lg"
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn icon v-on="on">
                          <v-icon
                            style="color: var(--primary-color-text) !important"
                          >
                            mdi-dots-vertical
                          </v-icon>
                        </v-btn>
                      </template>
                      <v-card class="content-accion-menu">
                        <v-container>
                          <v-row>
                            <v-col
                              cols="12"
                              v-for="(item, index) in aMenuAccion"
                              :key="index"
                              class="content-accion"
                            >
                              <a
                                style="
                                  text-decoration: none !important;
                                  text-transform: none !important;
                                "
                                text
                                flat
                                :href="
                                  '/home/transaction-detail/' +
                                  itemTable.Accounts.sAccountId +
                                  '/' +
                                  itemTable.sAccountTransactionid +
                                  '/incomes'
                                "
                              >
                                <v-icon> mdi-eye-outline </v-icon>
                                <span
                                  style="
                                    color: black;
                                    text-transform: none !important;
                                  "
                                  class="text-menu-accion"
                                >
                                  {{ item.text }}
                                </span>
                              </a>
                            </v-col>
                          </v-row>
                        </v-container>
                      </v-card>
                    </v-menu>
                  </div>
                </div>
                <div class="content-all">
                  <div class="content-left">
                    <p>Folio</p>
                  </div>
                  <div class="content-right">
                    <p class="text-right">
                      {{ itemTable.sFolio }}
                    </p>
                  </div>
                </div>
                <div class="content-all">
                  <div class="content-left">
                    <p>Cuenta</p>
                  </div>
                  <div class="content-right">
                    <p class="text-right">
                      {{ itemTable.Accounts.sName }}
                    </p>
                  </div>
                </div>
                <div class="content-all">
                  <div class="content-left">
                    <p>Cantidad ingresada</p>
                  </div>
                  <div class="content-right">
                    <p class="incomes-color text-right">
                      {{ itemTable.dAmountFormat }}
                    </p>
                  </div>
                </div>
                <div class="content-all">
                  <div class="content-left">
                    <p>Fecha de transacción</p>
                  </div>
                  <div class="content-right">
                    <p class="text-right">
                      {{ itemTable.tTransactionDate }}
                    </p>
                  </div>
                </div>
              </v-container>
              <v-divider class="divider-global mt-3 mb-3"></v-divider>
            </div>
          </div>
          <footer-table-component-global
            :numPages="numPages"
            :totalItems="totalItems"
            :bInitialCurrentPage="bInitialCurrentPage"
            @changeTable="updateFooterParams"
          />
        </div>
      </div>
    </div>
    <span v-else>
      <v-layout style="height: 100%" row wrap align-center justify-center>
        <loading-animation></loading-animation>
      </v-layout>
    </span>
  </div>
</template>
<script>
export default {
  data: () => ({
    bLoading: false,
    bLoadingTable: false,
    iCurrentPage: 1,
    bInitialCurrentPage: false,
    iPageNumber: 1,
    iItemsPerPage: 30,
    numPages: 0,
    totalItems: 0,
    totalView: 0,
    items: [],
    headers: [
      {
        text: "Folio",
        sortable: false,

        value: "sFolio",
        class: "global-header-table global-header-name",
        cellClass: "global-body-table global-body-name ",
      },
      {
        text: "Beneficiario",
        sortable: false,
        align: "left",
        value: "sBeneficiaryName",
        class: "global-header-table",
        cellClass: "global-body-table",
      },
      {
        text: "Cuenta bancaria",
        sortable: false,
        align: "center",
        value: "sAccountName",
        class: "global-header-table",
        cellClass: "global-body-table",
      },
      {
        text: "Cantidad ingresada",
        sortable: false,
        align: "end",
        value: "dAmountFormat",
        class: "global-header-table",
        cellClass: "global-body-table",
      },
      {
        text: "Fecha de transacción",
        sortable: false,
        align: "center",
        value: "tTransactionDate",
        class: "global-header-table",
        cellClass: "global-body-table",
      },
      {
        text: "Acciones",
        align: "center",
        sortable: false,
        class: "global-header-table",
        cellClass: "global-body-table",
      },
    ],
    aMenuAccion: [
      {
        text: "Detalle",
        icon: "mdi-eye",
        color: "black !important",
      },
    ],
  }),
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    refreshTable() {
      this.iCurrentPage = 1;
      return this.$store.state.refresh;
    },
  },
  mounted() {
    this.getItems();
  },
  methods: {
    updateFooterParams(e) {
      this.iPageNumber = e.iCurrentPage;
      this.iCurrentPage = e.iCurrentPage;
      this.iItemsPerPage = e.iItemsPerPage;
      this.getItems();
    },
    getItems() {
      // this.bLoading = true
      DB.get(`${URI}/api/sp/v1/finances/incomes`, {
        params: {
          iPageNumber: this.iCurrentPage,
          iItemsPerPage: this.iItemsPerPage,
          sSearch: this.sSearch,
          tStart: this.tStartDate,
          tEnd: this.tEndDate,
        },
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
      })
        .then((response) => {
          this.bLoading = false;
          this.numPages = response.data.numPages;
          this.totalItems = response.data.total;
          this.items = response.data.results.incomes.map((e) => {
            return {
              ...e,
              // sBeneficiaryName: "----",
            };
          });
          this.$emit("update", {
            dIncomes: response.data.incomeBalance,
            dExpenses: response.data.expensesBalance,
          });
          this.$store.commit("refresher", false);
        })
        .catch((error) => {
          this.bLoading = false;
          this.Error(error.response.data);
        });
    },
  },
  watch: {
    refreshTable: function () {
      this.getItems();
    },
    sSearch() {
      this.getItems();
    },
    tStartDate() {
      // this.bInitialCurrentPage = !this.bInitialCurrentPage;
      // this.getItems();
    },
    tEndDate() {
      this.bInitialCurrentPage = !this.bInitialCurrentPage;
      this.iCurrentPage = 1;
      this.getItems();
    },
  },
  components: {
    LoadingAnimation: () =>
      import(
        "../../global_components/loading-animations/loading-animation-one.vue"
      ),
  },
  props: {
    sSearch: String,
    tStartDate: String,
    tEndDate: String,
  },
};
</script>
<style scoped>
.content-all-first {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
}

.content-left-first {
  width: 50%;
  display: flex;
  align-items: center;
  font-family: "pop-Bold";
  font-size: 18px;
}

.content-empty-table {
  padding: 10px 15px 0px 15px;
  text-align: center;
}

.text-empty-table {
  color: var(--primary-color-text);
  font-family: "pop-Regular";
  font-size: 14px;
}

.table-item-text-custom {
  color: var(--primary-color-text) !important;
  font-family: "pop-Light";
  font-size: 14px !important;
}

.table-td-custom {
  vertical-align: bottom;
}

.incomes-color {
  color: #00a85b !important;
  font-weight: bold;
}
</style>

<style scoped>
/*#region CSS GLOBAL  */
.content-all-first {
  display: flex;
  width: 100%;
  color: var(--primary-color-text) !important;
  margin-bottom: 10px;
}

.content-all {
  display: flex;
  width: 100%;
  color: var(--primary-color-text) !important;
}

.content-left-first {
  width: 50%;
  display: flex;
  align-items: center;
  font-family: "pop-Bold";
  font-size: 18px;
}

.content-left {
  width: 50%;
  font-family: "pop-Semibold";
  font-size: 14px;
}

.text-right {
  /* white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden; */
}

.content-right-first {
  width: 50%;
  text-align: end;
  font-family: "pop-Light";
  font-size: 14px;
  margin-left: 5px;
  position: relative !important;
}

.content-right {
  width: 50%;
  text-align: end;
  font-family: "pop-Light";
  font-size: 14px;
  margin-left: 5px;
}

.content-right {
  overflow: auto;
}

.content-right::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.content-right::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.content-right::-webkit-scrollbar-thumb {
  background: var(--primary-color-scroll);

  border-radius: 10px;
}

.content-right::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color-scroll);
}

.content-table-mobile {
  background-color: var(--primary-color-background-table) !important;
  box-shadow: 0px 0px 10px 5px var(--primary-color-border-shadow) !important;
  border-radius: 10px;
}

.content-title {
  text-align: start;
}

.text-head {
  color: var(--primary-color-text) !important;
}

.content-text {
  /* text-align: end; */
}

.text-response {
  color: var(--primary-color-text) !important;
}

.text-menu-accion {
  font-family: "pop-Semibold";
  font-size: 14px;
  margin-left: 5px;
}

.content-accion-menu {
  width: 150px;
  max-width: 150px;
  min-width: 150px;
}

.content-accion {
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
}

.content-empty-table {
  padding: 10px 15px 0px 15px;
  text-align: center;
}

.text-empty-table {
  color: var(--primary-color-text);
  font-family: "pop-Regular";
  font-size: 14px;
}

/*#endregion CSS GLOBAL  */

/*#region CSS RAW MATERIAL */

/*#region CSS SKEYCOLOR */
.content-color-id-expenses {
  display: flex;
  justify-content: center;
  margin-right: 5px;
}

.color-expenses {
  height: 12px;
  width: 12px;
  border-radius: 100%;
  border: 1px solid var(--primary-color-text);
}

/*#endregion CSS SKEYCOLOR */

/*#region CSS VARIATION */
.content-chip-difference {
  display: flex;
  justify-content: end;
  width: 100%;
}

.chip-difference {
  align-content: center;
  width: 120px;
  text-align: center !important;
  justify-content: center;
  display: flex;
  font-family: "pop-Bold";
}

.content-text-icon-variation {
  display: flex;
  width: 120px;
}

.content-icon-variation {
  width: auto !important;
}

.text-variation {
  width: 100% !important;
  text-align: end !important;
  align-self: center !important;
}

/*#endregion CSS VARIATION */

/*#endregion CSS RAW MATERIAL */
</style>
<style scoped>
.text-menu-accion {
  font-family: "pop-Semibold";
  font-size: 14px;
  margin-left: 5px;
}

.content-accion-menu {
  width: 150px;
  max-width: 150px;
  min-width: 150px;
}

.content-accion {
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
}
</style>
