<template>
  <div>
    <account-detail-component />
    <delete-component-global />
  </div>
</template>
<script>
export default {
  name: "AccountDetailView",
};
</script>