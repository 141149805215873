<template>
  <o-p-detail-transation-component />
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>
