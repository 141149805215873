<template>
  <div>
    <v-data-table
      v-if="!isMobile"
      mobile-breakpoint="0"
      :headers="headers"
      :disable-sort="true"
      :loading="bLoadingTable"
      :items="items"
      :items-per-page="iItemsPerPage"
      :hide-default-footer="true"
      loading-text="Cargando datos..."
      no-data-text="No hay información disponible para mostrar."
      :footer-props="{
        'items-per-page-text': 'Filas por página',
        'items-per-page-all-text': 'Todos',
      }"
      class="global-table"
    >
      <template slot="progress">
        <v-progress-linear
          color="var(--primary-color-loading-table)"
          indeterminate
        >
        </v-progress-linear>
      </template>

      <template v-slot:body="{ items }">
        <tbody>
          <tr v-for="item in items" :key="item.sFolio" class="table-tr-custom">
            <td
              v-if="bShowOPGeneral"
              class="text-left table-td-custom global-body-name"
            >
              <a
                class="no-style-a-tag"
                :href="
                  '/home/order-details/' + item.sPurchaseOrderId + '/payables'
                "
              >
                <p class="table-item-text-custom">
                  <v-icon class="text-icon-launch mr-2"> mdi-launch </v-icon>
                  <span class="text-information-link">
                    {{ item.sReference }}
                  </span>
                </p>
              </a>
            </td>
            <td v-else class="text-left table-td-custom global-body-name">
              <p class="table-item-text-custom">
                <v-icon class="text-icon-launch-without-link mr-2">
                  mdi-launch
                </v-icon>
                <span class="table-item-text-custom">
                  {{ item.sReference }}
                </span>
              </p>
            </td>
            <td class="text-left table-td-custom">
              <p v-if="item.sVendorName" class="table-item-text-custom">
                {{ item.sVendorName }}
              </p>
              <p
                v-else
                :class="
                  getValidatePBGlobal(item.sReference)
                    ? 'table-item-text-custom'
                    : 'text-data-empty'
                "
              >
                {{
                  getValidatePBGlobal(item.sReference)
                    ? "Registro de báscula pública"
                    : sEmptyData
                }}
              </p>
            </td>
            <td class="text-left table-td-custom">
              <p class="incomes-color table-item-text-custom text-end">
                {{ item.sAmountToPay }}
              </p>
            </td>
            <td class="text-left table-td-custom">
              <p class="incomes-color table-item-text-custom text-end">
                {{ item.sAmountInitial }}
              </p>
            </td>
            <td class="text-center table-td-custom">
              <p class="table-item-text-custom">
                {{ item.tCreatedAt }}
              </p>
            </td>
          </tr>
          <tr v-if="items.length === 0">
            <td :colspan="headers.length" class="text-center text-data-empty">
              No hay información disponible para mostrar.
            </td>
          </tr>
        </tbody>
      </template>

      <template v-slot:footer>
        <footer-table-component-global
          :numPages="numPages"
          :totalItems="totalItems"
          :bInitialCurrentPage="bInitialCurrentPage"
          @changeTable="getEmitItems"
        />
      </template>
    </v-data-table>
    <div v-else>
      <div class="content-table-mobile">
        <div>
          <div v-if="items.length == 0" class="content-empty-table">
            <span class="text-empty-table">
              No hay información disponible para mostrar.
            </span>
          </div>
          <div
            id="TableMobileOC"
            v-for="(itemTable, index) in items"
            :key="index"
          >
            <v-container>
              <div class="content-all">
                <div class="content-left">
                  <p>Folio</p>
                </div>
                <div class="content-right">
                  <a
                    class="no-style-a-tag"
                    :href="
                      '/home/order-details/' +
                      itemTable.sPurchaseOrderId +
                      '/payables'
                    "
                  >
                    <p class="text-title">
                      <v-icon class="text-icon-launch mr-2">
                        mdi-launch
                      </v-icon>
                      <span class="text-information-link">
                        {{ itemTable.sReference }}
                      </span>
                    </p>
                  </a>
                </div>
              </div>
              <div class="content-all">
                <div class="content-left">
                  <p>Proveedor</p>
                </div>
                <div class="content-right">
                  <p class="text-right">
                    <span v-if="itemTable.sVendorName">
                      {{ itemTable.sVendorName }}
                    </span>
                    <span
                      v-else
                      :class="
                        getValidatePBGlobal(itemTable.sReference)
                          ? ''
                          : 'text-data-empty'
                      "
                    >
                      {{
                        getValidatePBGlobal(itemTable.sReference)
                          ? "Registro de báscula pública"
                          : sEmptyData
                      }}
                    </span>
                  </p>
                </div>
              </div>
              <div class="content-all">
                <div class="content-left">
                  <p>Cantidad por pagar</p>
                </div>
                <div class="content-right">
                  <p class="incomes-color text-right">
                    {{ itemTable.sAmountToPay }}
                  </p>
                </div>
              </div>
              <div class="content-all">
                <div class="content-left">
                  <p>Cantidad inicial</p>
                </div>
                <div class="content-right">
                  <p class="incomes-color text-right">
                    {{ itemTable.sAmountInitial }}
                  </p>
                </div>
              </div>
              <div class="content-all">
                <div class="content-left">
                  <p>Fecha de operación</p>
                </div>
                <div class="content-right">
                  <p class="text-right">
                    {{ itemTable.tCreatedAt }}
                  </p>
                </div>
              </div>
            </v-container>
            <v-divider class="divider-global mt-3 mb-3"></v-divider>
          </div>
        </div>
        <footer-table-component-global
          :numPages="numPages"
          :totalItems="totalItems"
          :bInitialCurrentPage="bInitialCurrentPage"
          @changeTable="getEmitItems"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    sEmptyData: "Sin información.",
    bLoadingTable: true,
    iCurrentPage: 1,
    bInitialCurrentPage: false,

    iPageNumber: 1,
    iItemsPerPage: 30,
    numPages: 0,
    totalItems: 0,
    totalView: 0,
    items: [],
    headers: [
      {
        text: "Folio",
        sortable: false,
        align: "left",
        value: "sFolio",
        class: "global-header-table global-header-name",
        cellClass: "global-body-table global-body-name ",
      },
      {
        text: "Proveedor",
        sortable: false,
        align: "left",
        value: "sAccountName",
        class: "global-header-table",
        cellClass: "global-body-table",
      },
      {
        text: "Cantidad por pagar",
        sortable: false,
        align: "end",
        value: "sAmountToPay",
        class: "global-header-table",
        cellClass: "global-body-table",
      },
      {
        text: "Cantidad inicial",
        sortable: false,
        align: "end",
        value: "sAmountInitial",
        class: "global-header-table",
        cellClass: "global-body-table",
      },
      {
        text: "Fecha de la operación",
        sortable: false,
        align: "center",
        value: "tCreatedAt",
        class: "global-header-table",
        cellClass: "global-body-table",
      },
    ],
  }),
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown;
    },
    bShowOPGeneral() {
      return this.$store.state.bShowOPGeneral;
    },
  },
  mounted() {
    this.getItems();
  },
  methods: {
    getEmitItems(obj) {
      this.iCurrentPage = obj.iCurrentPage;
      this.iItemsPerPage = obj.iItemsPerPage;
      this.getItems();
    },
    getItems(e) {
      this.bLoadingTable = true;
      DB.get(`${URI}/api/sp/v1/finances/orderstopay`, {
        params: {
          sVendorEnterpriseId: this.itemChosen,
          iPageNumber: this.iCurrentPage,
          iItemsPerPage: this.iItemsPerPage,
          sSearch: this.sSearch,
          tStart: this.tStartDate,
          tEnd: this.tEndDate,
        },
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
      })
        .then((response) => {
          this.bLoadingTable = false;
          this.numPages = response.data.numPages;
          this.totalItems = response.data.total;
          this.items = response.data.results.map((e) => {
            return {
              ...e,
              sAmountToPay:
                e.dMexicanPesoAmountToPayFormat +
                " / " +
                e.dUSDollarAmountToPayFormat,
              sAmountInitial:
                "$" +
                this.formatMoneyGlobal(e.dOriginalMexicanPesoTotalAmount) +
                "MXN /  $" +
                this.formatMoneyGlobal(e.dOriginalUSDollarTotalAmount) +
                "USD",
            };
          });
          this.$emit("update", {
            totalToCollect: response.data.totalToCollect.oMexicanPeso.dTotal,
            totalToPay: response.data.totalToPay.oMexicanPeso.dTotal,
            totalToCollectUSD: response.data.totalToCollect.oUSDollar.dTotal,
            totalToPayUSD: response.data.totalToPay.oUSDollar.dTotal,
          });
        })
        .catch((error) => {
          this.bLoadingTable = false;
          this.Error(error.response.data);
        });
    },
  },
  watch: {
    sSearch() {
      this.getItems();
    },
    tStartDate() {
      //   this.getItems();
    },
    tEndDate() {
      this.bInitialCurrentPage = !this.bInitialCurrentPage;
      this.iCurrentPage = 1;
      this.getItems();
    },
    itemChosen() {
      this.bInitialCurrentPage = !this.bInitialCurrentPage;
      this.iCurrentPage = 1;
      this.getItems();
    },
  },
  components: {
    LoadingAnimation: () =>
      import(
        "../../global_components/loading-animations/loading-animation-one.vue"
      ),
  },
  props: {
    itemChosen: String,
    value: Object,
    sSearch: String,
    tStartDate: String,
    tEndDate: String,
  },
};
</script>
<style scoped>
.text-icon-launch-without-link {
  color: var(--primary-color-text) !important;
  font-size: 20px;
}

.content-all-first {
  display: flex;
  width: 100%;
  margin-bottom: 10px;
}

.content-left-first {
  width: 50%;
  display: flex;
  align-items: center;
  font-family: "pop-Bold";
  font-size: 18px;
}

.content-empty-table {
  padding: 10px 15px 0px 15px;
  text-align: center;
}

.text-empty-table {
  color: var(--primary-color-text);
  font-family: "pop-Regular";
  font-size: 14px;
}

.table-item-text-custom {
  color: var(--primary-color-text) !important;
  font-family: "pop-Light";
  font-size: 14px !important;
}

.table-td-custom {
  vertical-align: bottom;
}

.incomes-color {
  color: var(--primary-color-text) !important;
  font-weight: bold;
}
</style>

<style scoped>
/*#region CSS GLOBAL  */
.content-all-first {
  display: flex;
  width: 100%;
  color: var(--primary-color-text) !important;
  margin-bottom: 10px;
}

.content-all {
  display: flex;
  width: 100%;
  color: var(--primary-color-text) !important;
}

.content-left-first {
  width: 50%;
  display: flex;
  align-items: center;
  font-family: "pop-Bold";
  font-size: 18px;
}

.content-left {
  width: 50%;
  font-family: "pop-Semibold";
  font-size: 14px;
}

.text-right {
  /* white-space: nowrap !important;
  text-overflow: ellipsis !important;
  overflow: hidden; */
}

.content-right-first {
  width: 50%;
  text-align: end;
  font-family: "pop-Light";
  font-size: 14px;
  margin-left: 5px;
  position: relative !important;
}

.content-right {
  width: 50%;
  text-align: end;
  font-family: "pop-Light";
  font-size: 14px;
  margin-left: 5px;
}

.content-right {
  overflow: auto;
}

.content-right::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}

.content-right::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

.content-right::-webkit-scrollbar-thumb {
  background: var(--primary-color-scroll);

  border-radius: 10px;
}

.content-right::-webkit-scrollbar-thumb:hover {
  background: var(--primary-color-scroll);
}

.content-table-mobile {
  background-color: var(--primary-color-background-table) !important;
  box-shadow: 0px 0px 10px 5px var(--primary-color-border-shadow) !important;
  border-radius: 10px;
}

.content-title {
  text-align: start;
}

.text-head {
  color: var(--primary-color-text) !important;
}

.content-text {
  /* text-align: end; */
}

.text-response {
  color: var(--primary-color-text) !important;
}

.text-menu-accion {
  font-family: "pop-Semibold";
  font-size: 14px;
  margin-left: 5px;
}

.content-accion-menu {
  width: 150px;
  max-width: 150px;
  min-width: 150px;
}

.content-accion {
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
}

.content-empty-table {
  padding: 10px 15px 0px 15px;
  text-align: center;
}

.text-empty-table {
  color: var(--primary-color-text);
  font-family: "pop-Regular";
  font-size: 14px;
}

/*#endregion CSS GLOBAL  */

/*#region CSS RAW MATERIAL */

/*#region CSS SKEYCOLOR */
.content-color-id-expenses {
  display: flex;
  justify-content: center;
  margin-right: 5px;
}

.color-expenses {
  height: 12px;
  width: 12px;
  border-radius: 100%;
  border: 1px solid var(--primary-color-text);
}

/*#endregion CSS SKEYCOLOR */

/*#region CSS VARIATION */
.content-chip-difference {
  display: flex;
  justify-content: end;
  width: 100%;
}

.chip-difference {
  align-content: center;
  width: 120px;
  text-align: center !important;
  justify-content: center;
  display: flex;
  font-family: "pop-Bold";
}

.content-text-icon-variation {
  display: flex;
  width: 120px;
}

.content-icon-variation {
  width: auto !important;
}

.text-variation {
  width: 100% !important;
  text-align: end !important;
  align-self: center !important;
}

/*#endregion CSS VARIATION */

/*#endregion CSS RAW MATERIAL */
</style>
<style scoped>
.text-menu-accion {
  font-family: "pop-Semibold";
  font-size: 14px;
  margin-left: 5px;
}

.content-accion-menu {
  width: 150px;
  max-width: 150px;
  min-width: 150px;
}

.content-accion {
  display: flex;
  align-items: center;
  width: 100%;
  cursor: pointer;
}

.text-icon-launch {
  color: var(--primary-color-link);
  /* margin-top: -5px; */
  font-size: 20px;
  cursor: pointer;
}

.no-style-a-tag {
  text-decoration: none !important;
  text-transform: none !important;
  margin: 0px !important;
  padding: 0px !important;
}
</style>
