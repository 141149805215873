<template>
    <div>
        <customer-detail-component />
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    }
}
</script>

<style>
</style>