import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import "./modules";
import vuetify from "./plugins/vuetify";
import VueApexCharts from "vue-apexcharts";
import VueFeatherIcon from "vue-feather-icon";
import DisableAutocomplete from "vue-disable-autocomplete";
import Vuesax from "vuesax";
import { notification } from "./notification";
import { globalfunctions } from "./global_functions_js";
import "vuesax/dist/vuesax.css"; //Vuesax styles
import "vue-photo-zoom-pro/dist/style/vue-photo-zoom-pro.css";

Vue.use(Vuesax, {
  // options here
});

Vue.use(DisableAutocomplete);

Vue.use(VueApexCharts);
Vue.mixin(notification);
Vue.mixin(globalfunctions);

Vue.component("apexchart", VueApexCharts);

Vue.use(VueFeatherIcon);

Vue.config.productionTip = false;

// initiate axios as a window variable for convinience ( ex: DB.get ; DB.post ; DB.put ; DB.delete )
window.DB = axios;



// window.URI = 'http://192.168.1.2:3000'
// window.URI = 'http://ec2-52-207-239-56.compute-1.amazonaws.com/beta'
// window.URI = "http://ec2-52-207-239-56.compute-1.amazonaws.com/dev";
// window.URI = "http://ec2-52-207-239-56.compute-1.amazonaws.com/alfa";
// window.URI = "http://ec2-52-207-239-56.compute-1.amazonaws.com/dev";

window.URI = "https://api.recamsa.com.mx";

window.FORMAT_MONEY = function (number) {
  let convert_number = Number(number).toFixed(2);
  return convert_number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
};

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
