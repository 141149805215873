var reroute = {
  /**
   *
   * @param {UserPerrmisionArray} permissions
   *
   */
  redirectUser: function (permissions) {
    // var permission = permissions.filter((e) => e.sModuleId !=="0c556c00-ac5b-441d-89ef-5c051196102b");
    var firstRoute = this.resolveRoutes(permissions[0].sModuleId);
    return firstRoute;
  },
  resolveRoutes: function (permission) {
    switch (permission) {
      case "9d99fb23-2d47-4ada-aeca-a6e3f3d236e5":
        return "Dashboard";
      case "1":
        return "PayablesAndRecievables";
      case "2":
        return "IncomeAndExpenses";
      case "dd28e22e-abed-4b96-8dde-53cfb250f5b8":
        return "Provider";
      case "31fc53df-ed76-4421-85ba-1dfa8ebdef1f":
        return "RawMaterial";
      case "df3cc403-5b68-47b8-824c-180bf5a489e2":
        return "Administrators";
      case "4":
        return "UserProfile";
      case "43e44764-625d-42fc-b470-ad60ab5d4121":
        return "Account";
      case "5f387e75-f270-403d-98a8-ef9a475d4fe0":
        return "OPGeneral";
      case "259d467d-ddcb-4499-a100-39907c751d7f":
        return "OPPending";
      case "787ebe0e-7fa3-4597-90b1-4507e2473071":
        return "OPPayable";
      case "f0831b6b-3c6d-46c2-9327-97800e73bd20":
        return "Audit";
      case "0c556c00-ac5b-441d-89ef-5c051196102b":
        return "Customer";
      default:
        return "UserProfile";
    }
  },
};

export default reroute;
