<template>
  <div>
    <o-p-payable-detail-component />
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style>
</style>