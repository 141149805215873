<template>
  <op-general-component />
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style>
</style>