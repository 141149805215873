var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.bLoading)?_c('div',[(!_vm.isMobile)?_c('v-data-table',{staticClass:"global-table",attrs:{"mobile-breakpoint":"0","headers":_vm.headers,"disable-sort":true,"loading":_vm.bLoadingTable,"items":_vm.items,"items-per-page":_vm.iItemsPerPage,"hide-default-footer":true,"loading-text":"Cargando datos...","no-data-text":"No hay información disponible para mostrar.","footer-props":{
        'items-per-page-text': 'Filas por página',
        'items-per-page-all-text': 'Todos',
      }},scopedSlots:_vm._u([{key:"body",fn:function(ref){
      var items = ref.items;
return [_c('tbody',[_vm._l((items),function(item){return _c('tr',{key:item.sFolio,staticClass:"table-tr-custom"},[_c('td',{staticClass:"table-td-custom global-body-name"},[_c('p',{staticClass:"table-item-text-custom"},[_vm._v(" "+_vm._s(item.sFolio)+" ")])]),_c('td',{staticClass:"text-left table-td-custom"},[_c('p',{staticClass:"table-item-text-custom"},[_vm._v(" "+_vm._s(item.sBeneficiaryName)+" ")])]),_c('td',{staticClass:"text-center table-td-custom"},[_c('p',{staticClass:"table-item-text-custom"},[_vm._v(" "+_vm._s(item.Accounts.sName)+" ")])]),_c('td',{staticClass:"text-center table-td-custom"},[_c('p',{staticClass:"incomes-color table-item-text-custom text-end"},[_vm._v(" "+_vm._s(item.dAmountFormat)+" ")])]),_c('td',{staticClass:"text-center table-td-custom"},[_c('p',{staticClass:"table-item-text-custom"},[_vm._v(" "+_vm._s(item.tTransactionDate)+" ")])]),_c('td',{staticClass:"text-center"},[_c('v-btn',{staticClass:"global-btn-table-detail",attrs:{"to":'/home/transaction-detail/' +
                  item.Accounts.sAccountId +
                  '/' +
                  item.sAccountTransactionid +
                  '/incomes',"icon":""}},[_c('v-icon',[_vm._v(" mdi-eye-outline ")])],1)],1)])}),(items.length === 0)?_c('tr',[_c('td',{staticClass:"text-center text-data-empty",attrs:{"colspan":_vm.headers.length}},[_vm._v(" No hay información disponible para mostrar. ")])]):_vm._e()],2)]}},{key:"footer",fn:function(){return [_c('footer-table-component-global',{attrs:{"numPages":_vm.numPages,"totalItems":_vm.totalItems,"bInitialCurrentPage":_vm.bInitialCurrentPage},on:{"changeTable":_vm.updateFooterParams}})]},proxy:true}],null,false,1691023309)},[_c('template',{slot:"progress"},[_c('v-progress-linear',{attrs:{"color":"var(--primary-color-loading-table)","indeterminate":""}})],1)],2):_c('div',[_c('div',{staticClass:"content-table-mobile"},[_c('div',[(_vm.items.length == 0)?_c('div',{staticClass:"content-empty-table"},[_c('span',{staticClass:"text-empty-table"},[_vm._v(" No hay información disponible para mostrar. ")])]):_vm._e(),_vm._l((_vm.items),function(itemTable,index){return _c('div',{key:index,attrs:{"id":"TableMobileOC"}},[_c('v-container',[_c('div',{staticClass:"content-all-first"},[_c('v-spacer'),_c('div',{staticClass:"content-right-first"},[_c('v-menu',{attrs:{"attach":"","bottom":"","left":"","close-on-content-click":false,"offset-y":"","rounded":"lg"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                  var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":""}},on),[_c('v-icon',{staticStyle:{"color":"var(--primary-color-text) !important"}},[_vm._v(" mdi-dots-vertical ")])],1)]}}],null,true)},[_c('v-card',{staticClass:"content-accion-menu"},[_c('v-container',[_c('v-row',_vm._l((_vm.aMenuAccion),function(item,index){return _c('v-col',{key:index,staticClass:"content-accion",attrs:{"cols":"12"}},[_c('a',{staticStyle:{"text-decoration":"none !important","text-transform":"none !important"},attrs:{"text":"","flat":"","href":'/home/transaction-detail/' +
                                itemTable.Accounts.sAccountId +
                                '/' +
                                itemTable.sAccountTransactionid +
                                '/incomes'}},[_c('v-icon',[_vm._v(" mdi-eye-outline ")]),_c('span',{staticClass:"text-menu-accion",staticStyle:{"color":"black","text-transform":"none !important"}},[_vm._v(" "+_vm._s(item.text)+" ")])],1)])}),1)],1)],1)],1)],1)],1),_c('div',{staticClass:"content-all"},[_c('div',{staticClass:"content-left"},[_c('p',[_vm._v("Folio")])]),_c('div',{staticClass:"content-right"},[_c('p',{staticClass:"text-right"},[_vm._v(" "+_vm._s(itemTable.sFolio)+" ")])])]),_c('div',{staticClass:"content-all"},[_c('div',{staticClass:"content-left"},[_c('p',[_vm._v("Cuenta")])]),_c('div',{staticClass:"content-right"},[_c('p',{staticClass:"text-right"},[_vm._v(" "+_vm._s(itemTable.Accounts.sName)+" ")])])]),_c('div',{staticClass:"content-all"},[_c('div',{staticClass:"content-left"},[_c('p',[_vm._v("Cantidad ingresada")])]),_c('div',{staticClass:"content-right"},[_c('p',{staticClass:"incomes-color text-right"},[_vm._v(" "+_vm._s(itemTable.dAmountFormat)+" ")])])]),_c('div',{staticClass:"content-all"},[_c('div',{staticClass:"content-left"},[_c('p',[_vm._v("Fecha de transacción")])]),_c('div',{staticClass:"content-right"},[_c('p',{staticClass:"text-right"},[_vm._v(" "+_vm._s(itemTable.tTransactionDate)+" ")])])])]),_c('v-divider',{staticClass:"divider-global mt-3 mb-3"})],1)})],2),_c('footer-table-component-global',{attrs:{"numPages":_vm.numPages,"totalItems":_vm.totalItems,"bInitialCurrentPage":_vm.bInitialCurrentPage},on:{"changeTable":_vm.updateFooterParams}})],1)])],1):_c('span',[_c('v-layout',{staticStyle:{"height":"100%"},attrs:{"row":"","wrap":"","align-center":"","justify-center":""}},[_c('loading-animation')],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }