<template >
  <v-layout class="content-home">
    <!-- <div class="demoColorResponsive"></div> -->
    <div id="circleYellow" class="circle-yellow-backgorund"></div>
    <div id="circleBlue" class="circle-blue-backgorund"></div>

    <div class="content-navbar-home">
      <navbar />
      <v-divider class="divider-sidebar-global"></v-divider>
      <div class="content-sidebar-home-all">
        <div id="sidebar" class="content-sidebar-home"
          :style="{ 'height': screenHeight + 'px', 'max-height': screenHeight + 'px' }">
          <sidebar />
        </div>
        <div class="content-component"
          :style="{ 'width': screenWidth + 'px', 'max-width': screenWidth + 'px' + 'px', 'height': screenHeight + 'px', 'max-height': screenHeight + 'px', 'min-height': screenHeight + 'px' }">
          <router-view />
        </div>
      </div>
    </div>
  </v-layout>
</template>

<script>
import Navbar from "../global_components/Navbar.vue";
import Sidebar from "../global_components/Sidebar.vue";

export default {
  name: "Home",
  data() {
    return {
      screenHeight: 0,
      screenWidth: 0,
      bSid: null,
    };
  },
  components: {
    Navbar,
    Sidebar,
    // HelloWorld,
  },
  beforeMount() {
    this.bSid = this.bSidebar;
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    this.changeColor(this.bDark);
    this.sidebarActive(this.bSidebar);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize: function () {
      this.screenHeight = window.innerHeight - 70;

      if (window.innerWidth > 959.99) {
        this.widthGlobal();
      } else {
        this.screenWidth = window.innerWidth - 40;
      }
    },
    widthGlobal() {
      if (this.bSidebar) {
        this.screenWidth = window.innerWidth - 335;
      } else {
        this.screenWidth = window.innerWidth - 125;
      }
    },
    changeColor(bActive) {
      if (bActive) {
        //MOD DARK

        document.documentElement.style.setProperty(
          "--primary-img-logo",
          "url('@/assets/ImgLogin/mini-logo-recamsa-white.png') "
        );
        document.documentElement.style.setProperty(
          "--primary-color-background",
          "#171521"
        );
        document.documentElement.style.setProperty(
          "--primary-color-text",
          "#FFFFFF"
        );
        document.documentElement.style.setProperty(
          "--primary-color-menu",
          "#262C3A"
        );
        document.documentElement.style.setProperty(
          "--primary-color-divider",
          "#4F5C7C"
        );
        document.documentElement.style.setProperty(
          "--primary-color-switch",
          "#9232E5"
        );
        document.documentElement.style.setProperty(
          "--primary-color-background-list-menu",
          "#3B445A"
        );
        document.documentElement.style.setProperty(
          "--primary-color-background-table",
          "rgb(58, 65, 80, .5)"
        );
        document.documentElement.style.setProperty(
          "--primary-color-btn-neutral",
          "rgb(58, 65, 80, .7)"
        );
        document.documentElement.style.setProperty(
          "--primary-color-menu-profile",
          "rgb(250, 250, 250, .99)"
        );
        document.documentElement.style.setProperty(
          "--primary-color-border-text-field",
          "#4F5C7C"
        );
        document.documentElement.style.setProperty(
          "--primary-color-background-btn-view-img",
          "#3B445A"
        );
        document.documentElement.style.setProperty(
          "--primary-color-background-card",
          "#282B37"
        );
        document.documentElement.style.setProperty(
          "--primary-color-menu-filter",
          "rgb(250, 250, 250, .99)"
        );
        document.documentElement.style.setProperty(
          "--primary-color-skeleton",
          "#FFFFFFFF"
        );
        document.documentElement.style.setProperty(
          "--primary-color-placeholder-input",
          "#4C5772"
        );
        document.documentElement.style.setProperty(
          "--primary-color-border-input",
          "#4C5772"
        );
        document.documentElement.style.setProperty(
          "--primary-color-line-border-table",
          "#4C5772"
        );
        document.documentElement.style.setProperty(
          "--primary-color-background-button-upload-image",
          "#4C5772"
        );
        document.documentElement.style.setProperty(
          "--primary-color-background-modal",
          "#4C5772"
        );
        document.documentElement.style.setProperty(
          "--primary-color-background-switch-permissions",
          "#4C5772"
        );
        document.documentElement.style.setProperty(
          "--primary-color-text-background-status",
          "#282B38"
        );
        document.documentElement.style.setProperty(
          "--primary-color-background-list-selected",
          "#3B445A"
        );
        document.documentElement.style.setProperty(
          "--primary-color-background-loading",
          "#4c5772"
        );

      } //MOD LIGHT
      else {
        document.documentElement.style.setProperty(
          "--primary-img-logo",
          "url('@/assets/ImgLogin/mini-logo-recamsa-white.png') "
        );
        document.documentElement.style.setProperty(
          "--primary-color-background",
          "#FFFFFF"
        );
        document.documentElement.style.setProperty(
          "--primary-color-text",
          "#171521"
        );
        document.documentElement.style.setProperty(
          "--primary-color-menu",
          "#FFFFFF"
        );
        document.documentElement.style.setProperty(
          "--primary-color-divider",
          "rgba(0, 0, 0, 0.12)"
        );
        document.documentElement.style.setProperty(
          "--primary-color-switch",
          "#F1D72E"
        );
        document.documentElement.style.setProperty(
          "--primary-color-background-list-menu",
          "#EBECEE"
        );
        document.documentElement.style.setProperty(
          "--primary-color-background-table",
          "rgb(255, 255, 255,0.5)"
        );
        document.documentElement.style.setProperty(
          "--primary-color-btn-neutral",
          "#EBECEE"
        );
        document.documentElement.style.setProperty(
          "--primary-color-menu-profile",
          "#FFFFFFFF"
        );
        document.documentElement.style.setProperty(
          "--primary-color-border-text-field",
          "#000000"
        );
        document.documentElement.style.setProperty(
          "--primary-color-background-btn-view-img",
          "#EBECEE"
        );
        document.documentElement.style.setProperty(
          "--primary-color-background-card",
          "#FFFFFFFF"
        );
        document.documentElement.style.setProperty(
          "--primary-color-menu-filter",
          "#FFFFFFFF"
        );
        document.documentElement.style.setProperty(
          "--primary-color-skeleton",
          "#E0E0DF"
        );
        document.documentElement.style.setProperty(
          "--primary-color-placeholder-input",
          "rgba(0, 0, 0, 0.50)"
        );
        document.documentElement.style.setProperty(
          "--primary-color-border-input",
          "rgba(0, 0, 0, 0.12)"
        );
        document.documentElement.style.setProperty(
          "--primary-color-line-border-table",
          "rgba(0, 0, 0, 0.12)"
        );
        document.documentElement.style.setProperty(
          "--primary-color-background-button-upload-image",
          "#FFFFFFFF"
        );
        document.documentElement.style.setProperty(
          "--primary-color-background-modal",
          "#000000"
        );
        document.documentElement.style.setProperty(
          "--primary-color-background-switch-permissions",
          "rgba(0, 0, 0, 0.38)"
        );
        document.documentElement.style.setProperty(
          "--primary-color-text-background-status",
          "#FFFFFFFF"
        );
        document.documentElement.style.setProperty(
          "--primary-color-background-list-selected",
          "#EBECEE"
        );
        document.documentElement.style.setProperty(
          "--primary-color-background-loading",
          "#ffffff"
        );
 
      }
    },
    sidebarActive(bSide) {
      this.$nextTick(() => {
        if (bSide) {
          var sidbar = document.getElementById("sidebar");
          sidbar.classList.add("content-sidebar-home-open");

          if (window.innerWidth > 959.99) {
            var circleYellow = document.getElementById("circleYellow");
            circleYellow.classList.add("circle-yellow-backgorund-open-menu");

            var circleBlue = document.getElementById("circleBlue");
            circleBlue.classList.add("circle-blue-backgorund-open-menu");
          }
        } else {
          var sidbar = document.getElementById("sidebar");
          sidbar.classList.remove("content-sidebar-home-open");

          if (window.innerWidth > 959.99) {
            var circleYellow = document.getElementById("circleYellow");
            circleYellow.classList.remove("circle-yellow-backgorund-open-menu");

            var circleBlue = document.getElementById("circleBlue");
            circleBlue.classList.remove("circle-blue-backgorund-open-menu");
          }
        }
      });
    },
  },
  computed: {
    colorBackground() {
      return this.$vuetify.theme.themes;
    },
    bSidebar() {
      return this.$store.state.bSidebar;
    },
    bDark() {
      return this.$store.state.bDark;
    },
  },
  watch: {
    bSidebar() {
      this.sidebarActive(this.bSidebar);
      this.handleResize();

      // var sidbar = document.getElementById("sidebar");
      // sidbar.classList.toggle("content-sidebar-home-open");

      // if (window.innerWidth > 960) {
      //   var circleYellow = document.getElementById("circleYellow");
      //   circleYellow.classList.toggle("circle-yellow-backgorund-open-menu");

      //   var circleBlue = document.getElementById("circleBlue");
      //   circleBlue.classList.toggle("circle-blue-backgorund-open-menu");
      // }
    },
    bDark() {
      this.changeColor(this.bDark);
    },
  },
};
</script>

<style scoped>
.content-home {
  display: flex !important;
  position: relative !important;
  background-color: var(--primary-color-background);
  overflow: hidden;
  height: 100%;
}

.content-sidebar-home {
  width: 90px !important;
  max-width: 90px !important;
  min-width: 90px !important;
  transition: 0.5s;
  padding: 10px 10px 10px 0px !important;
  z-index: 6;
}

.content-sidebar-home-open {
  width: 300px !important;
  max-width: 300px !important;
  min-width: 300px !important;
  transition: 0.5s;
  padding: 10px 10px 10px 0px !important;
  z-index: 6;
}

.content-navbar-home {
  width: 100vw;
  padding: 0px 20px 0px 20px;
  z-index: 6;
  height: 100%;
  max-height: 100% !important;
  min-height: 100% !important;
  overflow: hidden !important;
}

.content-sidebar-home-all {
  display: flex;
  overflow: auto;
}

.content-component {
  transition: 0.5s;
  padding: 10px 0px 10px 0px;
  overflow: auto !important;
}

.circle-yellow-backgorund {
  background-color: transparent;

  /* ff 3.6+ */
  background: -moz-radial-gradient(ellipse at 52% 50%,
      var(--primary-color-circle-yellow-background) 0%,
      transparent 50%,
      transparent 100%);

  /* safari 5.1+,chrome 10+ */
  background: -webkit-radial-gradient(ellipse at 52% 50%,
      var(--primary-color-circle-yellow-background) 0%,
      transparent 50%,
      transparent 100%);

  /* opera 11.10+ */
  background: -o-radial-gradient(ellipse at 52% 50%,
      var(--primary-color-circle-yellow-background) 0%,
      transparent 50%,
      transparent 100%);

  /* ie 10+ */
  background: -ms-radial-gradient(ellipse at 52% 50%,
      var(--primary-color-circle-yellow-background) 0%,
      transparent 50%,
      transparent 100%);

  /* global 92%+ browsers support */
  background: radial-gradient(ellipse at 52% 50%,
      var(--primary-color-circle-yellow-background) 10%,
      transparent 50%,
      transparent 100%);
  height: 20rem;
  width: 20rem;
  border-radius: 100%;
  opacity: 0.18;
  position: absolute;
  left: 5rem;
  top: 2rem;
}

.circle-yellow-backgorund-open-menu {
  background-color: var(--primary-color-circle-yellow-background);
  transition: all 0.5s !important;

  /* ff 3.6+ */
  background: -moz-radial-gradient(ellipse at 52% 50%,
      var(--primary-color-circle-yellow-background) 0%,
      transparent 50%,
      transparent 100%);

  /* safari 5.1+,chrome 10+ */
  background: -webkit-radial-gradient(ellipse at 52% 50%,
      var(--primary-color-circle-yellow-background) 0%,
      transparent 50%,
      transparent 100%);

  /* opera 11.10+ */
  background: -o-radial-gradient(ellipse at 52% 50%,
      var(--primary-color-circle-yellow-background) 0%,
      transparent 50%,
      transparent 100%);

  /* ie 10+ */
  background: -ms-radial-gradient(ellipse at 52% 50%,
      var(--primary-color-circle-yellow-background) 0%,
      transparent 50%,
      transparent 100%);

  /* global 92%+ browsers support */
  background: radial-gradient(ellipse at 52% 50%,
      var(--primary-color-circle-yellow-background) 10%,
      transparent 50%,
      transparent 100%);
  height: 20rem;
  width: 20rem;
  border-radius: 100%;
  opacity: 0.18;
  /* box-shadow: 0px 0px 80px var(--primary-color-circle-yellow-background); */
  position: absolute;
  left: 20rem !important;
  top: 2rem;
}

.circle-blue-backgorund {
  background-color: var(--primary-color-circle-blue-background);
  transition: all 0.5s !important;

  /* ff 3.6+ */
  background: -moz-radial-gradient(ellipse at 52% 50%,
      var(--primary-color-circle-blue-background) 0%,
      transparent 50%,
      transparent 100%);

  /* safari 5.1+,chrome 10+ */
  background: -webkit-radial-gradient(ellipse at 52% 50%,
      var(--primary-color-circle-blue-background) 0%,
      transparent 50%,
      transparent 100%);

  /* opera 11.10+ */
  background: -o-radial-gradient(ellipse at 52% 50%,
      var(--primary-color-circle-blue-background) 0%,
      transparent 50%,
      transparent 100%);

  /* ie 10+ */
  background: -ms-radial-gradient(ellipse at 52% 50%,
      var(--primary-color-circle-blue-background) 0%,
      transparent 50%,
      transparent 100%);

  /* global 92%+ browsers support */
  background: radial-gradient(ellipse at 52% 50%,
      var(--primary-color-circle-blue-background) 0%,
      transparent 50%,
      transparent 100%);
  height: 35rem;
  width: 90rem;
  border-radius: 100%;
  opacity: 0.18;
  /* box-shadow: 0px 0px 80px var(--primary-color-circle-blue-background); */
  position: absolute;
  left: -20vw;
  top: 40vh;
}

.circle-blue-backgorund-open-menu {
  background-color: var(--primary-color-circle-blue-background);
  transition: all 0.5s !important;

  /* ff 3.6+ */
  background: -moz-radial-gradient(ellipse at 52% 50%,
      var(--primary-color-circle-blue-background) 0%,
      transparent 50%,
      transparent 100%);

  /* safari 5.1+,chrome 10+ */
  background: -webkit-radial-gradient(ellipse at 52% 50%,
      var(--primary-color-circle-blue-background) 0%,
      transparent 50%,
      transparent 100%);

  /* opera 11.10+ */
  background: -o-radial-gradient(ellipse at 52% 50%,
      var(--primary-color-circle-blue-background) 0%,
      transparent 50%,
      transparent 100%);

  /* ie 10+ */
  background: -ms-radial-gradient(ellipse at 52% 50%,
      var(--primary-color-circle-blue-background) 0%,
      transparent 50%,
      transparent 100%);

  /* global 92%+ browsers support */
  background: radial-gradient(ellipse at 52% 50%,
      var(--primary-color-circle-blue-background) 0%,
      transparent 50%,
      transparent 100%);
  height: 35rem;
  width: 90rem;
  border-radius: 100%;
  opacity: 0.18;
  /* box-shadow: 0px 0px 80px var(--primary-color-circle-blue-background); */
  position: absolute;
  left: -5vw !important;
  top: 40vh;
}

/* RESPONSIVE CSS */
.demoColorResponsive {
  position: absolute;
  height: 2vh;
  width: 10vh;
  background-color: rgb(0, 0, 0);
  bottom: 0px;
  right: 50%;
  left: 50%;
}

/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-sidebar-home {
    display: none;
  }

  .content-sidebar-home-open {
    display: none;
  }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }

  .content-sidebar-home {
    display: none !important;
    transition: 1s;
  }

  .content-sidebar-home-open {
    display: none !important;
    transition: 1s;
  }
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>
