import Vue from "vue";
import Vuex from "vuex";
import PersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    //PARMAS LOGIN
    sUserId: "",
    sFullname: "",
    sRole: "",
    sEmail: "",
    sPhone: "",
    sCreateAt: "",
    sUrlImg: "",
    sToken: "",
    // aPermissions:[{sPermissionId:'1'},{sPermissionId:'2'}],
    aPermissions: null,

    //PERMISSIONS PROVIDER
    bAdminProvider: false,
    bShowProvider: false,
    //PERMISSIONS MATERIAL
    bAdminMaterial: false,
    bShowMaterial: false,
    //PERMISSIONS ADMIN
    bAdminAdministrator: false,
    bShowAdministrator: false,
    //PERMISSIONS CUSTOMER
    bAdminCustomer: false,
    bShowCustomer: false,
    //PERMISSIONS ACCOUNT
    bAdminAccount: false,
    bShowAccount: false,
    //PERMISSIONS OC GENERAL
    bAdminOPGeneral: false,
    bShowOPGeneral: false,
    //PERMISSIONS OC PENDING
    bAdminOPPending: false,
    bShowOPPending: false,
    //PERMISSIONS OC PAYABLE
    bAdminOPPayable: false,
    bShowOPPayable: false,
    //PERMISSIONS AUDIT
    bAdminAudit: false,
    bShowAudit: false,
    //PERMISSIONS CUSTOMER
    bAdminCustomer: false,
    bShowCustomer: false,

    //PARAMAS GLOBAS
    sNameRouteGlobal: "",
    sNameDetail: "",
    aRawMaterialsResetPrice: [],
    searchHeight: 0,
    sTypeActor: "",
    oError403: {
      code: 403,
      status: false,
      name: "CustomError",
      message: "Accesso denegado.",
    },
    sTabPositionOrderPayable: 0,
    sEmptyDataGlobal: "Sin información.",
    sModuleIdGlobal: "",

    //PARAMS CATALOG GLOBAL
    aItemStatusOrderGlobal: [],
    aItemStatusOrderIDPurchaseGlobal: [
      { text: "Seleccionar estado...", value: null, disabled: true },
      {
        text: "Creada",
        value: "42260d41-7c0e-49a8-930b-7527d27457a3",
      },
      {
        text: "Peso inicial",
        value: "3d55c1d3-704d-4856-a7af-18297b9e4d4d",
      },
      {
        text: "Materia identificada",
        value: "2ce6b152-5739-48dc-afdb-38a11d19cec2",
      },
      {
        text: "Peso asignado",
        value: "6f2e4b24-2dec-4fa2-a1bb-d1f1f741b492",
      },
      {
        text: "Pagada",
        value: "caa7c0d5-aa8b-4101-bc46-f92fe8a93d44",
      },
      {
        text: "Cancelada",
        value: "17008296-f494-4ea6-a83f-be7e1f162a63",
      },
    ],
    aItemStatusOrderIDSalesGlobal: [
      { text: "Seleccionar estado...", value: null, disabled: true },
      {
        text: "Creada",
        value: "403ffb08-8918-401b-8381-c98ac46130ef",
      },
      {
        text: "Peso inicial",
        value: "194f7a3f-632f-4be1-b1db-c1984279d29e",
      },
      {
        text: "Materia identificada",
        value: "6c7dfbba-b747-48a8-b696-bde23a2e47cd",
      },
      {
        text: "Peso asignado",
        value: "449fa03c-580f-4ef0-9674-8bcc85f8b0f3",
      },
      {
        text: "Pagada",
        value: "a5d69e14-9576-49be-bb7f-e33dd25ae011",
      },
      {
        text: "Cancelada",
        value: "875b85ca-335e-4a9c-9f93-dca8f93677ed",
      },
    ],
    aItemTypeAccountGlobal: [],

    //PARAMS SIDEBAR AND NAVBAR
    aMenuGlobal: [
      {
        sModuleId: "9d99fb23-2d47-4ada-aeca-a6e3f3d236e5",
        title: "Dashboard",
        view: "dashboard",
        name: "Dashboard",
        routeName: ["Dashboard"],
        img: "provider.png",
        iconMDI: "space_dashboard",
        href: "/dashboard",
        permission: "9d99fb23-2d47-4ada-aeca-a6e3f3d236e5",
      },
      {
        sModuleId: "123-report",
        title: "Reportes",
        view: "report",
        name: "Report",
        routeName: ["Report", "OPDetailReport"],
        img: "provider.png",
        iconMDI: "receipt_long",
        href: "/report",
        permission: "123-report",
      },
      {
        sModuleId: "1",
        title: "Cuentas por pagar y cobrar",
        view: "payables_and_recievables",
        name: "PayablesAndRecievables",
        routeName: ["Payables", "Recievables", "OperationDetails"],
        img: "provider.png",
        iconMDI: "paid",
        href: "/payables_and_recievables",
        permission: "1",
      },
      {
        sModuleId: "2",
        title: "Ingresos y Egresos",
        view: "income_and_expenses",
        name: "IncomeAndExpenses",
        routeName: [
          "Incomes",
          "Expenses",
          "TransactionDetail",
          "OPDetailTransactionDetail",
        ],
        img: "provider.png",
        iconMDI: "swap_vertical",
        href: "/income_and_expenses",
        permission: "2",
      },
      {
        sModuleId: "dd28e22e-abed-4b96-8dde-53cfb250f5b8",
        title: "Proveedores",
        view: "provider",
        name: "Provider",
        routeName: [
          "Provider",
          "ProviderDetail",
          "AddProvider",
          "OPDetailProvider",
          "UserProfileDetailProvider",
        ],
        img: "provider.png",
        iconMDI: "work_outline",
        href: "/provider",
        permission: "dd28e22e-abed-4b96-8dde-53cfb250f5b8",
      },
      {
        sModuleId: "31fc53df-ed76-4421-85ba-1dfa8ebdef1f",
        title: "Materias primas",
        view: "raw-material",
        name: "RawMaterial",
        routeName: ["RawMaterial", "RawMaterialDetail"],
        img: "raw-material.png",
        iconMDI: "grain",
        href: "/raw-material",
        permission: "31fc53df-ed76-4421-85ba-1dfa8ebdef1f",
      },
      {
        sModuleId: "df3cc403-5b68-47b8-824c-180bf5a489e2",
        title: "Administradores",
        name: "Administrators",
        view: "administrators",
        routeName: ["Administrators", "AdministratorsDetail"],
        icon: "users.png",
        iconMDI: "people_alt",
        href: "/administrators",
        permission: "df3cc403-5b68-47b8-824c-180bf5a489e2",
      },
      {
        sModuleId: "43e44764-625d-42fc-b470-ad60ab5d4121",
        title: "Cuentas financieras",
        view: "account",
        name: "Account",
        routeName: [
          "Account",
          "AccountDetail",
          "TransationDetail",
          "UserProfileDetailAccount",
          "OPTransationDetailAccount",
        ],
        icon: "user.png",
        iconMDI: "account_balance_wallet",
        href: "/account",
        permission: "43e44764-625d-42fc-b470-ad60ab5d4121",
      },
      {
        sModuleId: "5f387e75-f270-403d-98a8-ef9a475d4fe0",
        title: "Operaciones",
        view: "OP-General",
        name: "OPGeneral",
        routeName: ["OPGeneral", "OPGeneralDetail", "ActorDetailOPGeneral"],
        icon: "user.png",
        iconMDI: "description",
        href: "/op-general",
        permission: "5f387e75-f270-403d-98a8-ef9a475d4fe0",
      },
      {
        sModuleId: "259d467d-ddcb-4499-a100-39907c751d7f",
        title: "Especialista",
        view: "OP-Pending",
        name: "OPPending",
        routeName: [
          "OPPending",
          "OPPendingDetail",
          "AddOperation",
          "ProviderDetailOPPending",
        ],
        icon: "user.png",
        iconMDI: "hourglass_bottom",
        href: "/op-pending",
        permission: "259d467d-ddcb-4499-a100-39907c751d7f",
      },
      {
        sModuleId: "787ebe0e-7fa3-4597-90b1-4507e2473071",
        title: "Operaciones de caja",
        view: "OC-Payable",
        name: "OPPayable",
        routeName: ["OPPayable", "OPPayableDetail", "ProviderDetailOPPayable"],
        icon: "user.png",
        iconMDI: "point_of_sale",
        href: "/oc-payable",
        permission: "787ebe0e-7fa3-4597-90b1-4507e2473071",
      },
      {
        sModuleId: "f0831b6b-3c6d-46c2-9327-97800e73bd20",
        title: "Auditorías",
        view: "Audit",
        name: "Audit",
        routeName: ["Audit", "UserProfileDetailAudit"],
        icon: "user.png",
        iconMDI: "checklist",
        href: "/audit",
        permission: "f0831b6b-3c6d-46c2-9327-97800e73bd20",
      },
      {
        sModuleId: "0c556c00-ac5b-441d-89ef-5c051196102b",
        title: "Clientes",
        view: "Customer",
        name: "Customer",
        routeName: [
          "Customer",
          "AddCustomer",
          "CustomerDetail",
          "OPDetailCustomer",
        ],
        icon: "user.png",
        iconMDI: "switch_account",
        href: "/customer",
        permission: "0c556c00-ac5b-441d-89ef-5c051196102b",
      },
    ],
    bSidebar: false,
    bDark: true || localStorage.getItem("bChangeDark"),

    // #region Filtros fijos globales
    bEmptyFieldFilter: false,
    aRangeDatesGlobal: [],
    iCurrentPageGlobal: 1,
    iItemsPerPageGlobal: 30,
    sTypeOperationGlobal: [],
    aStatusGlobal: [],
    sSearchGlobal: "",

    // #endregion Filtros fijos globales

    // PARAMS TABLE GLOBALS
    refresh: false,
    sInOut: "",
    aTransition: ["in", "out"],
    sShowFor: "1",
    aTransitionIn: "in",
    aTransitionOut: "out",
    sSort: "",
    dStartDate: null,
    dEndDate: null,

    iCurrentPage: 1,
    iPageNumber: 1,
    iItemsPerPage: 30,
    iNumPages: 0,
    iTotalItems: 0,

    bMajorMinor: false,
    bEntries: false,
    bAZ: false,

    //GLOBAL DIALOG DELETE
    dialogDelete: false,
    textTitleDelete: "",
    textDescriptionDelete: "",
    textQuestionDelete: "",
    returnToView: false,
    apiDelete: "",

    //GLOBAL DIALOG ACTION CONFIRM
    dialogActionConfirm: false,
    textTitleActionConfirm: "",
    textDescriptionActionConfirm: "",
    textQuestionActionConfirm: "",
    returnToViewActionConfirm: false,
    apiActionConfirm: "",

    //DIALOG ADD PROVIDER
    dialogAddProvider: false,
    sTabPosition: 0,
    sTabPositionAccounts: 0,

    //DIALOG ADD RAW MATERIAL
    dialogAddRawMaterial: false,

    //DIALOG UPDATE PRICE RAW MATERIAL IN DETAIL PROVIDER
    dialogUpdatePriceRawMaterial: false,

    //DIALOG ADD ACCOUNT
    dialogAddAccount: false,

    //DIALOG ADD TRANSATION
    dialogAddTransation: false,

    //DIALOG EDIT INFORMATION
    dialogEditInformation: false,
    arrayObjectRawMaterial: [],

    //DIALOG NEW QUANTITY
    dialogNewQuantity: false,
    iCurrentAmount: 0,

    //DIALOG ADD ADMINISTRATOR
    dialogAddAdministrator: false,

    //DIALOG CHANGE PASSWORD
    dialogChangePassword: false,

    //DIALOG ADD RAW MATERIAL TO PURCHASE order:
    dialogAddRawMaterialToPurchaseOrder: false,

    //DIALOG CONFIRMATION OF PAID
    dialogConfirmationOfPaid: false,
  },
  mutations: {
    setNameRouteGlobal(state, item) {
      state.sNameRouteGlobal = item;
    },
    setCurrentPageGlobal(state, item) {
      state.iCurrentPageGlobal = item;
    },
    setItemsPerPageGlobal(state, item) {
      state.iItemsPerPageGlobal = item;
    },
    setRangeDateGlobal(state, item) {
      state.aRangeDatesGlobal = item;
    },
    setStatusGlobal(state, item) {
      state.aStatusGlobal = item;
    },
    setTypeOperationGlobal(state, item) {
      state.sTypeOperationGlobal = item;
    },
    setEmptyFieldFilter(state, item) {
      state.bEmptyFieldFilter = item;
    },
    setModDark(state, item) {
      state.bDark = item;
    },
    setInformationUser(state, item) {
      state.sUserId = item.sUserId;
      state.sToken = item.sToken;
      state.sFullname = item.sFullName;
      state.sRole = item.sRole;
      state.sEmail = item.sEmail;
      state.sPhone = item.sPhoneNumber;
      state.sCreateAt = item.tCreatedAt;
      state.sUrlImg = item.sUrlImg;
      state.aPermissions = item.aUserPermissions;
    },
    setPermissions(state, item) {
      state.aPermissions = item.aUserPermissions;
    },
    setRefreshFullName(state, item) {
      state.sFullname = item.sFullname;
    },
    setRefreshImg(state, item) {
      state.sUrlImg = item.sUrlImg;
    },
    openSidebar(state, item) {
      state.bSidebar = item;
    },
    setDialogActionConfirm(state, item) {
      state.dialogActionConfirm = item.active;
      state.textTitleActionConfirm = item.textTitleActionConfirm;
      state.textDescriptionActionConfirm = item.textDescriptionActionConfirm;
      state.textQuestionActionConfirm = item.textQuestionActionConfirm;
      state.returnToViewActionConfirm = item.returnToViewActionConfirm;
      state.apiActionConfirm = item.apiActionConfirm;
    },
    setDialogDelete(state, item) {
      state.dialogDelete = item.active;
      state.textTitleDelete = item.textTitle;
      state.textDescriptionDelete = item.textDescription;
      state.textQuestionDelete = item.textQuestion;
      state.returnToView = item.returnToView;
      state.apiDelete = item = item.api;
    },
    setDialogAddProvider(state, item) {
      state.dialogAddProvider = item.active;
    },
    setDialogAddRawMaterial(state, item) {
      state.dialogAddRawMaterial = item.active;
    },
    setDialogUpdatePriceRawMaterial(state, item) {
      state.dialogUpdatePriceRawMaterial = item.active;
    },
    setDialogAddAccount(state, item) {
      state.dialogAddAccount = item.active;
    },
    setDialogAddTransation(state, item) {
      state.dialogAddTransation = item.active;
    },
    setDialogEditInformation(state, item) {
      state.dialogEditInformation = item.active;
      state.arrayObjectRawMaterial = item.arrayObjectRawMaterial;
    },
    setDialogNewQuantity(state, item) {
      state.dialogNewQuantity = item.active;
      state.iCurrentAmount = item.iCurrentAmount;
    },
    setDialogAddAdministrator(state, item) {
      state.dialogAddAdministrator = item.active;
    },
    setDialogChangePassword(state, item) {
      state.dialogChangePassword = item.active;
    },
    setTabPosition(state, item) {
      state.sTabPosition = item.sTabPosition;
    },
    setTabPositionAccounts(state, sValue) {
      state.sTabPositionAccounts = sValue;
    },
    setsNameDetail(state, item) {
      state.sNameDetail = item;
    },
    setDialogAddRawMaterialToPurchaseOrder(state, item) {
      state.dialogAddRawMaterialToPurchaseOrder = item.active;
    },
    setDialogConfirmationOfPaid(state, item) {
      state.dialogConfirmationOfPaid = item.active;
    },
    setPushRawMateriasResetPrice(state, item) {
      state.aRawMaterialsResetPrice.push(item);
    },
    setRemoveRawMateriasResetPrice(state, item) {
      const index = state.aRawMaterialsResetPrice.indexOf(item);
      if (index > -1) {
        state.aRawMaterialsResetPrice.splice(index, 1); // 2nd parameter means remove one item only
      }
    },
    setEmptyRawMaterials(state, item) {
      state.aRawMaterialsResetPrice = [];
    },
    setTabPositionOrderPayable(state, item) {
      state.sTabPositionOrderPayable = item;
    },

    // SET PERMISSIONS IN MENU
    setPermissionProvider(state, item) {
      state.bShowProvider = item.bShow;
      state.bAdminProvider = item.bAdmin;
    },
    setPermissionMaterial(state, item) {
      state.bShowMaterial = item.bShow;
      state.bAdminMaterial = item.bAdmin;
    },
    setPermissionAdministrator(state, item) {
      state.bShowAdministrator = item.bShow;
      state.bAdminAdministrator = item.bAdmin;
    },
    setPermissionCustomer(state, item) {
      state.bShowCustomer = item.bShow;
      state.bAdminCustomer = item.bAdmin;
    },
    setPermissionAccount(state, item) {
      state.bShowAccount = item.bShow;
      state.bAdminAccount = item.bAdmin;
    },
    setPermissionOPGeneral(state, item) {
      state.bShowOPGeneral = item.bShow;
      state.bAdminOPGeneral = item.bAdmin;
    },
    setPermissionOPPending(state, item) {
      state.bShowOPPending = item.bShow;
      state.bAdminOPPending = item.bAdmin;
    },
    setPermissionOPPayable(state, item) {
      state.bShowOPPayable = item.bShow;
      state.bAdminOPPayable = item.bAdmin;
    },
    setPermissionAudit(state, item) {
      state.bShowAudit = item.bShow;
      state.bAdminAudit = item.bAdmin;
    },
    setSearchHeight(state, item) {
      state.searchHeight = item;
    },
    setTypeActor(state, value) {
      state.sTypeActor = value;
    },
    setModuleIdGlobal(state, value) {
      state.sModuleIdGlobal = value;
    },

    //#region update catalog global //
    setItemStatusOrderGlobal(state, item) {
      state.aItemStatusOrderGlobal = item;
    },
    setItemTypeAccountGlobal(state, item) {
      state.aItemTypeAccountGlobal = item;
    },
    //#endregion update catalog global //

    //#region --- upadte tables --- //
    refresher(state, item) {
      state.refresh = item;
    },
    setSearchGlobal(state, item) {
      state.sSearchGlobal = item;
    },
    setSort(state, item) {
      state.sSort = item;
    },
    setInOut(state, item) {
      state.sInOut = item;
    },
    setTransition(state, item) {
      state.aTransitionIn = item.in;
      state.aTransitionOut = item.out;
    },
    setsShowFor(state, item) {
      state.sShowFor = item;
    },
    setStartDate(state, item) {
      state.dStartDate = item;
    },
    setEndDate(state, item) {
      state.dEndDate = item;
    },
    setCurrentPage(state, item) {
      state.iCurrentPage = item;
    },
    setNumPages(state, item) {
      state.iNumPages = item;
    },
    setPageNumber(state, item) {
      state.iPageNumber = item;
    },
    setTotal(state, item) {
      state.iTotalItems = item;
    },
    setItemsPerPage(state, item) {
      state.iItemsPerPage = item;
    },
    setMajorMinor(sate, item) {
      state.bMajorMinor = item;
    },
    setEntries(state, item) {
      state.bEntries = item;
    },
    setAZ(state, item) {
      state.bAZ = item;
    },
    //#endregion --- upadte tables --- //
  },
  actions: {
    getPermissionsByUserGlobal({ dispatch, commit, state }) {
      return new Promise((resolve, reject) => {
        DB.get(`${URI}/api/sp/v1/users/${state.sUserId}/profile`, {
          headers: {
            Authorization: "Bearer " + state.sToken,
          },
        })
          .then((response) => {
            if (response.data.results.aUserPermissions !== null) {
              response.data.results.aUserPermissions.push({
                sModuleId: "4",
                sModuleName: "Perfil",
                aPermissions: [
                  {
                    sPermissionId: "01015b31-6d95-466a-8ba5-7cb8174c0778",
                    sPermissionName: "Ver",
                    bBoolean: true,
                  },
                  {
                    sPermissionId: "bea7f302-7e0c-43fc-a289-490b08fd9f63",
                    sPermissionName: "Administrar",
                    bBoolean: true,
                  },
                ],
              });

              let bAcountPermission =
                response.data.results.aUserPermissions.filter(
                  (e) => e.sModuleId === "43e44764-625d-42fc-b470-ad60ab5d4121"
                ).length > 0;
              if (bAcountPermission) {
                response.data.results.aUserPermissions.push(
                  {
                    sModuleId: "1",
                    sModuleName: "Cuentas por pagar y por cobrar",
                    aPermissions: [
                      {
                        sPermissionId: "01015b31-6d95-466a-8ba5-7cb8174c0778",
                        sPermissionName: "Ver",
                        bBoolean: true,
                      },
                      {
                        sPermissionId: "bea7f302-7e0c-43fc-a289-490b08fd9f63",
                        sPermissionName: "Administrar",
                        bBoolean: true,
                      },
                    ],
                  },
                  {
                    sModuleId: "2",
                    sModuleName: "Ingresos y egresos",
                    aPermissions: [
                      {
                        sPermissionId: "01015b31-6d95-466a-8ba5-7cb8174c0778",
                        sPermissionName: "Ver",
                        bBoolean: true,
                      },
                      {
                        sPermissionId: "bea7f302-7e0c-43fc-a289-490b08fd9f63",
                        sPermissionName: "Administrar",
                        bBoolean: true,
                      },
                    ],
                  }
                );
              }
            }
            let aPermissions = response.data.results.aUserPermissions;
            commit("setPermissions", {
              aUserPermissions: aPermissions,
            });
            dispatch("setPermissionsBtnGlobal", aPermissions)
              .then((resp) => {})
              .catch((err) => {});
            resolve(aPermissions);
          })
          .catch((error) => {
            reject(error.response.data);
          });
      });
    },
    setPermissionsBtnGlobal({ commit, state }, aPermissions) {
      return new Promise((resolve, reject) => {
        try {
          let bShow = false;
          let bAdmin = false;
          if (aPermissions !== null) {
            aPermissions.forEach((element) => {
              bShow = false;
              bAdmin = false;

              //PROVEDOR
              if (element.sModuleId == "dd28e22e-abed-4b96-8dde-53cfb250f5b8") {
                element.aPermissions.forEach((elementPermission) => {
                  //VER
                  if (
                    elementPermission.sPermissionId ==
                    "01015b31-6d95-466a-8ba5-7cb8174c0778"
                  ) {
                    bShow = true;
                  }
                  //ADMINISTRAR
                  if (
                    elementPermission.sPermissionId ==
                    "bea7f302-7e0c-43fc-a289-490b08fd9f63"
                  ) {
                    bAdmin = true;
                  }
                });
                commit("setPermissionProvider", {
                  bShow: bShow,
                  bAdmin: bAdmin,
                });
              }
              //MATERIALS
              if (element.sModuleId == "31fc53df-ed76-4421-85ba-1dfa8ebdef1f") {
                element.aPermissions.forEach((elementPermission) => {
                  //VER
                  if (
                    elementPermission.sPermissionId ==
                    "01015b31-6d95-466a-8ba5-7cb8174c0778"
                  ) {
                    bShow = true;
                  }
                  //ADMINISTRAR
                  if (
                    elementPermission.sPermissionId ==
                    "bea7f302-7e0c-43fc-a289-490b08fd9f63"
                  ) {
                    bAdmin = true;
                  }
                });
                commit("setPermissionMaterial", {
                  bShow: bShow,
                  bAdmin: bAdmin,
                });
              }
              //ADMINISTRATOR
              if (element.sModuleId == "df3cc403-5b68-47b8-824c-180bf5a489e2") {
                element.aPermissions.forEach((elementPermission) => {
                  //VER
                  if (
                    elementPermission.sPermissionId ==
                    "01015b31-6d95-466a-8ba5-7cb8174c0778"
                  ) {
                    bShow = true;
                  }
                  //ADMINISTRAR
                  if (
                    elementPermission.sPermissionId ==
                    "bea7f302-7e0c-43fc-a289-490b08fd9f63"
                  ) {
                    bAdmin = true;
                  }
                });
                commit("setPermissionAdministrator", {
                  bShow: bShow,
                  bAdmin: bAdmin,
                });
              }
              //CUSTOMERS
              if (element.sModuleId == "0c556c00-ac5b-441d-89ef-5c051196102b") {
                element.aPermissions.forEach((elementPermission) => {
                  //VER
                  if (
                    elementPermission.sPermissionId ==
                    "01015b31-6d95-466a-8ba5-7cb8174c0778"
                  ) {
                    bShow = true;
                  }
                  //ADMINISTRAR
                  if (
                    elementPermission.sPermissionId ==
                    "bea7f302-7e0c-43fc-a289-490b08fd9f63"
                  ) {
                    bAdmin = true;
                  }
                });
                commit("setPermissionCustomer", {
                  bShow: bShow,
                  bAdmin: bAdmin,
                });
              }
              //ACCOUNT
              if (element.sModuleId == "43e44764-625d-42fc-b470-ad60ab5d4121") {
                element.aPermissions.forEach((elementPermission) => {
                  //VER
                  if (
                    elementPermission.sPermissionId ==
                    "01015b31-6d95-466a-8ba5-7cb8174c0778"
                  ) {
                    bShow = true;
                  }
                  //ADMINISTRAR
                  if (
                    elementPermission.sPermissionId ==
                    "bea7f302-7e0c-43fc-a289-490b08fd9f63"
                  ) {
                    bAdmin = true;
                  }
                });
                commit("setPermissionAccount", {
                  bShow: bShow,
                  bAdmin: bAdmin,
                });
              }
              //OC GENERAL
              if (element.sModuleId == "5f387e75-f270-403d-98a8-ef9a475d4fe0") {
                element.aPermissions.forEach((elementPermission) => {
                  //VER
                  if (
                    elementPermission.sPermissionId ==
                    "01015b31-6d95-466a-8ba5-7cb8174c0778"
                  ) {
                    bShow = true;
                  }
                  //ADMINISTRAR
                  if (
                    elementPermission.sPermissionId ==
                    "bea7f302-7e0c-43fc-a289-490b08fd9f63"
                  ) {
                    bAdmin = true;
                  }
                });
                commit("setPermissionOPGeneral", {
                  bShow: bShow,
                  bAdmin: bAdmin,
                });
              }
              //OC PENDIENTE
              if (element.sModuleId == "259d467d-ddcb-4499-a100-39907c751d7f") {
                element.aPermissions.forEach((elementPermission) => {
                  //VER
                  if (
                    elementPermission.sPermissionId ==
                    "01015b31-6d95-466a-8ba5-7cb8174c0778"
                  ) {
                    bShow = true;
                  }
                  //ADMINISTRAR
                  if (
                    elementPermission.sPermissionId ==
                    "bea7f302-7e0c-43fc-a289-490b08fd9f63"
                  ) {
                    bAdmin = true;
                  }
                });
                commit("setPermissionOPPending", {
                  bShow: bShow,
                  bAdmin: bAdmin,
                });
              }
              //OC POR PAGAR
              if (element.sModuleId == "787ebe0e-7fa3-4597-90b1-4507e2473071") {
                element.aPermissions.forEach((elementPermission) => {
                  //VER
                  if (
                    elementPermission.sPermissionId ==
                    "01015b31-6d95-466a-8ba5-7cb8174c0778"
                  ) {
                    bShow = true;
                  }
                  //ADMINISTRAR
                  if (
                    elementPermission.sPermissionId ==
                    "bea7f302-7e0c-43fc-a289-490b08fd9f63"
                  ) {
                    bAdmin = true;
                  }
                });
                commit("setPermissionOPPayable", {
                  bShow: bShow,
                  bAdmin: bAdmin,
                });
              }
              //AUDIT
              if (element.sModuleId == "f0831b6b-3c6d-46c2-9327-97800e73bd20") {
                element.aPermissions.forEach((elementPermission) => {
                  //VER
                  if (
                    elementPermission.sPermissionId ==
                    "01015b31-6d95-466a-8ba5-7cb8174c0778"
                  ) {
                    bShow = true;
                  }
                  //ADMINISTRAR
                  if (
                    elementPermission.sPermissionId ==
                    "bea7f302-7e0c-43fc-a289-490b08fd9f63"
                  ) {
                    bAdmin = true;
                  }
                });
                commit("setPermissionAudit", {
                  bShow: bShow,
                  bAdmin: bAdmin,
                });
              }
              //CUSTOMER
              if (element.sModuleId == "0c556c00-ac5b-441d-89ef-5c051196102b") {
                element.aPermissions.forEach((elementPermission) => {
                  //VER
                  if (
                    elementPermission.sPermissionId ==
                    "01015b31-6d95-466a-8ba5-7cb8174c0778"
                  ) {
                    bShow = true;
                  }
                  //ADMINISTRAR
                  if (
                    elementPermission.sPermissionId ==
                    "bea7f302-7e0c-43fc-a289-490b08fd9f63"
                  ) {
                    bAdmin = true;
                  }
                });
                commit("setPermissionCustomer", {
                  bShow: bShow,
                  bAdmin: bAdmin,
                });
              }
              //Dashboard
              if (element.sModuleId == "") {
                element.aPermissions.forEach((elementPermission) => {
                  //VER
                  if (elementPermission.sPermissionId == "") {
                    bShow = true;
                  }
                  //ADMINISTRAR
                  if (elementPermission.sPermissionId == "") {
                    bAdmin = true;
                  }
                });
                commit("", {
                  bShow: bShow,
                  bAdmin: bAdmin,
                });
              }
            });
          } else {
            commit("setPermissionProvider", {
              bShow: true,
              bAdmin: true,
            });
            commit("setPermissionMaterial", {
              bShow: true,
              bAdmin: true,
            });
            commit("setPermissionAdministrator", {
              bShow: true,
              bAdmin: true,
            });
            commit("setPermissionCustomer", {
              bShow: true,
              bAdmin: true,
            });
            commit("setPermissionAccount", {
              bShow: true,
              bAdmin: true,
            });
            commit("setPermissionOPGeneral", {
              bShow: true,
              bAdmin: true,
            });
            commit("setPermissionOPPending", {
              bShow: true,
              bAdmin: true,
            });
            commit("setPermissionOPPayable", {
              bShow: true,
              bAdmin: true,
            });
            commit("setPermissionAudit", {
              bShow: true,
              bAdmin: true,
            });
            commit("setPermissionCustomer", {
              bShow: true,
              bAdmin: true,
            });
          }
          resolve("success");
        } catch (error) {
          reject(error);
        }
      });
    },
    getItemStatusOrderGlobal({ commit, state }) {
      return new Promise((resolve, reject) => {
        DB.get(`${URI}/api/sp/v1/orders/statuses`, {
          headers: {
            Authorization: "Bearer " + state.sToken,
          },
        })
          .then((response) => {
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getItemTypeAccount({ commit, state }) {
      return new Promise((resolve, reject) => {
        DB.get(`${URI}/api/sp/v1/account-types`, {
          headers: {
            Authorization: "Bearer " + state.sToken,
          },
        })
          .then((response) => {
            commit("setItemTypeAccountGlobal", response.data.results);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
    getItemTypeCurrencyGlobal({ commit, state }) {
      return new Promise((resolve, reject) => {
        DB.get(`${URI}/api/sp/v1/currencies`, {
          headers: {
            Authorization: "Bearer " + state.sToken,
          },
        })
          .then((response) => {
            // commit("setItemTypeAccountGlobal", response.data.results);
            resolve(response);
          })
          .catch((error) => {
            reject(error);
          });
      });
    },
  },
  modules: {},
  plugins: [PersistedState()],
});
