<template>
  <div>
      <actor-detail-o-p-general-component/>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>