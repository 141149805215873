<template>
  <div>
    <administrators-component />
  </div>
</template>

<script>

export default {
  name: "AdministratorsView",
};
</script>