<template>
  <div
    :style="!$vuetify.breakpoint.smAndUp ? 'padding: 0px' : ''"
    class="income_and_expenses-wrapper"
  >
    <div
      :style="!$vuetify.breakpoint.smAndUp ? 'padding-top: 5px' : ''"
      class="income_and_expenses-searchbar"
    >
      <!-- Start section title -->
      <div class="content-header-global">
        <div class="content-text-title">
          <p class="text-title">Cuentas por pagar y por cobrar</p>
        </div>
      </div>
      <!-- End section title -->
      <div class="content-btns">
        <!-- Start date picker -->
        <div
          v-if="$vuetify.breakpoint.smAndUp"
          class="content-filter-date-range mr-5"
        >
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="dates"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                persistent-placeholder
                v-model="dates"
                outlined
                chips
                clearable
                class="global-text-field global-text-field-date"
                label="Seleccionar rango de fechas"
                placeholder="Seleccionar rango de fechas..."
                color="var(--primary-color-border-input)"
                background-color="var(--primary-color-menu)"
                v-bind="attrs"
                v-on="on"
                readonly
              >
                <template slot="append">
                  <v-icon @click="menu = true"> mdi-calendar-blank </v-icon>
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              v-model="dates"
              :max="sMaxDate"
              range
              no-title
              locale="es"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false">
                Cancelar
              </v-btn>
              <v-btn
                :disabled="!bAddDates"
                text
                color="primary"
                @click="$refs.menu.save(), save()"
              >
                <span :class="bAddDates ? 'color-yellow-global' : ''">
                  Guardar
                </span>
              </v-btn>
            </v-date-picker>
          </v-menu>
        </div>
        <!-- End date picker -->
      </div>
    </div>
    <!-- Start Search filter for mobile -->
    <div v-if="!$vuetify.breakpoint.smAndUp" style="width: 100%; padding: 10px">
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="dates"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            persistent-placeholder
            v-model="dates"
            outlined
            chips
            clearable
            class="global-text-field global-text-field-date"
            label="Seleccionar rango de fechas"
            placeholder="Seleccionar rango de fechas..."
            color="var(--primary-color-border-input)"
            background-color="var(--primary-color-menu)"
            v-bind="attrs"
            v-on="on"
            readonly
          >
            <template slot="append">
              <v-icon @click="menu = true"> mdi-calendar-blank </v-icon>
            </template>
          </v-text-field>
        </template>
        <v-date-picker
          v-model="dates"
          :max="sMaxDate"
          range
          no-title
          locale="es"
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false"> Cancelar </v-btn>
          <v-btn
            :disabled="!bAddDates"
            text
            color="primary"
            @click="$refs.menu.save(), save()"
          >
            <span :class="bAddDates ? 'color-yellow-global' : ''">
              Guardar
            </span>
          </v-btn>
        </v-date-picker>
      </v-menu>
    </div>
    <!-- End Search filter for mobile -->
    <!-- Start amount of income and expenses -->
    <general-amounts
      :iTotalPay="iTotalPay"
      :iTotalCollect="iTotalCollect"
      :iTotalPayUSD="iTotalPayUSD"
      :iTotalCollectUSD="iTotalCollectUSD"
    ></general-amounts>
    <!-- End amount of income and expenses -->
    <!-- Start tabs -->
    <transactions-tab></transactions-tab>
    <!-- End tabs -->
    <!-- Start search text -->
    <div class="search-text-field">
      <div class="content-search">
        <v-text-field
          v-model="sSearch"
          label="Búsqueda general"
          placeholder="Búsqueda general"
          class="global-text-field text-field-payable-global"
          color="var(--primary-color-border-input)"
          background-color="var(--primary-color-menu)"
          persistent-placeholder
          outlined
          height="50px"
          clearable
        >
          <template v-slot:append>
            <v-icon color="#7C8088" size="17px"> mdi-magnify </v-icon>
          </template>
        </v-text-field>
      </div>
      <div v-if="bShowProvidersFilter" class="content-providers-search">
        <v-select
          v-show="bShowProviders"
          clearable
          v-model="sElementSelectedId"
          :loading="bLoadProviders"
          :disabled="bLoadProviders"
          :items="providers"
          item-text="sName"
          item-value="sVendorId"
          label="Seleccionar proveedor"
          placeholder="Seleccionar proveedor..."
          persistent-placeholder
          class="global-select"
          color="var(--primary-color-border-input)"
          background-color="var(--primary-color-menu)"
          outlined
        >
          <template slot="label">
            <span> Seleccionar proveedor </span>
          </template>
        </v-select>
      </div>
      <div v-else class="content-customer-search">
        <v-select
          v-show="bShowCustomer"
          v-model="sElementSelectedId"
          clearable
          :loading="bLoadClients"
          :disabled="bLoadClients"
          :items="clients"
          item-text="sPublicName"
          item-value="sCustomerId"
          label="Seleccionar cliente"
          placeholder="Seleccionar cliente..."
          persistent-placeholder
          class="global-select"
          color="var(--primary-color-border-input)"
          background-color="var(--primary-color-menu)"
          outlined
        >
          <template slot="label">
            <span> Seleccionar cliente </span>
          </template>
        </v-select>
      </div>
      <v-spacer/>
      <!-- <div class="content-btn-export-info">
        <div class="content-btn-export-info">
            <v-btn @click="setDialogGenerateReport()" class="btn-add-excel-global global-btn-primary">
              Exportar información
            </v-btn>
          </div>
      </div> -->
    </div>
    <!-- End search text -->
    <!-- Start router view for different routes -->
    <router-view
      @update="updateValues"
      :sSearch="sSearchDebounce"
      :tStartDate="tStartDate"
      :tEndDate="tEndDate"
      :itemChosen="sElementSelectedId"
    ></router-view>
    <!-- End router view for different routes -->

    <dialog-generate-report
      @setDialogGenerateReport="setDialogGenerateReport"
      :bDialogGenerateReport="bDialogGenerateReport"
      :oDialogGenerateReport="oDialogGenerateReport"
      :sExcelType="sExcelType"
    />
  </div>
</template>
<script>
import _ from "lodash";

export default {
  data: () => ({
    iTotalCollect: 0,
    iTotalPay: 0,
    iTotalCollectUSD: 0,
    iTotalPayUSD: 0,
    dates: [],
    sMaxDate: new Date().toISOString().slice(0, 10),
    menu: false,
    bAddDates: false,
    // end variables for calendar
    bLoading: false,
    isMobile: false,
    bAdd: false,
    sSearch: "",
    sSearchDebounce: "",
    oTablePages: null,
    // load select of clients and providers
    bLoadProviders: false,
    bLoadClients: false,
    clients: [],
    providers: [],
    sElementSelectedId: "",

    tStartDate: "",
    tEndDate: "",
    bDialogGenerateReport: false,
    oDialogGenerateReport: {},
    sExcelType:""
  }),
  mounted() {
    this.getClients();
    this.getProviders();
  },
  computed: {
    bShowProviders() {
      return this.$store.state.bShowProvider;
    },
    bShowCustomer() {
      return this.$store.state.bShowCustomer;
    },
    // tStartDate() {
    //     return this.dates == null ? null : (this.dates.length >= 1 ? this.dates[0] : null)
    // },
    // tEndDate() {
    //     return this.dates == null ? null : (this.dates.length == 2 ? this.dates[1] : null)
    // },
    routeName() {
      return this.$route.name;
    },
    bShowProvidersFilter() {
      return this.routeName == "Payables" ? true : false;
    },
  },
  watch: {
    dates() {
      if (this.dates !== null) {
        if (this.dates.length > 1) {
          this.bAddDates = true;
          if (this.dates[0] > this.dates[1]) {
            let fecha_inicial = this.dates[1];
            this.dates = [];
            this.dates.push(fecha_inicial);
          }
        } else {
          this.bAddDates = false;
        }
      } else {
        this.bAddDates = false;
        this.tStartDate = "";
        this.tEndDate = "";
      }
    },
    routeName() {
      this.sElementSelectedId = "";
    },
    sSearch: _.debounce(function (e) {
      this.sSearchDebounce = e;
    }, 500),
  },
  methods: {
    updateValues(e) {
      this.iTotalCollect = e.totalToCollect;
      this.iTotalPay = e.totalToPay;
      this.iTotalCollectUSD = e.totalToCollectUSD;
      this.iTotalPayUSD = e.totalToPayUSD;
    },
    getProviders() {
      if (this.bShowProviders) {
        this.bLoadProviders = true;
        this.providers = [];
        DB.get(`${URI}/api/sp/v1/vendors`, {
          params: {
            bSort: true,
          },
          headers: {
            Authorization: "Bearer " + this.$store.state.sToken,
          },
        })
          .then((response) => {
            this.bLoadProviders = false;
            this.providers = response.data.results.map((e) => {
              return {
                sVendorId: e.sVendorId,
                sName: e.oInfo.sPublicName,
              };
            });
          })
          .catch((error) => {
            this.bLoadProviders = false;
            this.Error(error.response.data);
          });
      }
    },
    getClients() {
      if (this.bShowCustomer) {
        this.bLoadClients = true;
        this.clients = [];
        DB.get(`${URI}/api/sp/v1/customers`, {
          params: {
            bSort: true,
          },
          headers: {
            Authorization: "Bearer " + this.$store.state.sToken,
          },
        })
          .then((response) => {
            this.bLoadClients = false;
            this.clients = response.data.results;
          })
          .catch((error) => {
            this.bLoadClients = false;
            this.Error(error.response.data);
          });
      }
    },
    save() {
      this.$refs.menu.save(this.dates);
      this.tStartDate = this.dates[0];
      this.tEndDate = this.dates[1];
    },
    setDialogGenerateReport() {
      this.bDialogGenerateReport = !this.bDialogGenerateReport;
      if (this.$route.name === "Payables") {
        this.sExcelType = "pay"
      } else {
        this.sExcelType = "collect"
      }
    },
  },
  components: {
    GeneralAmounts: () =>
      import("../../components/payablesandrecievables/general_amounts.vue"),
    TransactionsTab: () =>
      import(
        "../../components/payablesandrecievables/payables_and_recievables-tabs.vue"
      ),
      DialogGenerateReport: () =>
      import(
        "../../components/payablesandrecievables/DialogGenerateReport.vue"
      ),
  },
};
</script>
<style scoped>
.text-field-payable-global {
  min-width: 200px;
}
.content-filter-date-range {
  width: 320px;
  margin-left: 15px;
}

.search-text-field {
  display: flex;
  margin: 12px 0px;
}
.content-search {
  width: 300px;
  margin-right: 10px;
}
.content-providers-search {
  width: 300px;
}
.content-customer-search {
  width: 300px;
}

.content-amounts {
  display: flex;
}

.content-amount-card {
  width: 100%;
  height: 85px;
  margin-right: 10px;
  padding: 10px 20px 10px 20px;
  background-color: var(--primary-color-background-table) !important;
  border: 1px solid var(--primary-color-border-input);
  border-radius: 10px;
  align-items: center;
}

.btn-icon-pound {
  /* background-color: var(--primary-color-background-icon-status-blue) !important; */
  width: 30px !important;
  max-width: 30px !important;
  min-width: 30px !important;
  height: 30px !important;
  max-height: 30px !important;
  min-height: 30px !important;
  border-radius: 100%;
}

.content-icon-number .content-number .number-text {
  font-size: 14px !important;
}

.number-text {
  font-family: "pop-Bold";
  font-size: 16px;
  color: var(--primary-color-text);
}

.info-text {
  font-family: "pop-Regular";
  font-size: 12px;
  color: var(--primary-color-text);
}

.content-btns {
  display: flex;
  width: 50%;
  margin-bottom: 25px;
  justify-content: flex-end;
  place-self: end;
}

.income_and_expenses-searchbar {
  width: 100%;
  display: inline-flex;
  padding: 10px 10px 0px 10px;
}

.income_and_expenses-add-button {
  width: auto;
  height: 50px !important;
  letter-spacing: 0px !important;
  background: var(--primary-color-btn-primary) !important;
  text-transform: none !important;
  font-family: "pop-SemiBold" !important;
  border-radius: 10px !important;
}

.income_and_expenses-wrapper {
  padding: 0px 30px 0px 35px;
}

.content-header-global {
  display: flex;
  align-content: center;
  width: 100%;
}

.text-title {
  color: var(--primary-color-text);
  font-family: "pop-SemiBold";
  font-size: 30px;
  margin-bottom: 0px;
  margin-left: -2px;
}

.text-btn-add-mobile {
  font-size: 22px;
  display: flex;
  align-items: center;
}

.btn-add-global-mobile {
  width: 50px !important;
  max-width: 50px !important;
  min-width: 50px !important;
}

.content-text-title {
  width: 100%;
}

/*#region MODO RESPONSIVO MAQUETA */

/*#region XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }
  .search-text-field {
    display: block;
    margin: 12px 0px;
  }
  .content-search {
    margin-right: 10px;
    width: 100%;
    margin-bottom: 10px;
  }
  .content-providers-search {
    width: 100%;
  }
  .content-customer-search {
    width: 100%;
  }
}

/*#endregion XS */

/*#region SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }
  .search-text-field {
    display: flex;
    margin: 12px 0px;
  }
  .content-search {
    margin-right: 10px;
    width: 100%;
  }
  .content-providers-search {
    width: 100%;
  }
  .content-customer-search {
    width: 100%;
  }
}

/*#endregion SM */

/*#region MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/*#endregion MD */

/*#region LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/*#endregion LG */

/*#region XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}

/*#endregion XL */

/*#endregion MODO RESPONSIVO  MAQUETA */
</style>
