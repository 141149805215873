import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "@/views/Home.vue";
import DashboardView from "@/views/Dashboard.vue";
import ReportView from "@/views/Report.vue";
import OPDetailReportView from "@/views/OPDetailReport.vue";
import ProviderView from "@/views/Provider.vue";
import AddProviderView from "@/views/AddProvider.vue";
import ProviderDetailView from "@/views/ProviderDetail.vue";
import OPDetailProviderView from "@/views/OPDetailProvider.vue";
import UserProfileDetailProviderView from "@/views/UserProfileDetailProvider.vue";
import RawMaterialView from "@/views/RawMaterial.vue";
import RawMaterialDetailView from "@/views/RawMaterialDetail.vue";
import AdministratorsView from "@/views/Administrators.vue";
import AdministratorsDetailView from "@/views/AdministratorsDetail.vue";
import UserProfileView from "@/views/UserProfile.vue";
import AccountsView from "@/views/Account.vue";
import AccountDetailView from "@/views/AccountDetail.vue";
import TransationDetailView from "@/views/TransationDetail.vue";
import UserProfileDetailAccountView from "@/views/UserProfileDetailAccount.vue";
import OPTransationDetailAccountView from "@/views/TransationDetailOPGeneral.vue";
import OPGeneralView from "@/views/OPGeneral.vue";
import OPGeneralDetailView from "@/views/OPGeneralDetail.vue";
import ActorDetailOPGeneralView from "@/views/ActorDetailOPGeneral.vue";
import OPPendingView from "@/views/OPPending.vue";
import OPPendingDetailView from "@/views/OPPendingDetail.vue";
import ProviderDetailOPPendingView from "@/views/ProviderDetailOPPending.vue";
import OPPayableView from "@/views/OPPayable.vue";
import AddOperationView from "@/views/AddOperation.vue";
import OPPayableDetailView from "@/views/OPPayableDetail.vue";
import ProviderDetailOPPayableView from "@/views/ProviderDetailOPPayable.vue";
import AddCustomerView from "@/views/AddCustomer.vue";
import CustomerView from "@/views/Customer.vue";
import CustomerDetailView from "@/views/CustomerDetail.vue";
import OPDetailCustomerView from "@/views/OPDetailCustomer.vue";
import AuditView from "@/views/Audit.vue";
import UserProfileDetailAuditView from "@/views/UserProfileDetailAudit.vue";
// income and expenses
import IncomeAndExpenses from "@/views/IncomeAndExpenses/IncomeAndExpenses.vue";
import Incomes from "@/views/IncomeAndExpenses/Incomes.vue";
import Expenses from "@/views/IncomeAndExpenses/Expenses.vue";
// recievables and payables
import PayablesAndRecievables from "@/views/PayablesAndRecievables/PayablesAndRecievables.vue";
import Payables from "@/views/PayablesAndRecievables/Payables.vue";
import Recievables from "@/views/PayablesAndRecievables/Recievables.vue";
import OperationDetails from "@/views/PayablesAndRecievables/OperationDetail.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/home",
    name: "Home",
    redirect: "/home/dashboard",
    component: HomeView,
    children: [
      // #region Dashboard
      {
        path: "dashboard",
        name: "Dashboard",
        meta: {
          requiresAuth: true,
          adminAuth: true,
          permission: "9d99fb23-2d47-4ada-aeca-a6e3f3d236e5",
        },
        component: DashboardView,
      },
      // #endregion Dashboard

      // #region report
      {
        path: "report",
        name: "Report",
        meta: {
          requiresAuth: true,
          adminAuth: true,
          permission: "123-report",
        },
        component: ReportView,
      },
      {
        path: "report/:idOP",
        name: "OPDetailReport",
        meta: {
          requiresAuth: true,
          adminAuth: true,
          permission: "123-report",
        },
        component: OPDetailReportView,
      },
      // #endregion report

      // #region provider
      {
        path: "provider",
        name: "Provider",
        meta: {
          requiresAuth: true,
          adminAuth: true,
          permission: "dd28e22e-abed-4b96-8dde-53cfb250f5b8",
        },
        component: ProviderView,
      },
      {
        path: "add-provider",
        name: "AddProvider",
        meta: {
          requiresAuth: true,
          adminAuth: true,
          permission: "dd28e22e-abed-4b96-8dde-53cfb250f5b8",
        },
        component: AddProviderView,
      },
      {
        path: "provider-detail/:id",
        name: "ProviderDetail",
        meta: {
          requiresAuth: true,
          adminAuth: true,
          permission: "dd28e22e-abed-4b96-8dde-53cfb250f5b8",
        },
        component: ProviderDetailView,
      },
      {
        path: "provider-detail/:id/op-detail-provider/:idOP",
        name: "OPDetailProvider",
        meta: {
          requiresAuth: true,
          adminAuth: true,
          permission: "dd28e22e-abed-4b96-8dde-53cfb250f5b8",
        },
        component: OPDetailProviderView,
      },
      {
        path: "provider-detail/:id/oc-detail-provider/:idOC/user-profile-detail-provider/:idUser",
        name: "UserProfileDetailProvider",
        meta: {
          requiresAuth: true,
          adminAuth: true,
          permission: "dd28e22e-abed-4b96-8dde-53cfb250f5b8",
        },
        component: UserProfileDetailProviderView,
      },
      // #endregion provider

      //  #region raw materials
      {
        path: "raw-material",
        name: "RawMaterial",
        meta: {
          requiresAuth: true,
          adminAuth: true,
          permission: "31fc53df-ed76-4421-85ba-1dfa8ebdef1f",
        },
        component: RawMaterialView,
      },
      {
        path: "raw-material-detail/:id",
        name: "RawMaterialDetail",
        meta: {
          requiresAuth: true,
          adminAuth: true,
          permission: "31fc53df-ed76-4421-85ba-1dfa8ebdef1f",
        },
        component: RawMaterialDetailView,
      },
      //  #endregion raw materials

      // #region administrator
      {
        path: "administrators",
        name: "Administrators",
        meta: {
          requiresAuth: true,
          adminAuth: true,
          permission: "df3cc403-5b68-47b8-824c-180bf5a489e2",
        },
        component: AdministratorsView,
      },
      {
        path: "administrators-detail/:idUser",
        name: "AdministratorsDetail",
        meta: {
          requiresAuth: true,
          adminAuth: true,
          permission: "df3cc403-5b68-47b8-824c-180bf5a489e2",
        },
        component: AdministratorsDetailView,
      },
      // #endregion administrator

      //  #region user profile
      {
        path: "user-profile",
        name: "UserProfile",
        meta: {
          requiresAuth: false,
          adminAuth: false,
          permission: "4",
        },
        component: UserProfileView,
      },
      //  #endregion user profile

      // #region account
      {
        path: "account",
        name: "Account",
        meta: {
          requiresAuth: true,
          adminAuth: true,
          permission: "43e44764-625d-42fc-b470-ad60ab5d4121",
        },
        component: AccountsView,
      },
      {
        path: "account-detail/:id",
        name: "AccountDetail",
        meta: {
          requiresAuth: true,
          adminAuth: true,
          permission: "43e44764-625d-42fc-b470-ad60ab5d4121",
        },
        component: AccountDetailView,
      },
      {
        path: "account-detail/:id/transation/:idTransation",
        name: "TransationDetail",
        meta: {
          requiresAuth: true,
          adminAuth: true,
          permission: "43e44764-625d-42fc-b470-ad60ab5d4121",
        },
        component: TransationDetailView,
      },
      {
        path: "account-detail/:id/transation/:idTransation/user-profile-detail-account/:idUser",
        name: "UserProfileDetailAccount",
        meta: {
          requiresAuth: true,
          adminAuth: true,
          permission: "43e44764-625d-42fc-b470-ad60ab5d4121",
        },
        component: UserProfileDetailAccountView,
      },
      {
        path: "account-detail/:id/transation/:idTransation/op-detail-transition-detial-account/:idOP",
        name: "OPTransationDetailAccount",
        meta: {
          requiresAuth: true,
          adminAuth: true,
          permission: "43e44764-625d-42fc-b470-ad60ab5d4121",
        },
        component: OPTransationDetailAccountView,
      },
      // #endregion account

      // #region OP general
      {
        path: "op-general",
        name: "OPGeneral",
        meta: {
          requiresAuth: true,
          adminAuth: true,
          permission: "5f387e75-f270-403d-98a8-ef9a475d4fe0",
        },
        component: OPGeneralView,
      },
      {
        path: "op-general-detail/:idOP",
        name: "OPGeneralDetail",
        meta: {
          requiresAuth: true,
          adminAuth: true,
          permission: "5f387e75-f270-403d-98a8-ef9a475d4fe0",
        },
        component: OPGeneralDetailView,
      },
      {
        path: "op-general-detail/:idOP/actor-detail-op-general/:id",
        name: "ActorDetailOPGeneral",
        meta: {
          requiresAuth: true,
          adminAuth: true,
          permission: "5f387e75-f270-403d-98a8-ef9a475d4fe0",
        },
        component: ActorDetailOPGeneralView,
      },
      // #endregion OP general

      // #region OP Pending
      {
        path: "op-pending",
        name: "OPPending",
        meta: {
          requiresAuth: true,
          adminAuth: true,
          permission: "259d467d-ddcb-4499-a100-39907c751d7f",
        },
        component: OPPendingView,
      },
      {
        path: "add-operation",
        name: "AddOperation",
        meta: {
          requiresAuth: true,
          adminAuth: true,
          permission: "259d467d-ddcb-4499-a100-39907c751d7f",
        },
        component: AddOperationView,
      },
      {
        path: "op-pending-detail/:idOP",
        name: "OPPendingDetail",
        meta: {
          requiresAuth: true,
          adminAuth: true,
          permission: "259d467d-ddcb-4499-a100-39907c751d7f",
        },
        component: OPPendingDetailView,
      },
      {
        path: "op-pending-detail/:idOP/provider-detail-op-pending/:id",
        name: "ProviderDetailOPPending",
        meta: {
          requiresAuth: true,
          adminAuth: true,
          permission: "259d467d-ddcb-4499-a100-39907c751d7f",
        },
        component: ProviderDetailOPPendingView,
      },
      // #endregion OP Pending

      // #region OP payable
      {
        path: "op-payable",
        name: "OPPayable",
        meta: {
          requiresAuth: true,
          adminAuth: true,
          permission: "787ebe0e-7fa3-4597-90b1-4507e2473071",
        },
        component: OPPayableView,
      },
      {
        path: "op-payable-detail/:idOP",
        name: "OPPayableDetail",
        meta: {
          requiresAuth: true,
          adminAuth: true,
          permission: "787ebe0e-7fa3-4597-90b1-4507e2473071",
        },
        component: OPPayableDetailView,
      },
      {
        path: "op-payable-detail/:idOP/provider-detail-op-payable/:id",
        name: "ProviderDetailOPPayable",
        meta: {
          requiresAuth: true,
          adminAuth: true,
          permission: "787ebe0e-7fa3-4597-90b1-4507e2473071",
        },
        component: ProviderDetailOPPayableView,
      },
      // #endregion OP payable

      //  #region audit
      {
        path: "audit",
        name: "Audit",
        meta: {
          requiresAuth: true,
          adminAuth: true,
          permission: "f0831b6b-3c6d-46c2-9327-97800e73bd20",
        },
        component: AuditView,
      },
      {
        path: "user-profile-detail-audit/:idUser",
        name: "UserProfileDetailAudit",
        meta: {
          requiresAuth: true,
          adminAuth: true,
          permission: "f0831b6b-3c6d-46c2-9327-97800e73bd20",
        },
        component: UserProfileDetailAuditView,
      },
      //  #endregion audit
      
      // #region customer
      {
        path: "add-customer",
        name: "AddCustomer",
        meta: {
          requiresAuth: true,
          adminAuth: true,
          permission: "0c556c00-ac5b-441d-89ef-5c051196102b",
        },
        component: AddCustomerView,
      },
      {
        path: "customer",
        name: "Customer",
        meta: {
          requiresAuth: true,
          adminAuth: true,
          permission: "0c556c00-ac5b-441d-89ef-5c051196102b",
        },
        component: CustomerView,
      },
      {
        path: "customer-detail/:id",
        name: "CustomerDetail",
        meta: {
          requiresAuth: true,
          adminAuth: true,
          permission: "0c556c00-ac5b-441d-89ef-5c051196102b",
        },
        component: CustomerDetailView,
      },
      {
        path: "customer-detail/:id/op-detail-customer/:idOP",
        name: "OPDetailCustomer",
        meta: {
          requiresAuth: true,
          adminAuth: true,
          permission: "0c556c00-ac5b-441d-89ef-5c051196102b",
        },
        component: OPDetailCustomerView,
      },
      // #endregion customer

      // #region payables and recievables
      {
        path: "payables_and_recievables",
        name: "PayablesAndRecievables",
        redirect: "payables_and_recievables/payables",
        meta: {
          requiresAuth: true,
          adminAuth: true,
          permission: "0c556c00-ac5b-441d-89ef-5c051196102b",
        },
        // redirect: '/payables_and_recievables/incomes',
        component: PayablesAndRecievables,
        children: [
          {
            path: "payables",
            name: "Payables",
            meta: {
              requiresAuth: true,
              adminAuth: true,
              permission: "1",
            },
            component: Payables,
          },
          {
            path: "recievables",
            name: "Recievables",
            meta: {
              requiresAuth: true,
              adminAuth: true,
              permission: "1",
            },
            component: Recievables,
          },
        ], //
      },
      {
        path: "order-details/:idOP/:from",
        name: "OperationDetails",
        meta: {
          requiresAuth: true,
          adminAuth: true,
          permission: "1",
        },
        component: OperationDetails,
      },
      // #endregion payables and recievables
  
      // #region income and expense
      {
        path: "income_and_expenses",
        name: "IncomeAndExpenses",
        redirect: "income_and_expenses/incomes",
        meta: {
          requiresAuth: true,
          adminAuth: true,
          permission: "2",
        },
        // redirect: '/income_and_expenses/incomes',
        component: IncomeAndExpenses,
        children: [
          {
            path: "incomes",
            name: "Incomes",
            meta: {
              requiresAuth: true,
              adminAuth: true,
              permission: "2",
            },
            component: Incomes,
          },
          {
            path: "expenses",
            name: "Expenses",
            meta: {
              requiresAuth: true,
              adminAuth: true,
              permission: "2",
            },
            component: Expenses,
          },
        ],
      },
      {
        path: "transaction-detail/:id/:idTransation/:from",
        name: "TransactionDetail",
        meta: {
          requiresAuth: true,
          adminAuth: true,
          permission: "2",
        },
        component: () =>
          import("../views/IncomeAndExpenses/TransactionDetail.vue"),
      },
      {
        path: "transaction-detail/:id/:idTransation/:from/OP/:idOP",
        name: "OPDetailTransactionDetail",
        meta: {
          requiresAuth: true,
          adminAuth: true,
          permission: "2",
        },
        component: () =>
          import("../views/IncomeAndExpenses/OPDetailTransationDetail.vue"),
      },

      // #endregion income and expense
    ],
  },
];

export default routes;
