<template>
  <div class="content-sidebar">
    <div class="content-menu-full pa-3">
      <div class="container-options-menu overflow-x-auto">
        <v-list style="overflow: hidden" color="transparent pa-0">
          <div v-for="(item, i) in filteredItems" :key="i">
            <v-tooltip right content-class="content-tooltip-global">
              <template v-slot:activator="{ on, attrs }">
                <!-- <v-btn color="primary" dark v-bind="attrs" v-on="on">
                  Right
                </v-btn> -->
                <div
                  v-bind="!bSidebar ? attrs : ''"
                  v-on="!bSidebar ? on : ''"
                  class="item-menu"
                  @click="goView(item.name, item)"
                >
                  <v-list-item
                    :class="
                      setBackgroundColor(item.routeName, item)
                        ? 'list-selected'
                        : ''
                    "
                    exact
                  >
                    <v-list-item-icon>
                      <!-- <v-icon class="icon-list-menu">{{ item.iconMDI }}</v-icon> -->
                      <span
                        style="width: 0px"
                        class="material-icons icon-sidebar"
                      >
                        <!-- currency_exchange -->
                        {{ item.iconMDI }}
                      </span>
                    </v-list-item-icon>
                    <v-list-item-content>
                      <v-list-item-title class="text-list-menu">{{
                        item.title
                      }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </template>
              <span class="title-tooltip-menu">
                {{ item.title }}
              </span>
            </v-tooltip>
            <!-- item -->
          </div>
        </v-list>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      filteredItems: [],
    };
  },
  beforeMount() {
    this.changePermissions();
  },
  methods: {
    setBackgroundColor: function (routeNames, oItem) {
      let bln = false;
      for (let i = 0; i < routeNames.length; i++) {
        if (this.$route.name == routeNames[i]) {
          this.$store.commit("setModuleIdGlobal", oItem.sModuleId);
          bln = true;
        }
      }
      return bln;
    },
    goView(sName, item) {
      const loading = this.$vs.loading({
        type: "circles",
        color: "#F1D72E",
        text: "Cargando información...",
      });
      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          loading.close();
          this.$store.commit("openSidebar", false);
          if (sName === this.$route.name) {
            this.$store.commit("refresher", true);
          } else {
            this.$router.push({ name: sName }).catch((err) => {});
          }
        })
        .catch((err) => {
          loading.close();
          this.Error(err);
        });
    },
    changePermissions() {
      this.$store
        .dispatch("setPermissionsBtnGlobal", this.userPermissions)
        .then((resp) => {
          this.filteredItems = this.aMenuGlobal.filter((routeOption) => {
            if (this.userPermissions === null) {
              return routeOption;
            } else {
              var exp = this.userPermissions;
              let arr = "";
              exp.forEach((e) => {
                if (e.sModuleId === routeOption.sModuleId) {
                  arr = e;
                }
              });
              return arr;
            }
          });
        })
        .catch((err) => {
          this.Error(err);
        });
    },
  },
  computed: {
    bDark() {
      return this.$store.state.bDark;
    },
    bSidebar() {
      return this.$store.state.bSidebar;
    },
    aMenuGlobal() {
      return this.$store.state.aMenuGlobal;
    },
    userPermissions() {
      return this.$store.state.aPermissions;
    },
  },
  watch: {
    bSidebar() {
      var imglogo = document.getElementById("imglogo");
      imglogo.classList.toggle("img-logo-recamsa-close");
    },
    userPermissions() {
      this.changePermissions();
    },
    aMenuGlobal() {},
  },
};
</script>
<style scoped>
.content-sidebar {
  width: 100% !important;
  max-width: 100% !important;
  min-width: 100% !important;
  height: 99% !important;
  max-height: 99%;
  min-height: 99%;
  border-radius: 15px;
  box-shadow: 0px 0px 10px 5px var(--primary-color-border-shadow);
  border: solid 0.2 var(--primary-color-border-shadow);
}

.content-menu-full {
  background-color: var(--primary-color-menu);
  height: 100%;
  max-height: 100%;
  min-height: 100%;
  width: 100%;
  overflow: auto;
  border-radius: 15px;
}

.item-menu {
  cursor: pointer;
  margin-bottom: 10px;
}

.item-menu:hover {
  background-color: var(--primary-color-background-list-menu-hover);
  border-radius: 15px;
}

.list-selected {
  background-color: var(--primary-color-background-list-selected);
  border-radius: 13px;
}

.img-logo-recamsa {
  width: 250px;
  padding: 6px;
  margin-bottom: 18px;
}

.img-logo-recamsa-close {
  width: 350px;
  padding: 6px;
  margin-bottom: 0px;
}

.list-item-option {
  background-color: var(--primary-color-text);
}

.icon-list-menu {
  color: var(--primary-color-text) !important;
}

.text-list-menu {
  color: var(--primary-color-text) !important;
  font-size: 14px;
  font-family: "pop-Regular";
}

/* RESPONSIVE CSS */
/* XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }
}

/* SM */
@media (min-width: 600px) and (max-width: 959px) {
}

/* MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/* LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/* XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}
</style>
