<template>
  <div>
      <o-p-pending-detail-component/>
      <add-raw-material-to-purchase-order-component-global/>

  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style>
</style>