<template>
    <div>
        <raw-material-detail-component/>
    </div>
</template>

<script>

export default {
  name: 'RawMaterialDetail',
}
</script>