<template>
 
 <o-p-payable-component/>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>

<style>
</style>