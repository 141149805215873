<template>
  <div class="content-report">
    <div class="content-header-report">
      <div ref="searchbarGlobal">
        <header-component-global
          sTextTitle="Reportes"
          sTextBtnAdd=""
          :bAdminPermissions="false"
          setDialog=""
        />
      </div>
      <div class="content-date-picker-filter-range">
        <v-menu
          ref="menu"
          v-model="bMenu"
          attach
          :close-on-content-click="false"
          :return-value.sync="aDates"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="sDates"
              outlined
              chips
              clearable
              class="global-text-field global-text-field-date"
              label="Seleccionar rango de fechas"
              placeholder="Seleccionar rango de fechas..."
              color="var(--primary-color-border-input)"
              background-color="var(--primary-color-menu)"
              v-bind="attrs"
              v-on="on"
              readonly
            >
              <template slot="append">
                <v-icon @click="bMenu = true">mdi-calendar-blank</v-icon>
              </template>
            </v-text-field>
          </template>
          <v-date-picker
            v-model="aDates"
            :max="sMaxDate"
            range
            no-title
            locale="es"
          >
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="bMenu = false">
              Cancelar
            </v-btn>
            <v-btn
              :disabled="!bAddDates"
              text
              color="primary"
              @click="$refs.menu.save(aDates), save(aDates)"
            >
              <span :class="bAddDates ? 'color-yellow-global' : ''"
                >Guardar</span
              >
            </v-btn>
          </v-date-picker>
        </v-menu>
      </div>
    </div>

    <div class="content-info-filter-result">
      <p class="text-info-filter-result">
        {{ sReportMessageFilter }}
      </p>
    </div>
    <dashboard-report-component
      :dExpensesBalance="dExpensesBalance"
      :dIncomeBalance="dIncomeBalance"
      :dExpensesBalanceUSD="dExpensesBalanceUSD"
      :dIncomeBalanceUSD="dIncomeBalanceUSD"
    />

    <div class="content-tables-report content-global">
      <v-divider class="divider-global mt-9 mb-9"></v-divider>

      <!-- #region cuentas financieras -->
      <report-financial-accounts-content
        :bMobile="bMobile"
        @setSearchFinancialAccounts="setSearchFinancialAccounts"
        :aFinancialAccounts="aFinancialAccounts"
        @getEmitFinancialAccounts="getEmitFinancialAccounts"
        :numPages="numPagesFinancialAccounts"
        :totalItems="totalItemsFinancialAccounts"
      />
      <!-- #endregion cuentas financieras -->
      <v-divider class="divider-global mt-9 mb-9"></v-divider>

      <!-- #region cuentas por cobrar -->
      <report-accounts-receivable-content
        @setSearchAccountsReceivable="setSearchAccountsReceivable"
        :bMobile="bMobile"
        :bTablet="bTablet"
        :aAccountsReceivable="aAccountsReceivable"
        :aAccountsReceivableUSD="aAccountsReceivableUSD"
        :dTotalReceivable="dTotalReceivable"
        :dTotalReceivableUSD="dTotalReceivableUSD"
        @getEmitAccountsReceivable="getEmitAccountsReceivable"
        @getEmitAccountsReceivableUSD="getEmitAccountsReceivableUSD"
        :numPages="numPagesAccountsReceivable"
        :numPagesUSD="numPagesAccountsReceivableUSD"
        :totalItems="totalItemsAccountsReceivable"
        :totalItemsUSD="totalItemsAccountsReceivableUSD"
      />
      <!-- #endregion cuentas por cobrar -->

      <v-divider class="divider-global mt-9 mb-9"></v-divider>

      <!-- #region cuentas por pagar -->
      <report-accounts-payable-content
        @setSearchAccountsPayable="setSearchAccountsPayable"
        :bMobile="bMobile"
        :bTablet="bTablet"
        :aAccountsPayable="aAccountsPayable"
        :aAccountsPayableUSD="aAccountsPayableUSD"
        :dTotalPayable="dTotalPayable"
        :dTotalPayableUSD="dTotalPayableUSD"
        @getEmitAccountsPayable="getEmitAccountsPayable"
        @getEmitAccountsPayableUSD="getEmitAccountsPayableUSD"
        :numPages="numPagesAccountsPayable"
        :numPagesUSD="numPagesAccountsPayableUSD"
        :totalItems="totalItemsAccountsPayable"
        :totalItemsUSD="totalItemsAccountsPayableUSD"
      />
      <!-- #endregion cuentas por pagar -->

      <report-btns-action-report
        @setDialogGenerateReport="setDialogGenerateReport"
      />

      <!-- #region dialog generate report  -->
      <dialog-generate-report
        @setDialogGenerateReport="setDialogGenerateReport"
        :bDialogGenerateReport="bDialogGenerateReport"
        :oDialogGenerateReport="oDialogGenerateReport"
      />
      <!-- #endregion dialog generate report  -->
    </div>
  </div>
</template>

<script>
export default {
  name: "ReportComponet",
  data() {
    return {
      bMobile: false,
      bTablet: false,

      bMenu: false,
      bAddDates: false,
      aDates: [
        new Date().toISOString().slice(0, 10),
        new Date().toISOString().slice(0, 10),
      ],
      sDates:
        new Date().toISOString().slice(0, 10) +
        " - " +
        new Date().toISOString().slice(0, 10),
      tStart: new Date().toISOString().slice(0, 10),
      tEnd: new Date().toISOString().slice(0, 10),
      sMaxDate: new Date().toISOString().slice(0, 10),

      sReportMessageFilter: "En espera de información...",

      dExpensesBalance: 0,
      dIncomeBalance: 0,

      dExpensesBalanceUSD: 0,
      dIncomeBalanceUSD: 0,

      sSearchFinancialAccounts: "",
      aFinancialAccounts: [],
      iCurrentPageFinancialAccounts: 1,
      iItemsPerPageFinancialAccounts: 30,
      numPagesFinancialAccounts: 0,
      totalItemsFinancialAccounts: 0,

      // #region getEmitAccountsReceivable
      sSearchAccountsReceivable: "",
      aAccountsReceivable: [],
      aAccountsReceivableUSD: [],

      dTotalReceivable: 0,
      dTotalReceivableUSD: 0,

      iCurrentPageAccountsReceivable: 1,
      iCurrentPageAccountsReceivableUSD: 1,

      iItemsPerPageAccountsReceivable: 30,
      iItemsPerPageAccountsReceivableUSD: 30,

      numPagesAccountsReceivable: 0,
      numPagesAccountsReceivableUSD: 0,

      totalItemsAccountsReceivable: 0,
      totalItemsAccountsReceivableUSD: 0,
      // #endregion getEmitAccountsReceivable

      // #region getEmitAccountsPayable
      sSearchAccountsPayable: "",
      aAccountsPayable: [],
      aAccountsPayableUSD: [],

      dTotalPayable: 0,
      dTotalPayableUSD: 0,

      iCurrentPageAccountsPayable: 1,
      iCurrentPageAccountsPayableUSD: 1,

      iItemsPerPageAccountsPayable: 30,
      iItemsPerPageAccountsPayableUSD: 30,

      numPagesAccountsPayable: 0,
      numPagesAccountsPayableUSD: 0,

      totalItemsAccountsPayable: 0,
      totalItemsAccountsPayableUSD: 0,
      // #endregion getEmitAccountsPayable

      bDialogGenerateReport: false,
      oDialogGenerateReport: {},
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  beforeMount() {
    this.getFinancialAccounts();
    this.getAccountsReceivable();
    this.getAccountsReceivableUSD();
    this.getAccountsPayable();
    this.getAccountsPayableUSD();
  },
  methods: {
    handleResize() {
      if (window.innerWidth < 599.99) {
        this.bMobile = true;
        this.bTablet = false;
      } else if (window.innerWidth > 599.99 && window.innerWidth < 959) {
        this.bMobile = false;
        this.bTablet = true;
      } else {
        this.bMobile = false;
        this.bTablet = false;
      }
    },
    save(aDates) {
      this.$refs.menu.save(aDates);
      if (this.aDates.length > 1) {
        let fecha_inicial = this.aDates[0];
        let fecha_final = this.aDates[1];
        if (Date.parse(fecha_inicial) > Date.parse(fecha_final)) {
          let lastDate = this.aDates[1];
          this.aDates = [];
          this.aDates[0] = lastDate;
        } else {
          this.tStart = fecha_inicial;
          this.tEnd = fecha_final;
          this.sDates = this.tStart + " - " + this.tEnd;
          this.getFinancialAccounts();
          this.getAccountsReceivable();
          this.getAccountsReceivableUSD();
          this.getAccountsPayable();
          this.getAccountsPayableUSD();
        }
      }
    },
    setDialogGenerateReport() {
      this.bDialogGenerateReport = !this.bDialogGenerateReport;
    },

    setSearchFinancialAccounts(sSearchFinancialAccounts) {
      this.sSearchFinancialAccounts = sSearchFinancialAccounts;
    },
    getEmitFinancialAccounts(obj) {
      this.iCurrentPageFinancialAccounts = obj.iCurrentPage;
      this.iItemsPerPageFinancialAccounts = obj.iItemsPerPage;
      this.getFinancialAccounts();
    },
    getFinancialAccounts() {
      DB.get(`${URI}/api/sp/v1/reports/accounts`, {
        params: {
          sSearch: this.sSearchFinancialAccounts,
          tStart: this.tStart,
          tEnd: this.tEnd,
          iPageNumber: this.iCurrentPageFinancialAccounts,
          iItemsPerPage: this.iItemsPerPageFinancialAccounts,
        },
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
      })
        .then((response) => {

          this.dIncomeBalance =
            response.data.results.oIncomeBalance.dMexicanPesoBalance;

          this.dIncomeBalanceUSD =
            response.data.results.oIncomeBalance.dUSDollarBalance;

          this.dExpensesBalance =
            response.data.results.oExpensesBalance.dMexicanPesoBalance;

          this.dExpensesBalanceUSD =
            response.data.results.oExpensesBalance.dUSDollarBalance;


          this.numPagesFinancialAccounts = response.data.numPages;
          this.totalItemsFinancialAccounts = response.data.total;
          this.aFinancialAccounts = response.data.results.aReport.map(
            (e, i) => {
              return {
                ...e,
                sNumber: i + 1,
                sInstitution: e.oFinancialInstitution.sName,
                sType: e.oAccountType.sName,
                dBalance: e.dAccountBalanceFormat,
                dVariation: e.dAccountVariationFormat,
              };
            }
          );
        })
        .catch((error) => {
          this.bLoadingTable = false;
          this.Error(error.response.data);
        });
    },

    setSearchAccountsReceivable(sSearchAccountsReceivable) {
      this.sSearchAccountsReceivable = sSearchAccountsReceivable;
    },

    // #region getEmitAccountsReceivable MXN
    getEmitAccountsReceivable(obj) {
      this.iCurrentPageAccountsReceivable = obj.iCurrentPage;
      this.iItemsPerPageAccountsReceivable = obj.iItemsPerPage;
      this.getAccountsReceivable();
    },
    getAccountsReceivable() {
      DB.get(`${URI}/api/sp/v1/reports/orders-to-collect`, {
        params: {
          sSearch: this.sSearchAccountsReceivable,
          tStart: this.tStart,
          tEnd: this.tEnd,
          iPageNumber: this.iCurrentPageAccountsReceivable,
          iItemsPerPage: this.iItemsPerPageAccountsReceivable,
          sCurrencyCode: "mxn",
        },
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
      })
        .then((response) => {
          this.aAccountsReceivable = response.data.results.aList.map((e, i) => {
            return {
              sNumber: e.iRowNumber,
              sName: e.sCustomerName,
              sDateRange: e.tRange,
              dVariation: "$" + this.formatMoneyGlobal(e.dVariation) + " MXN",
              dTotalDebit: "$" + this.formatMoneyGlobal(e.dBalance) + " MXN",
            };
          });
          this.dTotalReceivable = response.data.results.oTotalToCollect.dTotal;

          this.sReportMessageFilter = response.data.results.sDashboardMessage;

          this.numPagesAccountsReceivable = response.data.numPages;
          this.totalItemsAccountsReceivable = response.data.total;
        })
        .catch((error) => {
          this.bLoadingTable = false;
          this.Error(error.response.data);
        });
    },
    // #endregion getEmitAccountsReceivable MXN

    // #region getEmitAccountsReceivable USD
    getEmitAccountsReceivableUSD(obj) {
      this.iCurrentPageAccountsReceivableUSD = obj.iCurrentPage;
      this.iItemsPerPageAccountsReceivableUSD = obj.iItemsPerPage;
      this.getAccountsReceivableUSD();
    },
    getAccountsReceivableUSD() {
      DB.get(`${URI}/api/sp/v1/reports/orders-to-collect`, {
        params: {
          sSearch: this.sSearchAccountsReceivable,
          tStart: this.tStart,
          tEnd: this.tEnd,
          iPageNumber: this.iCurrentPageAccountsReceivableUSD,
          iItemsPerPage: this.iItemsPerPageAccountsReceivableUSD,
          sCurrencyCode: "usd",
        },
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
      })
        .then((response) => {
          this.aAccountsReceivableUSD = response.data.results.aList.map(
            (e, i) => {
              return {
                sNumber: e.iRowNumber,
                sName: e.sCustomerName,
                sDateRange: e.tRange,
                dVariation: "$" + this.formatMoneyGlobal(e.dVariation) + " USD",
                dTotalDebit: "$" + this.formatMoneyGlobal(e.dBalance) + " USD",
              };
            }
          );
          if (!this.sSearchAccountsReceivable) {
            this.aAccountsReceivableUSD.push({
              sNumber: "",
              sName: "",
              sDateRange: "",
              dVariation: "",
              dTotalDebit: "",
            });
          }

          this.dTotalReceivableUSD =
            response.data.results.oTotalToCollect.dTotal;

          this.sReportMessageFilter = response.data.results.sDashboardMessage;

          this.numPagesAccountsReceivableUSD = response.data.numPages;
          this.totalItemsAccountsReceivableUSD = response.data.total;
        })
        .catch((error) => {
          this.bLoadingTable = false;
          this.Error(error.response.data);
        });
    },
    // #endregion getEmitAccountsReceivable USD

    setSearchAccountsPayable(sSearchAccountsPayable) {
      this.sSearchAccountsPayable = sSearchAccountsPayable;
    },
    // #region getEmitAccountsPayable MXN
    getEmitAccountsPayable(obj) {
      this.iCurrentPageAccountsPayable = obj.iCurrentPage;
      this.iItemsPerPageAccountsPayable = obj.iItemsPerPage;
      this.getAccountsPayable();
    },
    getAccountsPayable() {
      DB.get(`${URI}/api/sp/v1/reports/orders-to-pay`, {
        params: {
          sSearch: this.sSearchAccountsPayable,
          tStart: this.tStart,
          tEnd: this.tEnd,
          iPageNumber: this.iCurrentPageAccountsPayable,
          iItemsPerPage: this.iItemsPerPageAccountsPayable,
          sCurrencyCode: "mxn",
        },
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
      })
        .then((response) => {
          this.aAccountsPayable = response.data.results.aList.map((e, i) => {
            return {
              sNumber: i + 1,
              sName: e.sVendorName,
              sDateRange: e.tRange,
              dVariation: "$" + this.formatMoneyGlobal(e.dVariation) + " MXN",
              dTotalDebit: "$" + this.formatMoneyGlobal(e.dBalance) + " MXN",
            };
          });
          this.dTotalPayable = response.data.results.oTotalToPay.dTotal;

          this.sReportMessageFilter = response.data.results.sDashboardMessage;
          this.numPagesAccountsPayable = response.data.numPages;
          this.totalItemsAccountsPayable = response.data.total;
        })
        .catch((error) => {
          this.bLoadingTable = false;
          this.Error(error.response.data);
        });
    },
    // #endregion getEmitAccountsPayable MXN

    // #region getEmitAccountsPayable USD
    getEmitAccountsPayableUSD(obj) {
      this.iCurrentPageAccountsPayableUSD = obj.iCurrentPage;
      this.iItemsPerPageAccountsPayableUSD = obj.iItemsPerPage;
      this.getAccountsPayableUSD();
    },
    getAccountsPayableUSD() {
      DB.get(`${URI}/api/sp/v1/reports/orders-to-pay`, {
        params: {
          sSearch: this.sSearchAccountsPayable,
          tStart: this.tStart,
          tEnd: this.tEnd,
          iPageNumber: this.iCurrentPageAccountsPayableUSD,
          iItemsPerPage: this.iItemsPerPageAccountsPayableUSD,
          sCurrencyCode: "usd",
        },
        headers: {
          Authorization: "Bearer " + this.$store.state.sToken,
        },
      })
        .then((response) => {
          this.aAccountsPayableUSD = response.data.results.aList.map((e, i) => {
            return {
              sNumber: i + 1,
              sName: e.sVendorName,
              sDateRange: e.tRange,
              dVariation: "$" + this.formatMoneyGlobal(e.dVariation) + " USD",
              dTotalDebit: "$" + this.formatMoneyGlobal(e.dBalance) + " USD",
            };
          });
          this.dTotalPayableUSD = response.data.results.oTotalToPay.dTotal;

          this.sReportMessageFilter = response.data.results.sDashboardMessage;
          this.numPagesAccountsPayableUSD = response.data.numPages;
          this.totalItemsAccountsPayableUSD = response.data.total;
        })
        .catch((error) => {
          this.bLoadingTable = false;
          this.Error(error.response.data);
        });
    },
    // #endregion getEmitAccountsPayable USD
  },
  watch: {
    aDates() {
      if (this.aDates !== null) {
        if (this.aDates.length > 1) {
          this.bAddDates = true;
          if (this.aDates[0] > this.aDates[1]) {
            let fecha_inicial = this.aDates[1];
            this.aDates = [];
            this.aDates.push(fecha_inicial);
          }
        } else {
          this.bAddDates = false;
        }
      } else {
        this.bAddDates = false;
        this.tStart = null;
        this.tEnd = null;
        this.getFinancialAccounts();
        this.getAccountsReceivable();
        this.getAccountsReceivableUSD();
        this.getAccountsPayable();
        this.getAccountsPayableUSD();
      }
    },
    sDates() {
      if (this.sDates == null) {
        this.aDates = [];
        this.$refs.menu.save([]);
        this.bAddDates = false;
        this.tStart = null;
        this.tEnd = null;
        this.getFinancialAccounts();
        this.getAccountsReceivable();
        this.getAccountsReceivableUSD();
        this.getAccountsPayable();
        this.getAccountsPayableUSD();
      }
    },
    sSearchFinancialAccounts() {
      this.getFinancialAccounts();
    },
    sSearchAccountsReceivable() {
      this.getAccountsReceivable();
      this.getAccountsReceivableUSD();
    },
    sSearchAccountsPayable() {
      this.getAccountsPayable();
      this.getAccountsPayableUSD();
    },
  },
};
</script>

<style scoped>
.content-report {
  padding: 0px 30px 0px 35px;
}

.content-header-report {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.content-date-picker-filter-range {
  width: 300px;
}
.text-info-filter-result {
  font-family: "pop-Regular";
  font-size: 19px;
  color: var(--primary-color-text);
}

/*Plantilla media query css responsive*/

/*#region MODO RESPONSIVO MAQUETA */

/*#region XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }

  .content-report {
    padding: 0px 10px 0px 10px;
  }

  .content-header-report {
    display: block;
    justify-content: space-between;
    align-items: center;
  }
  .content-date-picker-filter-range {
    width: 100%;
  }
}

/*#endregion XS */

/*#region SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }
}

/*#endregion SM */

/*#region MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/*#endregion MD */

/*#region LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/*#endregion LG */

/*#region XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}

/*#endregion XL */

/*#endregion MODO RESPONSIVO  MAQUETA */
</style>
