<template>
  <div><user-profile-detail-audit-component/></div>
</template>

<script>
export default {

}
</script>

<style>

</style>