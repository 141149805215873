export const notification = {
  data() {
    return {
      oOptionCurrencyMXNGlobal: {
        locale: "en-US",
        prefix: "$",
        suffix: "MXN",
        length: 11,
        precision: 2,
      },
      oOptionCurrencyExchangeRateGlobal: {
        locale: "en-US",
        prefix: "$",
        suffix: "MXN por USD",
        length: 11,
        precision: 2,
      },
      oOptionCurrencyUSDGlobal: {
        locale: "en-US",
        prefix: "$",
        suffix: "USD",
        length: 11,
        precision: 2,
      },
      oOptionCurrencyKGGlobal: {
        locale: "en-US",
        prefix: "",
        suffix: "Kg",
        length: 11,
        precision: 2,
      },
    };
  },
  methods: {
    Success(
      textTitle = "",
      textDescription = "",
      position = "top-right",
      color = "success",
      duration = 7500
    ) {
      const noti = this.$vs.notification({
        duration,
        color,
        position,
        title: textTitle,
        text: textDescription,
      });
    },
    Error(
      objResult = {},
      textDescription = "",
      position = "top-right",
      color = "danger",
      duration = 7500
    ) {
      if (objResult.code === 401) {
        const loading = this.$vs.loading({
          type: "circles",
          color: "#F1D72E",
        });
        setTimeout(() => {
          localStorage.clear();
          window.location.reload();
          loading.close();
        }, 3000);
      } else if (objResult.code === 403) {
        this.$router.go(-1);
      }
      const noti = this.$vs.notification({
        duration,
        color,
        position,
        title: objResult.message,
        text: textDescription,
      });
    },
    Warning(
      textTitle = "",
      textDescription = "",
      position = "top-right",
      color = "warn",
      duration = 7500
    ) {
      const noti = this.$vs.notification({
        duration,
        color,
        position,
        title: textTitle,
        text: textDescription,
      });
    },
    Info(
      textTitle = "",
      textDescription = "",
      position = "top-right",
      color = "primary",
      duration = 7500
    ) {
      const noti = this.$vs.notification({
        duration,
        color,
        position,
        title: textTitle,
        text: textDescription,
      });
    },
  },
};
