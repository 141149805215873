<template>
    <div>
        <administrator-detail-component/>
        <delete-component-global/>
    </div>
</template>

<script>

export default {
  name: 'AdministratorsDetail',

}
</script>