<template>
  <div>
      <add-provider-component/>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
<style scoped>

</style>