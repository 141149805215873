<template>
  <div
    :style="!$vuetify.breakpoint.smAndUp ? 'padding: 0px' : ''"
    class="income_and_expenses-wrapper"
  >
    <div
      :style="!$vuetify.breakpoint.smAndUp ? 'padding-top: 5px' : ''"
      class="income_and_expenses-searchbar"
    >
      <!-- Start section title -->
      <div class="content-header-global">
        <div class="content-text-title">
          <p class="text-title">Ingresos y egresos</p>
        </div>
      </div>
      <!-- End section title -->
      <!-- Start add option -->
      <div class="content-btns">
        <!-- Start date picker -->
        <div
          v-if="$vuetify.breakpoint.smAndUp"
          class="content-filter-date-range mr-5"
        >
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="false"
            :return-value.sync="dates"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                persistent-placeholder
                v-model="dates"
                outlined
                chips
                clearable
                class="global-text-field global-text-field-date"
                label="Seleccionar rango de fechas"
                placeholder="Seleccionar rango de fechas..."
                color="var(--primary-color-border-input)"
                background-color="var(--primary-color-menu)"
                v-bind="attrs"
                v-on="on"
                readonly
              >
                <template slot="append">
                  <v-icon @click="menu = true"> mdi-calendar-blank </v-icon>
                </template>
              </v-text-field>
            </template>
            <v-date-picker
              v-model="dates"
              :max="sMaxDate"
              range
              no-title
              locale="es"
            >
              <v-spacer></v-spacer>
              <v-btn text color="primary" @click="menu = false">
                Cancelar
              </v-btn>
              <v-btn
                :disabled="!bAddDates"
                text
                color="primary"
                @click="$refs.menu.save(), save()"
              >
                <span :class="bAddDates ? 'color-yellow-global' : ''">
                  Guardar
                </span>
              </v-btn>
            </v-date-picker>
          </v-menu>
        </div>
        <!-- End date picker -->
        <v-btn
          v-if="bAdminAccount"
          @click="bAdd = true"
          class="btn-add-global income_and_expenses-add-button"
          :class="$vuetify.breakpoint.smAndDown ? 'btn-add-global-mobile' : ''"
        >
          <span
            class="text-btn-add-mobile"
            v-if="$vuetify.breakpoint.smAndDown"
          >
            +
          </span>
          <span v-else> + Añadir nuevo </span>
        </v-btn>
      </div>
      <!-- End add option -->
    </div>
    <!-- Start Search filter for mobile -->
    <div v-if="!$vuetify.breakpoint.smAndUp" style="width: 100%; padding: 10px">
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="dates"
        transition="scale-transition"
        offset-y
        min-width="auto"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            persistent-placeholder
            v-model="dates"
            outlined
            chips
            clearable
            class="global-text-field global-text-field-date"
            label="Seleccionar rango de fechas"
            placeholder="Seleccionar rango de fechas..."
            color="var(--primary-color-border-input)"
            background-color="var(--primary-color-menu)"
            v-bind="attrs"
            v-on="on"
            readonly
          >
            <template slot="append">
              <v-icon @click="menu = true"> mdi-calendar-blank </v-icon>
            </template>
          </v-text-field>
        </template>
        <v-date-picker
          v-model="dates"
          :max="sMaxDate"
          range
          no-title
          locale="es"
        >
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false"> Cancelar </v-btn>
          <v-btn
            :disabled="!bAddDates"
            text
            color="primary"
            @click="$refs.menu.save(), save()"
          >
            <span :class="bAddDates ? 'color-yellow-global' : ''">
              Guardar
            </span>
          </v-btn>
        </v-date-picker>
      </v-menu>
    </div>
    <!-- End Search filter for mobile -->
    <!-- Start amount of income and expenses -->
    <general-amounts
      :incomes="dIncomes"
      :expenses="dExpenses"
      :incomesUSD="dIncomesUSD"
      :expensesUSD="dExpensesUSD"
    ></general-amounts>
    <!-- End amount of income and expenses -->
    <!-- Start tabs -->
    <transactions-tab></transactions-tab>
    <!-- End tabs -->
    <!-- Start search text -->
    <div class="content-search-all">
      <div class="content-search">
        <v-text-field
          v-model="sSearch"
          label="Búsqueda general"
          placeholder="Búsqueda general"
          class="global-text-field"
          color="var(--primary-color-border-input)"
          background-color="var(--primary-color-menu)"
          persistent-placeholder
          outlined
          height="50px"
          clearable
        >
          <template v-slot:append>
            <v-icon color="#7C8088" size="17px"> mdi-magnify </v-icon>
          </template>
        </v-text-field>
      </div>

      <div class="content-btn-export-info" v-if="bAdminAccount">
        <div class="content-btn-export-info">
          <v-btn
            @click="setDialogGenerateReport()"
            class="btn-add-excel global-btn-primary"
          >
            Exportar {{sType}}
          </v-btn>
        </div>
      </div>

      <!-- <div class="content-type-currency">
        <v-select
          v-model="sTransactionType"
          :items="itemsTransactionType"
          item-text="sName"
          item-value="sTransactionTypeId"
          label="Tipo de transacción"
          placeholder="Seleccione el tipo de transacción"
          persisten-placeholder
          class="global-select field-code-country"
          color="var(--primary-color-border-input)"
          background-color="var(--primary-color-menu)"
          outlined
        >
        </v-select>
      </div> -->
    </div>

    <!-- End search text -->
    <!-- Start router view for different routes -->
    <router-view
      @update="updateValues"
      :sSearch="sSearchDebounce"
      :tStartDate="tStartDate"
      :tEndDate="tEndDate"
    >
    </router-view>
    <!-- End router view for different routes -->
    <!-- Start add dialog -->
    <add-transaction
      @close="bAdd = false"
      :bDialogAddTransation="bAdd"
      sCurrencyCode=""
    ></add-transaction>
    <!-- End add dialog -->

    <dialog-generate-report
      @setDialogGenerateReport="setDialogGenerateReport"
      :bDialogGenerateReport="bDialogGenerateReport"
      :oDialogGenerateReport="oDialogGenerateReport"
      :sExcelType="sExcelType"
    />
  </div>
</template>
<script>
import _ from "lodash";

export default {
  data() {
    return {
      dIncomes: 0.0,
      dExpenses: 0.0,
      dIncomesUSD: 0.0,
      dExpensesUSD: 0.0,
      dates: [],
      sMaxDate: new Date().toISOString().slice(0, 10),
      menu: false,
      bAddDates: false,
      // end variables for calendar
      bLoading: false,
      isMobile: false,
      bAdd: false,
      sSearch: "",
      sSearchDebounce: "",
      oTablePages: null,
      sTransactionType: null,
      itemsTransactionType: [],

      tStartDate: "",
      tEndDate: "",
      bDialogGenerateReport: false,
      oDialogGenerateReport: {},
      sExcelType: "",
    };
  },
  components: {
    AddTransaction: () => import("../../components/incomesandexpenses/add.vue"),
    GeneralAmounts: () =>
      import("../../components/incomesandexpenses/general_amounts.vue"),
    TransactionsTab: () =>
      import(
        "../../components/incomesandexpenses/income_and_expenses-tabs.vue"
      ),
    DialogGenerateReport: () =>
      import("../../components/incomesandexpenses/DialogGenerateReport.vue"),
  },
  methods: {
    updateValues(e) {
      this.dIncomes = e.dIncomes.dMexicanPesoBalance;
      this.dExpenses = e.dExpenses.dMexicanPesoBalance;

      this.dIncomesUSD = e.dIncomes.dUSDollarBalance;
      this.dExpensesUSD = e.dExpenses.dUSDollarBalance;
    },
    save() {
      this.$refs.menu.save(this.dates);
      this.tStartDate = this.dates[0];
      this.tEndDate = this.dates[1];
    },
    setDialogGenerateReport() {
      this.bDialogGenerateReport = !this.bDialogGenerateReport;
      if (this.$route.name === "Incomes") {
        this.sExcelType = "incomes";
      } else {
        this.sExcelType = "expenses";
      }
    },
  },
  computed: {
    bShowAccount() {
      return this.$store.state.bShowAccount;
    },
    bAdminAccount() {
      return this.$store.state.bAdminAccount;
    },
    sType() {
      if (this.$route.name === "Incomes") {
        return "ingresos";
      } else if (this.$route.name === "Expenses") {
        return "egresos";
      }
    },
    // tStartDate() {
    //     return this.dates == null ? null : (this.dates.length >= 1 ? this.dates[0] : null)
    // },
    // tEndDate() {
    //     return this.dates == null ? null : (this.dates.length == 2 ? this.dates[1] : null)
    // }
  },
  watch: {
    dates() {
      if (this.dates !== null) {
        if (this.dates.length > 1) {
          this.bAddDates = true;
          if (this.dates[0] > this.dates[1]) {
            let fecha_inicial = this.dates[1];
            this.dates = [];
            this.dates.push(fecha_inicial);
          }
        } else {
          this.bAddDates = false;
        }
      } else {
        this.bAddDates = false;
        this.tStartDate = "";
        this.tEndDate = "";
      }
    },
    sSearch: _.debounce(function (e) {
      this.sSearchDebounce = e;
    }, 500),
  },
};
</script>
<style scoped>
.content-filter-date-range {
  width: 320px;
  margin-left: 15px;
}

.content-search-all {
  display: flex;
  justify-content: space-between;
  margin: 12px 0px;
  width: 100%;
}
.content-search {
  width: 300px;
  margin-right: 10px;
}
.content-type-currency {
  width: 300px;
}
.content-amounts {
  display: flex;
}

.content-amount-card {
  width: 100%;
  height: 85px;
  margin-right: 10px;
  padding: 10px 20px 10px 20px;
  background-color: var(--primary-color-background-table) !important;
  border: 1px solid var(--primary-color-border-input);
  border-radius: 10px;
  align-items: center;
}

.btn-icon-pound {
  /* background-color: var(--primary-color-background-icon-status-blue) !important; */
  width: 30px !important;
  max-width: 30px !important;
  min-width: 30px !important;
  height: 30px !important;
  max-height: 30px !important;
  min-height: 30px !important;
  border-radius: 100%;
}

.content-icon-number .content-number .number-text {
  font-size: 14px !important;
}

.number-text {
  font-family: "pop-Bold";
  font-size: 16px;
  color: var(--primary-color-text);
}

.info-text {
  font-family: "pop-Regular";
  font-size: 12px;
  color: var(--primary-color-text);
}

.content-btns {
  display: flex;
  width: 320px;
  width: 50%;
  margin-bottom: 25px;
  justify-content: flex-end;
  place-self: end;
}

.income_and_expenses-searchbar {
  width: 100%;
  display: inline-flex;
  padding: 10px 10px 0px 10px;
}

.income_and_expenses-add-button {
  width: auto;
  height: 50px !important;
  letter-spacing: 0px !important;
  background: var(--primary-color-btn-primary) !important;
  text-transform: none !important;
  font-family: "pop-SemiBold" !important;
  border-radius: 10px !important;
}

.income_and_expenses-wrapper {
  padding: 0px 30px 0px 35px;
}

.content-header-global {
  display: flex;
  align-content: center;
  width: 100%;
}

.text-title {
  color: var(--primary-color-text);
  font-family: "pop-SemiBold";
  font-size: 30px;
  margin-bottom: 0px;
  margin-left: -2px;
}

.text-btn-add-mobile {
  font-size: 22px;
  display: flex;
  align-items: center;
}

.btn-add-global-mobile {
  width: 50px !important;
  max-width: 50px !important;
  min-width: 50px !important;
}

.content-text-title {
  width: 100%;
}

/*#region MODO RESPONSIVO MAQUETA */

/*#region XS */
@media (max-width: 599px) {
  .demoColorResponsive {
    background-color: rgb(190, 136, 226);
  }
  .content-search-all {
    display: block;
    margin: 12px 0px;
    width: 100%;
  }
  .content-search {
    width: 100%;
    margin-right: 0px;
  }
  .content-type-currency {
    width: 100%;
  }
}

/*#endregion XS */

/*#region SM */
@media (min-width: 600px) and (max-width: 959px) {
  .demoColorResponsive {
    background-color: rgb(136, 226, 151);
  }
}

/*#endregion SM */

/*#region MD */
@media (min-width: 960px) and (max-width: 1264px) {
  .demoColorResponsive {
    background-color: rgb(201, 122, 83);
  }
}

/*#endregion MD */

/*#region LG */
@media (min-width: 1264px) and (max-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(204, 198, 109);
  }
}

/*#endregion LG */

/*#region XL */
@media (min-width: 1904px) {
  .demoColorResponsive {
    background-color: rgb(113, 199, 201);
  }
}

/*#endregion XL */

/*#endregion MODO RESPONSIVO  MAQUETA */
</style>
