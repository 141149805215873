import Vue from "vue";

//#region G L O B A L   C O M P O N E N T S

Vue.component("DeleteComponentGlobal", () =>
  import("../global_components/DeleteComponentGlobal")
);
Vue.component("DialogActionConfirmGlobal", () =>
  import("../global_components/DialogActionConfirmGlobal")
);
Vue.component("DialogUpdatePriceRawMaterialGlobal", () =>
  import("../global_components/DialogUpdatePriceRawMaterialGlobal")
);
Vue.component("CheckboxComponentGlobal", () =>
  import("../global_components/CheckboxGlobal")
);
Vue.component("FilterComponentGlobal", () =>
  import("../global_components/filter_component_global/FiltersComponentGlobal")
);
Vue.component("FilterMenuGlobal", () =>
  import("../global_components/filter_component_global/FilterMenuGlobal")
);
Vue.component("FooterComponentGlobal", () =>
  import("../global_components/FooterComponentGlobal")
);
Vue.component("FooterTableComponentGlobal", () =>
  import("../global_components/FooterTableComponentGlobal")
);
Vue.component("LineChartsComponentGlobal", () =>
  import("../global_components/LineChartsComponentGlobal")
);
Vue.component("PhoneComponentGlobal", () =>
  import("../global_components/phone_component_global/PhoneComponentGlobal")
);
Vue.component("PhoneTextComponentGlobal", () =>
  import("../global_components/phone_component_global/PhoneTextComponentGlobal")
);
Vue.component("SkeletonPhoneComponentGlobal", () =>
  import(
    "../global_components/phone_component_global/SkeletonPhoneComponentGlobal"
  )
);
Vue.component("InputCurrencyGlobal", () =>
  import("../global_components/InputCurrencyGlobal")
);
Vue.component("VTextFieldCurrency", () =>
  import("../global_components/v-text-field-currency")
);
Vue.component("NoDataTextComponentGlobal", () =>
  import("../global_components/NoDataTextComponentGlobal")
);
Vue.component("HeaderComponentGlobal", () =>
  import("../global_components/header_component_global/HeaderComponentGlobal")
);
Vue.component("HeaderDetailComponentGlobal", () =>
  import(
    "../global_components/header_component_global/HeaderDetailComponentGlobal"
  )
);

Vue.component("TableMobileComponentGlobal", () =>
  import("../global_components/TableMobileComponentGlobal")
);
Vue.component("MenuAccionTableComponentGlobal", () =>
  import("../global_components/MenuAccionTableComponentGlobal")
);

Vue.component("ProviderGeneralInformationGlobal", () =>
  import(
    "../global_components/general_information_provider_or_person_global/general_information_provider_global/ProviderGeneralInformationGlobal"
  )
);
Vue.component("SkeletonProviderGeneralInformationGlobal", () =>
  import(
    "../global_components/general_information_provider_or_person_global/general_information_provider_global/SkeletonProviderGeneralInformationGlobal"
  )
);

Vue.component("PersonGeneralInformationGlobal", () =>
  import(
    "../global_components/general_information_provider_or_person_global/general_information_person_global/PersonGeneralInformationGlobal"
  )
);
Vue.component("SkeletonPersonGeneralInformationGlobal", () =>
  import(
    "../global_components/general_information_provider_or_person_global/general_information_person_global/SkeletonPersonGeneralInformationGlobal"
  )
);

Vue.component("UserProfileComponentGlobal", () =>
  import(
    "../global_components/user_profile_detail_component_global/UserProfileComponentGlobal"
  )
);
Vue.component("SkeletonUserProfileComponentGlobal", () =>
  import(
    "../global_components/user_profile_detail_component_global/SkeletonUserProfileComponentGlobal"
  )
);
Vue.component("TableMobilePermissionsComponentGlobal", () =>
  import(
    "../global_components/user_profile_detail_component_global/TableMobilePermissionsComponentGlobal"
  )
);

Vue.component("CustomerGeneralInformationGlobal", () =>
  import(
    "../global_components/general_information_customer_global/CustomerGeneralInformationGlobal"
  )
);
Vue.component("SkeletonCustomerGeneralInformationGlobal", () =>
  import(
    "../global_components/general_information_customer_global/SkeletonCustomerGeneralInformationGlobal"
  )
);

Vue.component("DialogImgsOP", () =>
  import(
    "../global_components/op-general-component-global/dialog-images-op/DialogImgsOP"
  )
);
Vue.component("CardImgsOP", () =>
  import(
    "../global_components/op-general-component-global/dialog-images-op/CardImgsOP"
  )
);

Vue.component("VuePhotoZoomPro", () => import("vue-photo-zoom-pro"));

Vue.component("ChartComponentGlobal", () =>
  import("../global_components/chart_component_global/ChartComponentGlobal")
);

//#region OPERACIONES GLOBAL
Vue.component("TableRawMaterialsGlobal", () =>
  import(
    "../global_components/op-general-component-global/op-general-component-table-raw-materials-global/TableRawMaterialsGlobal"
  )
);
Vue.component("TableMobileRawMaterialsGlobal", () =>
  import(
    "../global_components/op-general-component-global/op-general-component-table-raw-materials-global/TableMobileRawMaterialsGlobal"
  )
);
//#endregion OPERACIONES GLOBAL

//#endregion

//#region D A S H B O A R D //
Vue.component("DashboardComponent", () =>
  import("../components/dashboard/DashboardComponent")
);

Vue.component("AmountsComponent", () =>
  import("../components/dashboard/components_amounts/AmountsComponent")
);
Vue.component("GraphicsComponent", () =>
  import("../components/dashboard/components_graphics/GraphicsComponent")
);
Vue.component("RankingsComponent", () =>
  import("../components/dashboard/components_rankings/RankingsComponent")
);

//#endregion D A S H B O A R D //

// #region R E P O R T E //
Vue.component("DashboardReportComponent", () =>
  import("../components/report/DashboardReportComponent")
);

// #region CUENTAS FINANCIERAS
Vue.component("ReportFinancialAccountsContent", () =>
  import("../components/report/financial_accounts/content")
);

Vue.component("ReportFinancialAccountsTable", () =>
  import("../components/report/financial_accounts/table")
);

Vue.component("ReportFinancialAccountsCard", () =>
  import("../components/report/financial_accounts/card")
);
// #endregion CUENTAS FINANCIERAS

// #region CUENTAS POR COBRAR
Vue.component("ReportAccountsReceivableContent", () =>
  import("../components/report/accounts_receivable/content")
);

Vue.component("ReportAccountsReceivableTable", () =>
  import("../components/report/accounts_receivable/table")
);

Vue.component("ReportAccountsReceivableCard", () =>
  import("../components/report/accounts_receivable/card")
);
// #endregion CUENTAS POR COBRAR

// #region CUENTAS POR PAGAR
Vue.component("ReportAccountsPayableContent", () =>
  import("../components/report/accounts_payable/content")
);

Vue.component("ReportAccountsPayableTable", () =>
  import("../components/report/accounts_payable/table")
);

Vue.component("ReportAccountsPayableCard", () =>
  import("../components/report/accounts_payable/card")
);
// #endregion CUENTAS POR PAGAR

// #region BOTONES DE ACCIONES REPORTES
Vue.component("ReportBtnsActionReport", () =>
  import("../components/report/BtnsActionReport")
);
// #endregion BOTONES DE ACCIONES REPORTES

// #region MODAL GENERACIÓN DE DOCUMENTO DE REPORTE
Vue.component("DialogGenerateReport", () =>
  import("../components/report/DialogGenerateReport")
);
// #endregion MODAL GENERACIÓN DE DOCUMENTO DE REPORTE

// #endregion R E P O R T E //

//#region P R O V I D E R S
Vue.component("ProviderComponent", () =>
  import("../components/provider/ProviderComponent")
);
Vue.component("AddProviderComponent", () =>
  import("../components/provider/AddProviderComponent")
);
Vue.component("TableMobileProviderComponent", () =>
  import("../components/provider/TableMobileProviderComponent")
);
Vue.component("TableMobilePersonComponent", () =>
  import("../components/provider/TableMobilePersonComponent")
);
//#endregion

//#region P R O V I D E R S   D E T A I L S

Vue.component("ProviderDetailComponent", () =>
  import("../components/provider_detail/ProviderDetailComponent")
);
Vue.component("SkeletonProviderDetailComponent", () =>
  import("../components/provider_detail/SkeletonProviderDetailComponent")
);

Vue.component("FiltersOPComponent", () =>
  import("../components/provider_detail/components_o_p/FiltersOPComponent")
);
Vue.component("SkeletonFilterOPComponent", () =>
  import(
    "../components/provider_detail/components_o_p/SkeletonFilterOPComponent"
  )
);
Vue.component("OPComponent", () =>
  import("../components/provider_detail/components_o_p/OPComponent")
);
Vue.component("OPDetailProviderComponent", () =>
  import(
    "../components/provider_detail/components_o_p/components_o_p_detail/OPDetailProvider"
  )
);
Vue.component("TableMobileOPComponent", () =>
  import("../components/provider_detail/components_o_p/TableMobileOPComponent")
);
Vue.component("SkeletonOPComponent", () =>
  import("../components/provider_detail/components_o_p/SkeletonOPComponent")
);

Vue.component("FilterRawMaterialsDetailProviderComponent", () =>
  import(
    "../components/provider_detail/components_raw_materials/FilterRawMaterialComponent"
  )
);
Vue.component("SkeletonFilterRawMaterialsDetailProviderComponent", () =>
  import(
    "../components/provider_detail/components_raw_materials/SkeletonFilterRawMaterialComponent"
  )
);
Vue.component("RawMaterialsDetailProviderComponent", () =>
  import(
    "../components/provider_detail/components_raw_materials/RawMaterialComponent"
  )
);
Vue.component("TableMobileRawMaterialsDetailProviderComponent", () =>
  import(
    "../components/provider_detail/components_raw_materials/TableMobileRawMaterialsComponent"
  )
);
Vue.component("SkeletonRawMaterialsDetailProviderComponent", () =>
  import(
    "../components/provider_detail/components_raw_materials/SkeletonRawMaterialComponent"
  )
);
Vue.component("DialogUpdatePriceRawMaterialComponent", () =>
  import(
    "../components/provider_detail/components_raw_materials/DialogUpdatePriceRawMaterialComponent"
  )
);

// Vue.component("PersonGeneralInformationComponent", () =>
//   import(
//     "../components/provider_detail/components_general_information_person/PersonGeneralInformationComponent"
//   )
// );
// Vue.component("SkeletonPersonGeneralInformationComponent", () =>
//   import(
//     "../components/provider_detail/components_general_information_person/SkeletonPersonGeneralInformationComponent"
//   )
// );

//#endregion

//#region R A W  M A T E R I A L
Vue.component("RawMaterialComponent", () =>
  import("../components/raw_material/RawMaterialComponent")
);
Vue.component("TableMobileRawMaterialComponent", () =>
  import("../components/raw_material/TableMobileRawMaterialComponent")
);
Vue.component("DialogAddRawMaterialComponent", () =>
  import("../components/raw_material/DialogAddRawMaterialComponent")
);
//#endregion

//#region R A W  M A T E R I A L  D E T A I L S

Vue.component("RawMaterialDetailComponent", () =>
  import("../components/raw_material_detail/RawMaterialDetailComponent")
);

Vue.component("GeneralInformationRawMaterialComponent", () =>
  import(
    "../components/raw_material_detail/components_general_information/GeneralInformationComponent"
  )
);
Vue.component("SkeletonGeneralInformationComponent", () =>
  import(
    "../components/raw_material_detail/components_general_information/SkeletonGeneralInformationComponent"
  )
);
Vue.component("DialogEditInformationQuantityComponent", () =>
  import(
    "../components/raw_material_detail/components_general_information/DialogEditInformationComponent"
  )
);

Vue.component("BasePriceComponent", () =>
  import(
    "../components/raw_material_detail/components_base_price/BasePriceComponent"
  )
);
Vue.component("SkeletonBasePriceComponent", () =>
  import(
    "../components/raw_material_detail/components_base_price/SkeletonBasePriceComponent"
  )
);
Vue.component("DialogNewBasePriceComponent", () =>
  import(
    "../components/raw_material_detail/components_base_price/DialogNewBasePriceComponent"
  )
);

Vue.component("InventoryComponent", () =>
  import(
    "../components/raw_material_detail/components_inventory/InventoryComponent"
  )
);
Vue.component("SkeletonInventoryComponent", () =>
  import(
    "../components/raw_material_detail/components_inventory/SkeletonInventoryComponent"
  )
);
Vue.component("DialogNewQuantityComponent", () =>
  import(
    "../components/raw_material_detail/components_inventory/DialogNewQuantityComponent"
  )
);

Vue.component("InputsOutputsComponent", () =>
  import(
    "../components/raw_material_detail/components_inputs_outputs/InputsOutputsComponent"
  )
);
Vue.component("FilterInputOutputsComponent", () =>
  import(
    "../components/raw_material_detail/components_inputs_outputs/FilterComponent"
  )
);
Vue.component("SkeletonInputsOutputsComponent", () =>
  import(
    "../components/raw_material_detail/components_inputs_outputs/SkeletonInputsOutputsComponent"
  )
);

Vue.component("TransactionHistoryComponent", () =>
  import(
    "../components/raw_material_detail/components_transaction_history/TransactionHistoryComponent"
  )
);
Vue.component("TableMobileTransactionHistoryComponent", () =>
  import(
    "../components/raw_material_detail/components_transaction_history/TableMobileTransactionHistoryComponent"
  )
);
Vue.component("FilterTransactionHistoryComponent", () =>
  import(
    "../components/raw_material_detail/components_transaction_history/FilterComponent"
  )
);
Vue.component("SkeletonTransactionHistoryComponent", () =>
  import(
    "../components/raw_material_detail/components_transaction_history/SkeletonTransactionHistoryComponent"
  )
);

//#endregion

//#region A D M I N I S T R A T O R S
Vue.component("AdministratorsComponent", () =>
  import("../components/administrators/AdministratorsComponent")
);
Vue.component("TableMobileAdministratorsComponent", () =>
  import("../components/administrators/TableMobileAdministratorsComponent")
);
Vue.component("DialogAddAdministratorComponent", () =>
  import("../components/administrators/DialogAddAdministratorComponent")
);
//#endregion

//#region A D M I N I S T R A T O R S    D E T A I L
Vue.component("AdministratorDetailComponent", () =>
  import("../components/administrator_detail/AdministratorsDetailComponent")
);
Vue.component("SkeletonAdministratorDetailComponent", () =>
  import(
    "../components/administrator_detail/SkeletonAdministratorDetailComponent"
  )
);
//#endregion

//#region U S E R   P R O F I L E
Vue.component("UserProfileComponent", () =>
  import("../components/user_profile/UserProfileComponent")
);
Vue.component("SearchbarUserProfileComponent", () =>
  import("../components/user_profile/SearchbarUserProfileComponent")
);
Vue.component("DialogChangePasswordComponent", () =>
  import("../components/user_profile/DialogChangePasswordComponent")
);
Vue.component("SkeletonUserProfileComponent", () =>
  import("../components/user_profile/SkeletonUserProfileComponent")
);
//#endregion

//#region A C C O U N T S
Vue.component("AccountsComponent", () =>
  import("../components/accounts/AccountsComponent")
);
Vue.component("TableMobileAccountsComponent", () =>
  import("../components/accounts/TableMobileAccountsComponent")
);
Vue.component("DialogAddAccountsComponent", () =>
  import("../components/accounts/DialogAddAccountsComponent")
);
Vue.component("DialogAccountGenerateReport", () =>
  import("../components/accounts/DialogGenerateReport")
);
//#endregion

//#region A C C O U N T S   D E T A I L
Vue.component("AccountDetailComponent", () =>
  import("../components/account_detail/AccountDetailComponent")
);

Vue.component("GeneralInformationAccountComponent", () =>
  import(
    "../components/account_detail/components_general_information/GeneralInformationComponent"
  )
);
Vue.component("SkeletonGeneralInformationAccountComponent", () =>
  import(
    "../components/account_detail/components_general_information/SkeletonGeneralInformationComponent"
  )
);

Vue.component("TransationComponent", () =>
  import(
    "../components/account_detail/components_transation/TransationComponent"
  )
);
Vue.component("TableMobileTransationComponent", () =>
  import(
    "../components/account_detail/components_transation/TableMobileTransationComponent"
  )
);
Vue.component("SkeletonTransationComponent", () =>
  import(
    "../components/account_detail/components_transation/SkeletonTransationComponent"
  )
);

Vue.component("SearchbarTransationComponent", () =>
  import(
    "../components/account_detail/components_transation/SearchbarTransationComponent"
  )
);
Vue.component("SkeletonSearchbarTransationComponent", () =>
  import(
    "../components/account_detail/components_transation/SkeletonSearchbarTransationComponent"
  )
);

Vue.component("DialogAddTransationComponent", () =>
  import(
    "../components/account_detail/components_transation/DialogAddTransationComponent.vue"
  )
);

Vue.component("SearchbarTransationDetailComponent", () =>
  import(
    "../components/account_detail/components_transation/component_transation_detail/SearchbarTransationDetailComponent"
  )
);
Vue.component("TransationDetailComponent", () =>
  import(
    "../components/account_detail/components_transation/component_transation_detail/TransationDetailComponent"
  )
);
Vue.component("SkeletonSearchbarTransationDetailComponent", () =>
  import(
    "../components/account_detail/components_transation/component_transation_detail/SkeletonSearchbarTransationDetailComponent"
  )
);
Vue.component("SkeletonTransationDetailComponent", () =>
  import(
    "../components/account_detail/components_transation/component_transation_detail/SkeletonTransationDetailComponent"
  )
);

Vue.component("UserProfileDetailTransationComponent", () =>
  import(
    "../components/account_detail/components_user_profile/UserProfileComponent"
  )
);

Vue.component("OPDetailTransationComponent", () =>
  import(
    "../components/account_detail/components_transation/component_transation_detail/components_o_p_detail/OPDetailTransation.vue"
  )
);

//#endregion

//#region O C   G L O B A L
Vue.component("OCComponentGlobal", () =>
  import("../global_components/oc_component_global/OCComponentGlobal")
);
Vue.component("TableMobileOCComponentGlobal", () =>
  import(
    "../global_components/oc_component_global/TableMobileOCComponentGlobal"
  )
);

//#endregion

//#region O C   G L O B A L  D E T A I L
Vue.component("SkeletonSearchbarOCDetailComponentGlobal", () =>
  import(
    "../global_components/oc_detail_component_global/SkeletonSearchbarOCDetailComponentGlobal"
  )
);
Vue.component("SearchbarOCDetailComponentGlobal", () =>
  import(
    "../global_components/oc_detail_component_global/SearchbarOCDetailComponentGlobal"
  )
);
Vue.component("AddRawMaterialToPurchaseOrderComponentGlobal", () =>
  import(
    "../global_components/oc_detail_component_global/AddRawMaterialToPurchaseOrderComponentGlobal"
  )
);
Vue.component("DialogConfirmationOfPaidComponentGlobal", () =>
  import(
    "../global_components/oc_detail_component_global/DialogConfirmationOfPaidComponentGlobal"
  )
);
Vue.component("OCDetailComponentGlobal", () =>
  import(
    "../global_components/oc_detail_component_global/OCDetailComponentGlobal"
  )
);
Vue.component("TableMobileRawMaterialOCComponent", () =>
  import(
    "../global_components/oc_detail_component_global/TableMobileRawMaterialOCComponent"
  )
);
Vue.component("SkeletonOCDetailComponentGlobal", () =>
  import(
    "../global_components/oc_detail_component_global/SkeletonOCDetailComponentGlobal"
  )
);

Vue.component("ProviderDetailOCGlobal", () =>
  import(
    "../global_components/oc_detail_component_global/provider_detail_oc_compnent_global/ProviderDetailOCGlobal"
  )
);

//#endregion

//#region O P  G E N E R A L
Vue.component("OpGeneralComponent", () =>
  import("../components/op_general/OPGeneralComponent")
);
//#endregion

//#region O P  G E N E R A L  D E T A I L
Vue.component("OPGeneralDetailComponent", () =>
  import("../components/op_general_detail/OPGeneralDetailComponent")
);
Vue.component("ActorDetailOPGeneralComponent", () =>
  import("../components/op_general_detail/ActorDetailOPGeneralComponent")
);
//#endregion

//#region O C  P E N D I N G
Vue.component("OPPendingComponent", () =>
  import("../components/op_pending/OPPendingComponent")
);
Vue.component("AddOPComponent", () =>
  import("../components/op_pending/AddOPComponent")
);
//#endregion

//#region O C  P E N D I N G   D E T A I L
Vue.component("OPPendingDetailComponent", () =>
  import("../components/op_pending_detail/OPPendingDetailComponent")
);
Vue.component("ProviderDetailOPPendingComponent", () =>
  import("../components/op_pending_detail/ProviderDetailOPPendingComponent")
);
//#endregion

//#region O C  P A Y A B L E
Vue.component("OPPayableComponent", () =>
  import("../components/op_payable/OPPayableComponent")
);
//#endregion

//#region O C  P A Y A B L E    D E T A I L
Vue.component("OPPayableDetailComponent", () =>
  import("../components/op_payable_detail/OPPayableDetailComponent")
);
Vue.component("ProviderDetailOPPayableComponent", () =>
  import("../components/op_payable_detail/ProviderDetailOPPayableComponent")
);
//#endregion

// #region O P E R A T I O N S  G L O B A L S
Vue.component("OPDashboardComponentGlobal", () =>
  import(
    "../global_components/operations_component_global/OPDashboardComponentGlobal"
  )
);
Vue.component("OPComponentGlobal", () =>
  import("../global_components/operations_component_global/OPComponentGlobal")
);
Vue.component("TableMobileOPComponentGlobal", () =>
  import(
    "../global_components/operations_component_global/TableMobileOPComponentGlobal"
  )
);
// #endregion O P E R A T I O N S  G L O B A L S

//#region O P E R A T I O N S  G L O B A L S  D E T A I L
Vue.component("SkeletonSearchbarOperationsDetailComponentGlobal", () =>
  import(
    "../global_components/operations_detail_component_global/SkeletonSearchbarOperationsDetailComponentGlobal"
  )
);
Vue.component("SearchbarOperationsDetailComponentGlobal", () =>
  import(
    "../global_components/operations_detail_component_global/SearchbarOperationsDetailComponentGlobal"
  )
);
Vue.component("AddRawMaterialToOperationsComponentGlobal", () =>
  import(
    "../global_components/operations_detail_component_global/AddRawMaterialToOperationsComponentGlobal"
  )
);
Vue.component("DialogConfirmationOfPaidComponentGlobal", () =>
  import(
    "../global_components/operations_detail_component_global/DialogConfirmationOfPaidComponentGlobal"
  )
);
Vue.component("OperationsDetailComponentGlobal", () =>
  import(
    "../global_components/operations_detail_component_global/OperationsDetailComponentGlobal"
  )
);

Vue.component("SkeletonOperationsDetailComponentGlobal", () =>
  import(
    "../global_components/operations_detail_component_global/SkeletonOperationsDetailComponentGlobal"
  )
);

Vue.component("ActorDetailOPGlobal", () =>
  import(
    "../global_components/operations_detail_component_global/actor_detail_op_component_global/ActorDetailOPGlobal"
  )
);

//#endregion O P E R A T I O N S  G L O B A L S  D E T A I L

// #region A U D I T
Vue.component("AuditComponent", () =>
  import("../components/audit/AuditComponent")
);
Vue.component("TableMobileAuditComponent", () =>
  import("../components/audit/TableMobileAuditComponent")
);

Vue.component("UserProfileDetailAuditComponent", () =>
  import("../components/audit/UserProfileDetailAuditComponent")
);

// #endregion A U D I T

//#region C U S T O M E R
Vue.component("AddCustomerComponent", () =>
  import("../components/customer/AddCustomerComponent")
);
Vue.component("CustomerComponent", () =>
  import("../components/customer/CustomerComponent")
);
Vue.component("TableMobileCustomerComponent", () =>
  import("../components/customer/TableMobileCustomerComponent")
);
//#endregion C U S T O M E R

//#region C U S T O M E R    D E T A I L
Vue.component("CustomerDetailComponent", () =>
  import("../components/customer_detail/CustomerDetailComponent")
);
Vue.component("SkeletonCustomerDetailComponent", () =>
  import("../components/customer_detail/SkeletonCustomerDetailComponent")
);

//#region S A L E S  O R D E R S   C U S T O M E R   D E T A I L
Vue.component("FiltersSalesOrdersComponent", () =>
  import(
    "../components/customer_detail/components_sales_orders/FiltersSalesOrdersComponent"
  )
);
Vue.component("SkeletonFilterSalesOrdersComponent", () =>
  import(
    "../components/customer_detail/components_sales_orders/SkeletonFilterSalesOrdersComponent"
  )
);
Vue.component("SalesOrdersComponent", () =>
  import(
    "../components/customer_detail/components_sales_orders/SalesOrdersComponent"
  )
);
Vue.component("SalesOrdersDetailCustomerComponent", () =>
  import(
    "../components/customer_detail/components_sales_orders/components_sales_orders_detail/SalesOrdersDetailProvider"
  )
);
Vue.component("TableMobileSalesOrdersComponent", () =>
  import(
    "../components/customer_detail/components_sales_orders/TableMobileSalesOrdersComponent"
  )
);
Vue.component("SkeletonSalesOrdersComponent", () =>
  import(
    "../components/customer_detail/components_sales_orders/SkeletonSalesOrdersComponent"
  )
);
//#endregion S A L E S  O R D E R S    C U S T O M E R   D E T A I L

//#region R A W  M A T E R I A L  C U S T O M E R   D E T A I L
Vue.component("FilterRawMaterialsDetailCustomerComponent", () =>
  import(
    "../components/customer_detail/components_raw_materials/FilterRawMaterialComponent"
  )
);
Vue.component("SkeletonFilterRawMaterialsDetailCustomerComponent", () =>
  import(
    "../components/customer_detail/components_raw_materials/SkeletonFilterRawMaterialComponent"
  )
);
Vue.component("RawMaterialsDetailCustomerComponent", () =>
  import(
    "../components/customer_detail/components_raw_materials/RawMaterialComponent"
  )
);
Vue.component("TableMobileRawMaterialsDetailCustomerComponent", () =>
  import(
    "../components/customer_detail/components_raw_materials/TableMobileRawMaterialsComponent"
  )
);
Vue.component("SkeletonRawMaterialsDetailCustomerComponent", () =>
  import(
    "../components/customer_detail/components_raw_materials/SkeletonRawMaterialComponent"
  )
);
Vue.component("DialogUpdatePriceRawMaterialComponent", () =>
  import(
    "../components/customer_detail/components_raw_materials/DialogUpdatePriceRawMaterialComponent"
  )
);
//#endregion R A W  M A T E R I A L    C U S T O M E R   D E T A I L

//#endregion C U S T O M E R    D E T A I L
