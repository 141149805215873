const routes = [
  {
    /*
        Set Up router for guests and 
        landing page display vue file
      */
    path: "/",
    name: "HomeLogin",
    meta: {
      requiresAuth: false,
    },
    component: () => import("@/views/Login.vue"),
  },
];

export default routes;
